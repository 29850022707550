import React, { useEffect, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import axios from "axios";
import SearchBar from "material-ui-search-bar";
import { navigate, useLocation } from "@reach/router";
import queryString from "query-string";
import { format } from "date-fns";
import { Info } from "@material-ui/icons";
import StyledLink from "../components/styled-link";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reproduction: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonProgress: {
      position: "relative",
      bottom: "-25%",
      left: "-50%",
    },
    modal: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    },
    table: {
      minWidth: 650,
    },
  })
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function Search(props: { canonical_paper_id: number; location: string }) {
  const queryParams = queryString.parse(location.search);
  if (props.canonical_paper_id) {
    return <SearchReproductions canonicalPaperId={props.canonical_paper_id} />;
  } else {
    return <SearchPaper initialSearchTerm={queryParams.query || ""} />;
  }
}

function SearchReproductions({ canonicalPaperId }) {
  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [paper, setPaper] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `/api/canonical_paper_reproductions/${canonicalPaperId}`
      );
      if (response.status == 200) {
        setPaper(response.data.paper);
        setRows(response.data.reproductions);
        setLoading(false);
      }
    })();
  }, []);

  const searchPapers = async () => {
    navigate(`/reproductions/search?query=${searchTerm}`);
  };

  const doiUrl = paper.doi ? `https://doi.org/${paper.doi}` : paper.url;

  return loading ? (
    <div>Loading</div>
  ) : (
    <Container maxWidth="lg">
      <Box display="flex" mt={4} alignItems="center">
        <SearchBar
          style={{ flexGrow: 1 }}
          placeholder="Search reproductions by paper title, DOI, or author name"
          cancelOnEscape={true}
          value={searchTerm}
          onChange={(newValue) => setSearchTerm(newValue.trim())}
          onRequestSearch={searchPapers}
        />
        <Box flexWrap="nowrap" ml={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={searchPapers}
          >
            Search reproductions
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
      </Box>
      <Typography variant="caption" display="block" gutterBottom>
        Search results are sorted from newest to oldest based on the
        reproduction submission date. Do a blank search to see all submitted
        reproductions.
      </Typography>
      <Box mt={4} mb={4}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="reproductions for paper">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h3" gutterBottom>
                    {paper.title}
                  </Typography>
                  <StyledLink href={doiUrl}>{doiUrl}</StyledLink>
                </TableCell>
                <TableCell align="center">
                  Original reproduction package
                </TableCell>
                <TableCell align="center">Number of reproductions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={1}>
                <TableCell>
                  {paper.authors} - {paper.publication_year} -{" "}
                  {paper.publication_name}
                </TableCell>
                <TableCell align="center">
                  {paper.original_reproduction_package_available
                    ? "Available"
                    : "Unavailable"}
                </TableCell>
                <TableCell align="center">
                  {paper.num_submitted_reproductions}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={4} mb={4}>
        <h3>Reproductions of this paper</h3>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="reproductions for paper">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "25%" }}>
                  Reproduction title
                </StyledTableCell>
                <StyledTableCell style={{ width: "21%" }} align="right">
                  Reproducer
                </StyledTableCell>
                <StyledTableCell style={{ width: "15%" }} align="right">
                  Original reproduction package
                </StyledTableCell>
                <StyledTableCell style={{ width: "15%" }} align="right">
                  Revised reproduction package
                  <div>
                    <Tooltip
                      title="Created independently by the reproducer."
                      placement="top"
                      arrow
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell style={{ width: "8%" }} align="right">
                  Display items assessed
                </StyledTableCell>
                <StyledTableCell style={{ width: "8%" }} align="right">
                  Claims assessed
                </StyledTableCell>
                <StyledTableCell style={{ width: "8%" }} align="right">
                  Author inquiries
                  <div>
                    <Tooltip
                      title="Indicates whether authors are open to further inquiries related to this paper. Based on the value recorded in the latest reproduction of this paper."
                      placement="top"
                      arrow
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <StyledLink
                      href={`/reproductions/${row.id}/published/index`}
                    >
                      {row.title || "Missing reproduction nickname"}
                    </StyledLink>
                    <p>
                      {row.candidate_abandoned_at ? (
                        <small>
                          Abandoned{" "}
                          {format(
                            new Date(row.candidate_abandoned_at),
                            "MMM dd, yyyy"
                          )}
                        </small>
                      ) : (
                        <small>
                          Submitted{" "}
                          {format(new Date(row.submitted_at), "MMM dd, yyyy")}
                        </small>
                      )}
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    {row.reproducers.join(", ")}
                  </TableCell>
                  <TableCell align="right">
                    {row.original_reproduction_package ? (
                      <StyledLink href={row.original_reproduction_package}>
                        Original Package Link
                      </StyledLink>
                    ) : (
                      <p>Not available</p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {row.revised_reproduction_package &&
                    row.revised_reproduction_package === "anonymized" ? (
                      "anonymized"
                    ) : row.revised_reproduction_package ? (
                      <StyledLink href={row.revised_reproduction_package}>
                        Revised Package Link
                      </StyledLink>
                    ) : (
                      <p>Not available</p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {row.num_display_items_assessed}
                  </TableCell>
                  <TableCell align="right">{row.num_claims_assessed}</TableCell>
                  <TableCell align="right">{row.author_inquiries}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

function SearchPaper({ initialSearchTerm }) {
  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [lastPageNumber, setLastPageNumber] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [loading, setLoading] = React.useState<boolean>(false);

  const location = useLocation();

  const pageNumber = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get("page");
  }, [location]);

  useEffect(() => {
    (() => {
      searchPapers();
    })();
  }, [pageNumber]);

  const searchPapers = async () => {
    setLoading(true);
    let queryString;
    if (searchTerm == "") {
      const pageNumberForUrl = pageNumber || 1;
      queryString = `page=${pageNumberForUrl}&query=${searchTerm}`;
    } else {
      queryString = `query=${searchTerm}`;
    }
    // soft navigate without remounting component
    navigate(`/reproductions/search?${queryString}`, { replace: true });
    const response = await axios.get(`/api/paper_search?${queryString}`);
    if (response.status == 200) {
      setRows(response.data.papers);
      setLastPageNumber(response.data.pagy.last);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" mt={4} alignItems="center">
        <SearchBar
          style={{ flexGrow: 1 }}
          placeholder="Search reproductions by paper title, DOI, or author name"
          cancelOnEscape={true}
          value={searchTerm}
          onChange={(newValue) => setSearchTerm(newValue.trim())}
          onRequestSearch={searchPapers}
        />
        <Box flexWrap="nowrap" ml={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={searchPapers}
          >
            Search reproductions
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
      </Box>
      <Typography variant="caption" display="block" gutterBottom>
        Search results are sorted from newest to oldest based on the
        reproduction submission date. Do a blank search to see all submitted
        reproductions.
      </Typography>
      <Box mt={4} mb={4}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell align="right">Authors</StyledTableCell>
                <StyledTableCell align="right">
                  Original reproduction package
                  <div>
                    <Tooltip
                      title="Considered available if any of the submitted reproductions reported that an original reproduction package was available."
                      placement="top"
                      arrow
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  Number of reproductions
                  <div>
                    <Tooltip
                      title="Number of submitted reproductions, regardless of privacy settings. 0 indicates abandoned reproductions, i.e. papers for which the reproducer was unable to find any reproduction materials."
                      placement="top"
                      arrow
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const doiUrl = row.doi ? `https://doi.org/${row.doi}` : row.url;
                return (
                  <TableRow key={row.doi}>
                    <TableCell component="th" scope="row">
                      <StyledLink
                        href={`/reproductions/search/${row.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/reproductions/search/${row.id}`);
                        }}
                      >
                        {row.title}
                      </StyledLink>
                      <p>
                        <small>
                          Paper: <StyledLink href={doiUrl}>{doiUrl}</StyledLink>
                        </small>
                      </p>
                    </TableCell>
                    <TableCell align="right">{row.authors}</TableCell>
                    <TableCell align="right">
                      {row.original_reproduction_package_available
                        ? "Available"
                        : "Unavailable"}
                    </TableCell>
                    <TableCell align="right">
                      {row.num_submitted_reproductions}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {searchTerm == "" ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              size="large"
              count={lastPageNumber}
              page={Number(pageNumber)}
              onChange={(event, page) =>
                navigate(`/reproductions/search?page=${page}&query=`)
              }
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Container>
  );
}

export default Search;
