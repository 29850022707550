import React, { useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Grid,
  Fab,
} from "@material-ui/core";
import { EditPaper } from "../select-paper/components/edit-paper";
import AddOriginalReproductionPackages from "./components/add-original-packages";
import AddRevisedReproductionPackages from "./components/add-revised-packages";
import AssignReproducibilityScore from "./components/assign-score";
import UploadPDF from "./components/upload-pdf";
import SaveButton from "./components/save-button";
import axios from "axios";

function MinimumReproduction({
  loading,
  formik,
  handleSave,
  readOnly = false,
}: {
  loading: boolean;
  formik: any;
  handleSave: Function;
  readOnly: boolean;
}) {
  const [
    existingReproductionsLink,
    setExistingReproductionsLink,
  ] = React.useState("");

  useEffect(() => {
    if (!readOnly) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [readOnly]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const fetchExistingReproductionsLink = async (doi) => {
    axios
      .get(`/api/canonical_papers.json`, {
        params: { doi: doi },
      })
      .then(function (response) {
        if (response.status === 200) {
          setExistingReproductionsLink(response.data.url);
        } else {
          setExistingReproductionsLink("");
        }
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    fetchExistingReproductionsLink(formik.values.paper.doi);
  }, [loading]);

  const handleSavePaper = async () => {
    const paperDOI = formik.values.paper.doi;

    await handleSave();

    await fetchExistingReproductionsLink(paperDOI);
  };

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <form onSubmit={formik.handleSubmit}>
          <Container maxWidth="md">
            <Box my={4}>
              {readOnly ? (
                <></>
              ) : (
                <Typography variant="h1" align="center">
                  Create A Minimum Reproduction Attempt
                </Typography>
              )}
            </Box>
            {/* ************ STEP 1: Select Reproduction Paper ************ */}
            <Typography variant="h4" gutterBottom>
              Select a Paper
            </Typography>
            <EditPaper
              paper={formik.values.paper}
              updatePaper={(values) => {
                formik.setValues({
                  ...formik.values,
                  paper: { ...formik.values.paper, ...values },
                });
              }}
              readOnly={readOnly}
            />
            <SaveButton onClick={handleSavePaper} readOnly={readOnly} />
            <Divider variant="middle" style={{ margin: "24px 0" }} />
            {/* ************ STEP 2: Add Original Reproduction Package ************ */}
            <Typography variant="h4" gutterBottom>
              Add Original Reproduction Package
            </Typography>
            <AddOriginalReproductionPackages
              existingReproductionsLink={existingReproductionsLink}
              formik={formik}
              readOnly={readOnly}
            />
            <SaveButton onClick={handleSave} readOnly={readOnly} />
            <Divider variant="middle" style={{ margin: "24px 0" }} />
            {/* ************ STEP 3: Add Revised Reproduction Package ************ */}
            <Box mb={4}>
              <Typography variant="h4" gutterBottom>
                Add Revised Reproduction Package
              </Typography>

              <AddRevisedReproductionPackages
                formik={formik}
                readOnly={readOnly}
              />

              <SaveButton onClick={handleSave} readOnly={readOnly} />

              <Divider variant="middle" style={{ margin: "24px 0" }} />
            </Box>
            {/* ************ STEP 4: Assign Paper-level Reproducibility Score ************ */}
            <Box mb={4}>
              <Typography variant="h4" gutterBottom>
                Assign Paper-level Reproducibility Score
              </Typography>

              <AssignReproducibilityScore formik={formik} readOnly={readOnly} />

              <SaveButton onClick={handleSave} readOnly={readOnly} />

              <Divider variant="middle" style={{ margin: "24px 0" }} />
            </Box>
            {/* ************ STEP 5: Upload PDF ************ */}
            <Box mb={4}>
              <Typography variant="h4" gutterBottom>
                Upload PDF
              </Typography>

              <UploadPDF formik={formik} readOnly={readOnly} />

              {readOnly ? (
                <></>
              ) : (
                <Grid container justify="flex-end">
                  <Fab
                    variant="extended"
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Submit Reproduction Attempt
                  </Fab>
                </Grid>
              )}
            </Box>
          </Container>
        </form>
      )}
    </>
  );
}

export default MinimumReproduction;
