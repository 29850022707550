import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Container, Fab, LinearProgress } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Save } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import ClaimRobustnessChecks from "./claim-robustness-checks";
import {
  fetchRobustness,
  saveRobustnessFields,
} from "../../../actions/robustness.actions";
import { navigate } from "@reach/router";
import { EditRobustness } from "../../../state/edit-robustness";
import queryString from "query-string";
import ExternalLink from "../../../components/external-link";
import NotFound from "../../../pages/404";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightGroup: {
      marginLeft: "auto",
      display: "inline-flex",
    },
    actionBar: {
      display: "flex",
      padding: 8,
      alignItems: "center",
    },
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    floatingSaveButton: {
      position: "-webkit-sticky",
      position: "sticky",
      left: "90%",
      bottom: "40px",
    },
  })
);

function ActionBar({
  disableBack = false,
  disableNext = false,
  handleBack,
  handleUp,
  handleNext,
}: {
  disableBack: boolean;
  disableNext: boolean;
  handleBack: any;
  handleUp: Function;
  handleNext: any;
}) {
  const classes = useStyles();
  return (
    <div className={classes.actionBar}>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUp}
          className={classes.button}
        >
          Return to Stages Overview
        </Button>
        <Button
          variant="contained"
          onClick={handleBack}
          className={classes.button}
          disabled={disableBack}
        >
          Previous Step
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disabled={disableNext}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
}

const HorizontalNonLinearStepper: React.SFC<any> = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [estimatesByClaimId, setEstimatesByClaimId] = React.useState({});

  const claims = props.editRobustness.robustness.claims;

  useEffect(() => {
    setActiveStep(props.currentStep || 0);
  }, [props.currentStep]);

  const isAuthor = props.editRobustness.robustness.isAuthor === "true";

  const viewingShareableLink = props.editRobustness.robustness.shareableLink;

  const readOnly =
    "submitted" === props.editRobustness.robustness.workflowStage ||
    !isAuthor ||
    viewingShareableLink;

  useEffect(() => {
    if (!readOnly) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [readOnly]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const totalSteps = () => {
    return claims.size;
  };

  const isFirstStep = (): boolean => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    handleStep(activeStep + 1)();
  };

  const handleSave = () => {
    props.saveRobustnessFields(props.id);
  };

  const handleBack = () => {
    handleStep(activeStep - 1)();
  };

  const handleUp = () => {
    const nextUrl = `index?step=4`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      props.saveRobustnessFields(props.id, nextUrl);
    }
  };

  const handleStep = (nextStep: number) => () => {
    const nextUrl = `robustness?step=${nextStep}`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      props.saveRobustnessFields(props.id, nextUrl);
    }
    window.scrollTo({ top: 0 });
  };

  const handleGoToConclusion = () => {
    props.saveRobustnessFields(
      props.id,
      `/reproductions/${props.id}/conclusion`
    );
  };

  const isPublishedView = location.pathname.split("/").includes("published");

  useEffect(() => {
    (async () => {
      const queryParams = isPublishedView ? "?mode=published" : "";
      const response = await axios.get(
        `/api/reproductions/${props.id}/prespecified_estimates.json${queryParams}`
      );
      if (response.status == 200) {
        // @ts-ignore
        setEstimatesByClaimId(
          buildEstimatesByClaimIdMap(response.data.display_items)
        );
      }
    })();
  }, []);

  const buildEstimatesByClaimIdMap = (data: any) => {
    let estimatesByClaimId = new Object();
    for (let i = 0, l = data.length; i < l; i++) {
      let estimates = data[i].prespecified_estimates;
      for (let j = 0, l = estimates.length; j < l; j++) {
        let claimId = estimates[j].claim_id;
        if (!estimatesByClaimId[claimId]) {
          estimatesByClaimId[claimId] = [];
        }
        estimatesByClaimId[claimId].push(estimates[j]);
      }
    }
    return estimatesByClaimId;
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          opacity: props.editRobustness.saving ? 1 : 0,
          transition: "opacity 300ms",
        }}
      >
        <LinearProgress />
      </div>
      <Stepper nonLinear activeStep={activeStep}>
        {claims.map((label, index) => (
          <Step key={index}>
            <StepButton onClick={handleStep(index)}>
              Robustness for Claim {index + 1}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <ActionBar
        disableBack={isFirstStep()}
        disableNext={isLastStep()}
        handleBack={handleBack}
        handleUp={handleUp}
        handleNext={handleNext}
      />
      {!props.editRobustness.loading && (
        <div>
          <ClaimRobustnessChecks
            claim={claims.get(activeStep)}
            estimates={estimatesByClaimId[claims.get(activeStep).claimId]}
            claimIndex={activeStep}
            readOnly={readOnly}
          />
          {isLastStep() && !readOnly && (
            <Button
              onClick={handleGoToConclusion}
              variant="contained"
              color="primary"
              size="small"
            >
              Save and move to conclusion
            </Button>
          )}
        </div>
      )}
      <ActionBar
        disableBack={isFirstStep()}
        disableNext={isLastStep()}
        handleBack={handleBack}
        handleUp={handleUp}
        handleNext={handleNext}
      />
      {!readOnly && (
        <Fab
          variant="extended"
          color="secondary"
          onClick={handleSave}
          className={[classes.button, classes.floatingSaveButton].join(" ")}
        >
          <Save />
          Save
        </Fab>
      )}
    </div>
  );
};

const mapStateToProps = ({
  editRobustness,
  user,
}: {
  editRobustness: EditRobustness;
  user: User;
}) => {
  return { editRobustness, user };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveRobustnessFields: (
      reproductionId: number,
      nextUrl: string,
      workflowStage: string
    ) =>
      dispatch(
        saveRobustnessFields({ reproductionId, nextUrl, workflowStage })
      ),
    fetchRobustness: (id: number, mode: string) =>
      dispatch(fetchRobustness({ id, mode })),
  };
};

function Index(props: {
  id: number;
  path: string;
  location: string;
  editRobustness: EditRobustness;
  fetchRobustness: (id: number, mode: string) => void;
}) {
  const isPublishedView = location.pathname.split("/").includes("published");
  useEffect(() => {
    props.fetchRobustness(props.id, isPublishedView && "published");
  }, [isPublishedView]);

  const queryParams = queryString.parse(props.location.search);
  const currentStep = parseInt(queryParams.step);
  return props.editRobustness.notFound ? (
    <NotFound />
  ) : (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" align="center">
          Robustness
        </Typography>
        <Typography>
          <strong>This stage is not required.</strong> Assess the robustness of
          claims by modifying analytic choices and reporting their subsequent
          effects on the estimates of interest, i.e. conducting robustness
          checks. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/robust.html">
            here
          </ExternalLink>
          .
        </Typography>
        <HorizontalNonLinearStepper {...props} currentStep={currentStep} />
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
