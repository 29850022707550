import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Snackbar,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import queryString from "query-string";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { EditReproduction, Reproduction } from "../../state/edit-reproduction";
import {
  fetchReproduction,
  saveFields,
} from "../../actions/edit-reproductions.actions";
import {
  Add,
  DeleteOutline,
  Edit,
  Info,
  FileCopy,
  Visibility,
} from "@material-ui/icons";
import axios from "axios";
import { User } from "../../state/user";
import ExternalLink from "../../components/external-link";
import NotFound from "../../pages/404";
import MuiAlert from "@material-ui/lab/Alert";
import { format } from "date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Summary from "./summary";
import Modal from "@material-ui/core/Modal";
import MinimumReproduction from "./minimum-reproduction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    stepIcon: { cursor: "pointer" },
    table: {
      minWidth: "75%",
      borderCollapse: "collapse",
      "& th": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
      "& td": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
    },
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

const ALL_STEPS = [
  "Select a paper",
  "Scoping",
  "Assessment",
  "Improvement",
  "Robustness",
];

const isStepEditable = (
  workflowStage: string,
  paperType: string,
  step: number,
  readOnly: boolean
) => {
  if (readOnly) return false;
  switch (step) {
    case 0:
      return (
        workflowStage === "select_paper" && paperType !== "abandoned candidate"
      );
    case 1:
      return workflowStage === "scoping";
    default:
      return workflowStage === "assessment";
    // TODO: add another case here for submitted reproduction
  }
};

function getSteps(workflowStage: string) {
  switch (workflowStage) {
    case "select_paper":
      return ALL_STEPS.slice(0, 1);
    case "abandoned_candidate":
      return ALL_STEPS.slice(0, 1);
    case "scoping":
      return ALL_STEPS.slice(0, 2);
    default:
      return ALL_STEPS;
  }
}

const initialStep = (step: number) => {
  if (step >= 2) {
    return 2; // clamp step to 2 so we don't see checkmarks on assessment and after
  } else if (!step) {
    return 0;
  } else {
    return step;
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function ReproductionVerticalLinearStepper({
  step,
  workflowStage,
  paperType,
  saveFields,
  readOnly,
  reproductionId,
}: {
  step: number;
  workflowStage: string;
  paperType: string;
  saveFields: any;
  readOnly: boolean;
  reproductionId: string;
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(initialStep(step));

  const steps = getSteps(workflowStage);

  const handleStep = (step) => () => {
    window.history.pushState(
      "",
      "",
      `${window.location.pathname}?step=${step}`
    );
    setActiveStep(step);
  };

  const discussPaper = async () => {
    const response = await axios.get(
      `/api/reproductions/${reproductionId}/discussion_url`
    );
    if (response.status == 200) {
      const url = response.data.url;
      window.open(url, "_blank", "noopener");
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step
            key={label}
            active={index == activeStep || (index >= 2 && activeStep >= 2)}
          >
            <StepLabel
              className={classes.stepIcon}
              icon={String(index)}
              onClick={handleStep(index)}
            >
              <Box display="flex" alignItems="center">
                <Box mr={2}>
                  <Typography variant="h4">{label}</Typography>
                </Box>
                {isStepEditable(workflowStage, paperType, index, readOnly) ? (
                  <Edit />
                ) : (
                  <Visibility />
                )}
              </Box>
            </StepLabel>
            <StepContent>
              {getStepContent(index)}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    href={getStepLink(index)}
                    className={classes.button}
                  >
                    {isStepEditable(workflowStage, paperType, index, readOnly)
                      ? "Edit this section"
                      : "View this section"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {workflowStage === "select_paper" && (
        <Typography>
          In order to access the Scoping stage and subsequent parts of the
          survey, you first need to{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/selecting-a-paper.html#declare">
            declare a paper
          </ExternalLink>
          . For an overview of the structure of the survey, see{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/intro.html#stages-of-the-exercise">
            here
          </ExternalLink>
          .
        </Typography>
      )}
      {workflowStage === "scoping" && (
        <Typography>
          In order to access the Assessment stage and subsequent parts of the
          survey, you first need to complete the scoping stage. For an overview
          of the structure of the survey, see{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/intro.html#stages-of-the-exercise">
            here
          </ExternalLink>
          .
        </Typography>
      )}
      <Box display="flex" justifyContent="space-between">
        {workflowStage === "assessment" && !readOnly ? (
          <Button
            href={"conclusion?step=0"}
            variant="contained"
            color="primary"
            size="small"
          >
            Submit reproduction attempt
          </Button>
        ) : (
          <></>
        )}
        {workflowStage === "submitted" ? (
          <Box display="flex" justifyContent="flex-start">
            <Button
              href={"conclusion?step=0"}
              variant="contained"
              color="primary"
              size="small"
            >
              Submission
            </Button>
            <Box ml={3} />
            <Button
              href={"privacy_setting"}
              variant="contained"
              color="primary"
              size="small"
            >
              Privacy Setting
            </Button>
          </Box>
        ) : (
          <></>
        )}
        {workflowStage !== "select_paper" && (
          <Box display="flex" alignItems="center">
            <Button
              onClick={discussPaper}
              variant="contained"
              color="primary"
              size="small"
            >
              Discuss this paper
            </Button>
            <Tooltip
              title="This will take you to the forum where you can create a topic to discuss your declared paper."
              placement="top"
              arrow
            >
              <Info fontSize="small" />
            </Tooltip>
          </Box>
        )}
      </Box>
    </div>
  );
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return (
        <Typography>
          Declare the paper that you will analyze in the remainder of the
          exercise and record other "candidate" papers for which you were unable
          to obtain access to a reproduction package. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/scoping.html#declare">
            here
          </ExternalLink>
          .
        </Typography>
      );
    case 1:
      return (
        <Typography>
          Focusing on the declared paper from the previous stage, define the
          scope of your exercise by identifying the display items and claims on
          which you will focus in the later stages. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/scoping.html">
            here
          </ExternalLink>
          .
        </Typography>
      );
    case 2:
      return (
        <Typography>
          Describe in detail the available reproduction materials and assign a
          reproducibility score to the display items that you reviewed in the
          previous stage, as well as the overall paper. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/assessment.html">
            here
          </ExternalLink>
          .
        </Typography>
      );
    case 3:
      return (
        <Typography>
          Record and/or propose ways to improve the reproducibility of
          individual display items and/or the overall paper. See detailed
          guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/improvements.html">
            here
          </ExternalLink>
          .
        </Typography>
      );
    case 4:
      return (
        <Typography>
          Assess the robustness of claims by modifying analytic choices and
          reporting their subsequent effects on the estimates of interest, i.e.
          conducting robustness checks. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/robust.html">
            here
          </ExternalLink>
          .
        </Typography>
      );
    default:
      return "Unknown step";
  }
}

function getStepLink(step: number) {
  switch (step) {
    case 0:
      return "select-paper";
    case 1:
      return "scoping?step=0";
    case 2:
      return "assessment?step=0";
    case 3:
      return "improvement?step=0";
    case 4:
      return "robustness?step=0";
    default:
      return "Unknown step";
  }
}

const mapStateToProps = ({
  editReproduction,
  user,
}: {
  editReproduction: EditReproduction;
  user: User;
}) => {
  return {
    editReproduction,
    reproduction: editReproduction.reproduction,
    user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchReproduction: (id: number, mode: string) =>
      dispatch(fetchReproduction({ id, mode })),
    saveFields: (nextUrl: string, workflowStage: string) =>
      dispatch(saveFields({ nextUrl, workflowStage })),
  };
};

function Index(props: {
  path: string;
  id: string;
  location: string;
  fetchReproduction: (id: number, mode: string) => void;
  saveFields: (nextUrl: string, workflowStage: string) => void;
  editReproduction: EditReproduction;
  reproduction: Reproduction;
}) {
  const classes = useStyles();
  const isPublishedView = location.pathname.split("/").includes("published");
  const viewingShareableLink = props.reproduction.shareableLink;
  const isAuthor = props.reproduction.isAuthor === "true";
  const isSubmitted = props.reproduction.submittedAt.length > 0;

  useEffect(() => {
    props.fetchReproduction(props.id, isPublishedView && "published");
  }, [isPublishedView]);

  const [shareableLinks, setShareableLinks] = React.useState([]);
  const [clipboardSnackbarOpen, setClipboardSnackbarOpen] = React.useState(
    false
  );
  const [collaboratorInvitations, setCollaboratorInvitations] = React.useState(
    []
  );
  const [modalOpen, setModalOpen] = React.useState(false);

  const [invitedCollaboratorEmail, setInvitedCollaboratorEmail] = useState("");

  const openInviteCollaboratorModal = () => {
    setModalOpen(true);
  };

  const closeInviteCollaboratorModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (isAuthor && !viewingShareableLink) {
      reloadLinks();
      reloadCollaboratorInvitations();
    }
  }, [props.reproduction]);

  const reloadLinks = async () => {
    axios
      .get(`/api/reproductions/${props.id}/shareable_links`)
      .then(function (response) {
        setShareableLinks(response.data.links);
      });
  };

  const generateShareableLink = async () => {
    await axios.post(`/api/reproductions/${props.id}/shareable_links`);
    reloadLinks();
  };

  const deleteShareableLink = (id: string) => async () => {
    await axios.delete(`/api/reproductions/${props.id}/shareable_links/${id}`);
    reloadLinks();
  };

  const paper = props.reproduction.paper;
  const doiLink = paper.doi ? `https://doi.org/${paper.doi}` : paper.url;

  const queryParams = queryString.parse(location.search);

  const reloadCollaboratorInvitations = async () => {
    axios
      .get(`/api/reproductions/${props.id}/collaborator_invitations.json`)
      .then(function (response) {
        setCollaboratorInvitations(response.data);
      });
  };

  const inviteNewCollaborator = async () => {
    axios
      .post(`/api/reproductions/${props.id}/collaborator_invitations`, {
        invited_email: invitedCollaboratorEmail,
      })
      .then(function (response) {
        reloadCollaboratorInvitations();
        setInvitedCollaboratorEmail("");
      });
  };

  const deleteCollaboratorInvitation = (invitation_id: string) => async () => {
    axios
      .delete(
        `/api/reproductions/${props.id}/collaborator_invitations/${invitation_id}`
      )
      .then(function (response) {
        reloadCollaboratorInvitations();
      });
  };

  const readOnly =
    !isAuthor || viewingShareableLink || isPublishedView || isSubmitted;

  return props.editReproduction.notFound ? (
    <NotFound />
  ) : (
    <Container maxWidth="md">
      <Box my={4}>
        {readOnly ? (
          <>
            <Typography variant="h3" gutterBottom>
              Project Name: <em>{props.reproduction.projectNickname}</em>
            </Typography>
            <Typography variant="h4" gutterBottom>
              Reproduction of:{" "}
              <ExternalLink href={doiLink}>
                {paper.title} <em>{paper.publicationName}</em>
                {paper.publicationYear ? ", " + paper.publicationYear : ""}
              </ExternalLink>
            </Typography>
            {props.reproduction.id ? (
              <>
                <Summary reproduction={props.reproduction} />
                <Box paddingTop={2} />
                <Divider />
                <Box paddingTop={2} />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Typography variant="h3" gutterBottom>
              {props.reproduction.projectNickname ||
                "Create a reproduction attempt"}
            </Typography>
            {collaboratorInvitations.length > 1 && (
              <Typography>
                Caution: only one user can edit the form at a time. If a
                collaborator edits and saves the form while you edit, all of
                your unsaved edits will be overwritten.
              </Typography>
            )}
          </>
        )}
        {readOnly && props.reproduction.reproductionType === "minimum" ? (
          <MinimumReproduction id={props.id} readOnly={readOnly} />
        ) : (
          <></>
        )}
        {props.reproduction.reproductionType === "full" ? (
          <ReproductionVerticalLinearStepper
            step={Number(queryParams.step)}
            workflowStage={props.reproduction.workflowStage}
            paperType={props.reproduction.paperType}
            saveFields={props.saveFields}
            readOnly={readOnly}
            reproductionId={props.id}
          />
        ) : (
          <></>
        )}
      </Box>
      {isAuthor && !props.reproduction.shareableLink && (
        <Card>
          <CardContent>
            <Box display="flex">
              <Button
                startIcon={<Add />}
                variant={"contained"}
                color={"primary"}
                onClick={generateShareableLink}
              >
                Generate a view-only link
              </Button>
              {props.reproduction.workflowStage !== "select_paper" && (
                <Box ml={2}>
                  <Button
                    startIcon={<Add />}
                    variant={"contained"}
                    color={"primary"}
                    onClick={openInviteCollaboratorModal}
                  >
                    Invite a collaborator
                  </Button>
                </Box>
              )}
            </Box>
            {shareableLinks.length > 0 && (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Copy</th>
                      <th>URL</th>
                      <th>Date generated</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shareableLinks.map((link) => (
                      <tr key={link.id}>
                        <td>
                          <CopyToClipboard
                            text={link.url}
                            onCopy={() => setClipboardSnackbarOpen(true)}
                          >
                            <Button>
                              <FileCopy />
                            </Button>
                          </CopyToClipboard>
                        </td>
                        <td>
                          <ExternalLink href={link.url}>
                            {link.url}
                          </ExternalLink>
                        </td>
                        <td>
                          {format(
                            new Date(link.created_at),
                            "MM/dd/yyyy hh:mm:ss a"
                          )}
                        </td>
                        <td>
                          <Button>
                            <DeleteOutline
                              onClick={deleteShareableLink(link.id)}
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Snackbar
                  open={clipboardSnackbarOpen}
                  autoHideDuration={3000}
                  onClose={() => setClipboardSnackbarOpen(false)}
                >
                  <Alert
                    onClose={() => setClipboardSnackbarOpen(false)}
                    severity="success"
                  >
                    Copied to clipboard!
                  </Alert>
                </Snackbar>
              </>
            )}

            <Box mt={4}>
              <Modal
                open={modalOpen}
                onClose={closeInviteCollaboratorModal}
                aria-labelledby="Invite a collaborator modal"
              >
                <div style={getModalStyle()} className={classes.paper}>
                  <Box>
                    <Typography variant="h3">Collaborators</Typography>
                    <Box pt={2}>
                      <table className={classes.table}>
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {collaboratorInvitations.map((invitation) => (
                            <tr key={invitation.email}>
                              <td>{invitation.email}</td>
                              <td>{invitation.status}</td>
                              <td>
                                {invitation.status == "pending" ? (
                                  <Button>
                                    <DeleteOutline
                                      onClick={deleteCollaboratorInvitation(
                                        invitation.id
                                      )}
                                    />
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography>
                      Caution: only one user can edit the form at a time. If a
                      collaborator edits and saves the form while you edit, all
                      of your unsaved edits will be overwritten.
                    </Typography>
                  </Box>
                  <TextField
                    label="Invite new collaborator by email"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    type="email"
                    onChange={(e) =>
                      setInvitedCollaboratorEmail(e.target.value)
                    }
                    value={invitedCollaboratorEmail}
                    autoComplete="email"
                    autoFocus
                  />
                  <Box display="flex" flexDirection="row" pt={2}>
                    <Box pr={2}>
                      <Button
                        onClick={inviteNewCollaborator}
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        Invite new collaborator
                      </Button>
                    </Box>
                    <Button
                      onClick={closeInviteCollaboratorModal}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    >
                      Close modal
                    </Button>
                  </Box>
                </div>
              </Modal>
            </Box>
          </CardContent>
        </Card>
      )}
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
