import { Paper } from "../../../../state/edit-reproduction";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Box, Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../../css";
import axios from "axios";
import ExternalLink from "../../../../components/external-link";
import StyledLink from "../../../../components/styled-link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
    buttonProgress: {
      position: "relative",
      bottom: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    ...questionStyles(1, theme),
  })
);

export function EditPaper({
  paper,
  updatePaper,
  readOnly,
}: {
  paper: Paper;
  updatePaper: Function;
  readOnly: boolean;
}) {
  const classes = useStyles();

  const [doi, setDoi] = React.useState<string>(paper.doi);
  // if the paper has a title, we'll consider the metadata to have been searched at least once
  const [loading, setLoading] = React.useState<string>(false);
  const [loaded, setLoaded] = React.useState<string>(paper.title !== "");
  const [apiResponse, setApiResponse] = React.useState<string>("");

  const onChange = (name: string) => (e: any) => {
    updatePaper({ [name]: e.target.value });
  };

  const onDoiBlur = (e: any) => {
    setLoading(true);
    axios
      .get(`/api/papers.json?doi=${doi}`)
      .then(function (response) {
        const responseBody = response.data;
        updatePaper({
          doi: doi,
          title: responseBody.title || "",
          publicationName: responseBody.publication_name || "",
          publicationYear: responseBody.publication_year || "",
          authors: responseBody.authors.join(", ") || "",
        });
        setLoaded(true);
        setLoading(false);
        setApiResponse("");
      })
      .catch(function (error) {
        updatePaper({
          doi: doi,
          title: "",
          publicationName: "",
          publicationYear: "",
          authors: "",
        });
        setLoaded(true);
        setLoading(false);
        setApiResponse(
          "There was a problem loading metadata with this DOI. Please check that it is entered correctly and try again, or enter the metadata manually below."
        );
      });
  };

  const doiUrl = paper.doi ? `https://doi.org/${paper.doi}` : paper.url;

  return (
    <div className={classes.paper}>
      {readOnly ? (
        <>
          <Typography
            variant="body1"
            component="div"
            className={classes.question}
            gutterBottom
          >
            Basic information about the paper.
          </Typography>
          <Box className={classes.readOnly}>
            <Typography gutterBottom>
              <strong>Digital Object Identifier (or URL)</strong>:{" "}
              <StyledLink href={doiUrl}>{doiUrl}</StyledLink>
            </Typography>
            <Typography gutterBottom>
              <strong>Title of the paper</strong>: {paper.title}
            </Typography>
            <Typography gutterBottom>
              <strong>Publication Name</strong>: {paper.publicationName}
            </Typography>
            <Typography gutterBottom>
              <strong>Publication Year</strong>: {paper.publicationYear}
            </Typography>
            <Typography gutterBottom>
              <strong>Authors</strong>: {paper.authors}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography
            variant="body1"
            component="div"
            className={classes.question}
            gutterBottom
          >
            Enter the{" "}
            <ExternalLink href="https://en.wikipedia.org/wiki/Digital_object_identifier">
              DOI
            </ExternalLink>{" "}
            for the paper that you have chosen to reproduce for this activity
            and we will fetch some basic information. Please use the{" "}
            <code>prefix/suffix</code> notation, e.g.{" "}
            <code>10.1257/aer.20101199</code>.
          </Typography>
          <Box display="flex">
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="Digital Object Identifier (or URL if no DOI available)"
              className={classes.formField}
              fullWidth
              placeholder="E.g. 10.1000/xyz123 or https://arxiv.org/abs/2007.03654"
              value={doi}
              onChange={(e) => setDoi(e.target.value)}
              onBlur={onDoiBlur}
            />
            <Box flexWrap="nowrap">
              <Button variant="contained" color="secondary" disabled={loading}>
                Search DOI
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Box>
          {apiResponse && <Box color="red">{apiResponse}</Box>}
          {loaded && (
            <>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.formField}
                label="Title of the paper"
                fullWidth
                placeholder="E.g. Railroads of the Raj: Estimating the Impact of Transportation Infrastructure"
                value={paper.title}
                onChange={onChange("title")}
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label="Name of journal or publication"
                className={classes.formField}
                fullWidth
                placeholder="E.g. American Economic Review"
                value={paper.publicationName}
                onChange={onChange("publicationName")}
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label="Year of publication"
                className={classes.formField}
                fullWidth
                placeholder="E.g. 2018"
                value={paper.publicationYear}
                onChange={onChange("publicationYear")}
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.formField}
                label="Authors"
                fullWidth
                placeholder="e.g. Esther Duflo, Uri Simonsohn"
                value={paper.authors}
                onChange={onChange("authors")}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
