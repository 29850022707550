import Typography from "@material-ui/core/Typography";
import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";

import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  ImprovedDisplayItem,
  EditImprovement,
  Improvement,
  DisplayItemImprovement,
} from "../../../state/edit-improvement";
import { updateImprovementFields } from "../../../actions/improvement.actions";
import { Button } from "gatsby-material-ui-components";
import { Add, Delete } from "@material-ui/icons";
import QuestionWithRadioGroup from "../components/question-with-radio-group";
import { QuestionWithTrueFalseRadio } from "../components/question-with-true-false-radio";
import QuestionWithTextField from "../components/question-with-text-field";
import ExternalLink from "../../../components/external-link";
import ReproducibilityLevels from "../assessment/components/reproducibility-levels";

const useStyles = (sectionNumber) =>
  makeStyles((theme: Theme) =>
    createStyles({
      otherField: {
        minWidth: 320,
      },
      formField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: 600,
      },
      displayItem: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      hidden: {
        display: "none",
      },
      readOnly: {
        padding: theme.spacing(1),
        border: "1px solid",
        backgroundColor: "#f1f1f1",
      },
      ...questionStyles(sectionNumber, theme),
    })
  );

function ReproducibilityScore({
  improvedDisplayItem,
  updateImprovedDisplayItem,
  disabled,
  readOnly,
  questionClass,
}: {
  improvedDisplayItem: ImprovedDisplayItem;
  updateImprovedDisplayItem: Function;
  disabled: boolean;
  readOnly: boolean;
  questionClass: any;
}) {
  const classes = useStyles("")();

  const adjustedLevels =
    improvedDisplayItem.updateScoreChoice === "yes_adjusted";

  return (
    <div>
      <QuestionWithRadioGroup
        disabled={disabled}
        classes={classes}
        questionClass={questionClass}
        readOnly={readOnly}
        value={improvedDisplayItem.updatedReproducibilityScore}
        onChange={(e: any) => {
          updateImprovedDisplayItem(
            improvedDisplayItem.merge({
              updatedReproducibilityScore: e.target.value,
            })
          );
        }}
        valuesAndLabels={ReproducibilityLevels(adjustedLevels)}
      >
        Given the improvements that you have added, how would you score the new
        level of reproducibility for {improvedDisplayItem.name}?
      </QuestionWithRadioGroup>
    </div>
  );
}

export function EditDisplayItemImprovement({
  displayItemImprovement,
  section,
  readOnly,
  updateDisplayItemImprovement,
  deleteDisplayItemImprovement,
}: {
  displayItemImprovement: DisplayItemImprovement;
  section: string;
  readOnly: boolean;
  updateDisplayItemImprovement: Function;
  deleteDisplayItemImprovement: Function;
}) {
  const classes = useStyles();

  const onChange = (name: string) => (e: any) => {
    updateDisplayItemImprovement(
      displayItemImprovement.merge({ [name]: e.target.value })
    );
  };

  return (
    <Card className={classes.displayItemImprovement}>
      <CardContent>
        <Typography>Type of improvement</Typography>
        {readOnly ? (
          <div className={classes.readOnly}>
            {((answer) => {
              switch (answer) {
                case "missing_raw_data":
                  return "Added missing raw data files";
                case "missing_analytic_data":
                  return "Added missing analytic data files";
                case "missing_analysis_code":
                  return "Added missing analysis code";
                case "missing_data_cleaning":
                  return "Added missing data cleaning code";
                case "debugged_analysis_code":
                  return "Debugged analysis code";
                case "debugged_cleaning_code":
                  return "Debugged cleaning code";
                case "other":
                  return "Other";
              }
            })(displayItemImprovement.improvementType)}
          </div>
        ) : (
          <FormControl>
            <Select
              id="display-item-improvement-type"
              value={displayItemImprovement.improvementType}
              onChange={onChange("improvementType")}
            >
              <MenuItem value={"missing_raw_data"}>
                Added missing raw data files
              </MenuItem>
              <MenuItem value={"missing_analytic_data"}>
                Added missing analytic data files
              </MenuItem>
              <MenuItem value={"missing_analysis_code"}>
                Added missing analysis code
              </MenuItem>
              <MenuItem value={"missing_data_cleaning"}>
                Added missing data cleaning code
              </MenuItem>
              <MenuItem value={"debugged_analysis_code"}>
                Debugged analysis code
              </MenuItem>
              <MenuItem value={"debugged_cleaning_code"}>
                Debugged cleaning code
              </MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          </FormControl>
        )}
        <Box mb={4} />
        <QuestionWithTextField
          classes={classes}
          readOnly={readOnly}
          multiline
          fullWidth
          variant="outlined"
          value={displayItemImprovement.description}
          onChange={onChange("description")}
          noQuestionNumber={true}
        >
          {section === "futureImprovements"
            ? "Describe in detail what you would do"
            : "Describe in detail what you did"}
        </QuestionWithTextField>
        {!readOnly && (
          <Button
            startIcon={<Delete />}
            color="secondary"
            variant="contained"
            onClick={deleteDisplayItemImprovement}
            size="small"
          >
            Delete
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

export function EditImprovedDisplayItem({
  improvedDisplayItem,
  updateImprovedDisplayItem,
  index,
  readOnly,
}: {
  improvedDisplayItem: ImprovedDisplayItem;
  updateImprovedDisplayItem: Function;
  index: number;
  readOnly: boolean;
}) {
  const classes = useStyles("1." + (index + 1))();

  const onChange = (name: string) => (e: any) => {
    updateImprovedDisplayItem(
      improvedDisplayItem.merge({ [name]: e.target.value })
    );
  };

  const reproducibilityScoreDisabled =
    improvedDisplayItem.updateScoreChoice !== "yes" &&
    improvedDisplayItem.updateScoreChoice !== "yes_adjusted";

  const addDisplayItemImprovement = (section: string) => () => {
    updateImprovedDisplayItem(
      improvedDisplayItem.merge({
        [section]: improvedDisplayItem[section].push(
          new DisplayItemImprovement()
        ),
      })
    );
  };

  const updateDisplayItemImprovement = (index: number, section: string) => (
    value: any
  ) => {
    updateImprovedDisplayItem(
      improvedDisplayItem.merge({
        [section]: improvedDisplayItem[section].set(index, value),
      })
    );
  };

  const deleteDisplayItemImprovement = (
    index: number,
    section: string
  ) => () => {
    updateImprovedDisplayItem(
      improvedDisplayItem.merge({
        [section]: improvedDisplayItem[section].delete(index),
      })
    );
  };

  const editDisplayItemImprovement = (section: string) => (
    displayItemImprovement: DisplayItemImprovement,
    index: number
  ) => (
    <EditDisplayItemImprovement
      key={index}
      section={section}
      displayItemImprovement={displayItemImprovement}
      readOnly={readOnly}
      updateDisplayItemImprovement={updateDisplayItemImprovement(
        index,
        section
      )}
      deleteDisplayItemImprovement={deleteDisplayItemImprovement(
        index,
        section
      )}
    />
  );

  return (
    <Box mb={4}>
      <Card>
        <CardContent>
          <Box pr={1}>
            <Typography variant="h4" gutterBottom>
              <span>
                Display Item {index + 1} - {improvedDisplayItem.name}
              </span>
            </Typography>
          </Box>
          <div className={classes.section}>
            <QuestionWithTrueFalseRadio
              classes={classes}
              questionClass={classes.question}
              questionText={`Did you make any improvements that increased the reproducibility of ${improvedDisplayItem.name}?`}
              value={improvedDisplayItem.makeImprovementsChoice}
              onChange={onChange("makeImprovementsChoice")}
              readOnly={readOnly}
            />
            <div
              className={
                improvedDisplayItem.makeImprovementsChoice === "true"
                  ? ""
                  : classes.hidden
              }
            >
              <Typography
                variant="body1"
                component="div"
                className={classes.question}
                gutterBottom
              >
                Describe the improvements you made to {improvedDisplayItem.name}
                :
              </Typography>
              <div>
                {improvedDisplayItem.currentImprovements.map(
                  editDisplayItemImprovement("currentImprovements")
                )}
              </div>
              {!readOnly && (
                <Button
                  startIcon={<Add />}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={addDisplayItemImprovement("currentImprovements")}
                >
                  Add display item improvement
                </Button>
              )}
              <QuestionWithRadioGroup
                classes={classes}
                readOnly={readOnly}
                value={improvedDisplayItem.updateScoreChoice}
                onChange={onChange("updateScoreChoice")}
                valuesAndLabels={[
                  {
                    value: "yes",
                    label:
                      "Yes, please show me the ACRe scale with no adjustments.",
                  },
                  {
                    value: "yes_adjusted",
                    label:
                      "Yes, please show me the ACRe scale with adjustments for administrative data.",
                  },
                  {
                    value: "no",
                    label: "No improvements to report.",
                  },
                ]}
              >
                Do the improvements that you implemented require an update to
                the reproducibility score of {improvedDisplayItem.name}?
                <p>
                  <small>
                    Your reproducibility score of{" "}
                    <strong>{improvedDisplayItem.originalScore}</strong> from
                    the original replication package will remain in the ACRe
                    system; this new score will also be recorded, reflecting the
                    improvements you were able to make.
                  </small>
                </p>
              </QuestionWithRadioGroup>
              <ReproducibilityScore
                improvedDisplayItem={improvedDisplayItem}
                updateImprovedDisplayItem={updateImprovedDisplayItem}
                disabled={reproducibilityScoreDisabled}
                readOnly={readOnly}
                questionClass={classes.question}
              />
            </div>
            <Typography
              variant="body1"
              component="div"
              className={classes.question}
              gutterBottom
            >
              Describe other potential reproducibility improvements to this
              display item. These are improvements that either you or another
              reproducer could implement as part of a future reproduction.
            </Typography>
            <div>
              {improvedDisplayItem.futureImprovements.map(
                editDisplayItemImprovement("futureImprovements")
              )}
            </div>
            {!readOnly && (
              <Button
                startIcon={<Add />}
                variant={"outlined"}
                color={"primary"}
                onClick={addDisplayItemImprovement("futureImprovements")}
              >
                Add future improvement
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}

function DisplayItemImprovements({
  improvement,
  updateImprovementFields,
  readOnly,
}: {
  improvement: Improvement;
  updateImprovementFields: Function;
  readOnly: boolean;
}) {
  const classes = useStyles("")();

  const updateImprovedDisplayItemAtIndex = (index: number) => (value: any) => {
    updateImprovementFields({
      improvedDisplayItems: improvement.improvedDisplayItems.set(index, value),
    });
  };

  const editImprovedDisplayItem = (
    improvedDisplayItem: ImprovedDisplayItem,
    index: number
  ) => (
    <EditImprovedDisplayItem
      key={index}
      index={index}
      improvedDisplayItem={improvedDisplayItem}
      updateImprovedDisplayItem={updateImprovedDisplayItemAtIndex(index)}
      readOnly={readOnly}
    />
  );
  return (
    <>
      <Typography variant="body1" component="div" gutterBottom>
        Record and/or propose ways to improve the reproducibility of individual
        display items and/or the overall paper. See detailed guidance here{" "}
        <ExternalLink href="https://bitss.github.io/ACRE/improvements.html">
          here
        </ExternalLink>
        .
      </Typography>
      <Box mb={4}></Box>
      <Typography variant="h4" gutterBottom>
        Display item improvements
      </Typography>
      <Typography variant="body1" component="div" gutterBottom>
        As part of your assessment of specific display items, you may have
        identified several ways to improve their computational reproducibility.
        In this section, you can record various reproducibility improvements
        that you've implemented, such as:
        <ul>
          <li>Adding raw data, including missing files or metadata</li>
          <li>Adding missing analytic data files</li>
          <li>Adding missing analysis code</li>
          <li>Adding missing data cleaning code</li>
          <li>Debugging analysis code</li>
          <li>Debugging cleaning code</li>
          <li>Other improvements (per your discretion)</li>
        </ul>
        Learn more in the{" "}
        <ExternalLink href="https://bitss.github.io/ACRE/improvements.html#di-imp">
          ACRe Guide
        </ExternalLink>
        .
      </Typography>
      <Box mb={4}></Box>
      <Divider />

      <div className={classes.section}>
        <div>
          {improvement.improvedDisplayItems.map(editImprovedDisplayItem)}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({
  editImprovement: { improvement },
}: {
  editImprovement: EditImprovement;
}) => {
  return { improvement };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateImprovementFields: (fields: any) =>
      dispatch(updateImprovementFields(fields)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayItemImprovements);
