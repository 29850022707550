import {
  saveFields,
  updateFields,
} from "../../../actions/edit-reproductions.actions";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import React from "react";
import {
  EditReproduction,
  Reproduction,
  ReproductionPackage,
} from "../../../state/edit-reproduction";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { questionStyles } from "../css";
import { Button } from "gatsby-material-ui-components";
import { Add } from "@material-ui/icons";
import MultipleSelect from "../components/multiple-select";
import { EditPaper } from "./components/edit-paper";
import { QuestionWithTrueFalseRadio } from "../components/question-with-true-false-radio";
import QuestionWithRadioGroup from "../components/question-with-radio-group";
import { EditReproductionPackage } from "../scoping/components/edit-reproduction-package";
import ExternalLink from "../../../components/external-link";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    otherField: {
      flexGrow: 1,
    },
    didNotRespondLabel: {
      whiteSpace: "nowrap",
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
    ...questionStyles(1, theme),
  })
);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function AuthorsResponseReadOnly({
  reproduction,
}: {
  reproduction: Reproduction;
}) {
  return (
    <ul>
      {reproduction.authorsResponse.map((answer, index) => {
        if (answer === "Did not respond.") {
          return (
            <li key={index}>
              {answer} As of {reproduction.authorDidNotRespondAsOfDate}
            </li>
          );
        } else if (answer === "declined_not_ready") {
          return (
            <li key={index}>
              Declined to share the missing materials, citing not ready to
              share. Author(s) estimate they can share materials on{" "}
              {reproduction.authorsResponseNotReadyDate}
            </li>
          );
        } else if (answer === "no_longer_have_access") {
          return (
            <li key={index}>
              Author(s) state that they no longer have access to the data.
            </li>
          );
        } else if (answer === "Other (explain briefly)") {
          return (
            <li key={index}>Other: {reproduction.authorsResponseOther}</li>
          );
        } else {
          return <li key={index}>{answer}</li>;
        }
      })}
    </ul>
  );
}

function AddBasicInformation({
  reproduction,
  saveFields,
  updateFields,
  readOnly,
}: {
  reproduction: Reproduction;
  saveFields: Function;
  updateFields: Function;
  readOnly: boolean;
}) {
  const updatePaper = (values: any) => {
    updateFields({ paper: reproduction.paper.merge(values) });
  };

  const handleDateChange = (name: string) => (value: any) => {
    updateFields({ [name]: value });
  };

  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };


  let authorsNotContacted = reproduction.authorsContacted !== "true";

  let hasReproductionPackage =
    reproduction.reproductionPackageAvailable.startsWith("true") ||
    reproduction.authorsResponse.contains("Provided reproduction package.");

  let canBeDeclared =
    reproduction.paperType === "candidate" &&
    (hasReproductionPackage ||
      reproduction.reproductionPackageFromScratch === "true");

  let cannotBeDeclared =
    reproduction.paperType === "candidate" &&
    reproduction.reproductionPackageAvailable.startsWith("false") &&
    reproduction.reproductionPackageFromScratch === "false" &&
    (reproduction.authorsContacted === "false" ||
      (reproduction.authorsResponse.size > 0 &&
        !reproduction.authorsResponse.contains(
          "Provided reproduction package."
        )));
  

  const updateReproductionPackage = (index: number) => (value: any) => {
    updateFields({
      originalReproductionPackages: reproduction.originalReproductionPackages.set(
        index,
        value
      ),
    });
  };

  const deleteReproductionPackage = (index: number) => () => {
    updateFields({
      originalReproductionPackages: reproduction.originalReproductionPackages.delete(
        index
      ),
    });
  };

  const addReproductionPackage = () => {
    updateFields({
      originalReproductionPackages: reproduction.originalReproductionPackages.push(
        new ReproductionPackage({ stage: "original", contentType: "data" })
      ),
    });
  };

  const editReproductionPackage = (readOnly: boolean) => (
    reproductionPackage: ReproductionPackage,
    index: number
  ) => (
    <EditReproductionPackage
      key={index}
      reproductionPackage={reproductionPackage}
      updateReproductionPackage={updateReproductionPackage(index)}
      deleteReproductionPackage={deleteReproductionPackage(index)}
      readOnly={readOnly}
    />
  );

  const leavePaperUndeclared = () => {
    saveFields(`/reproductions/home`, "abandon_candidate_paper");
  };

  const declarePaper = () => {
    saveFields(
      `/reproductions/${reproduction.id}/index?step=1`,
      "declare_paper"
    );
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Basic information
      </Typography>
      <Typography>
        At this point, you are not expected to review the reproduction materials
        in detail, as you will dedicate most of your time to this in later
        stages of the exercise. If materials are available, you will declare
        this paper as your target to reproduce. Only then you will be asked to
        read the paper and define the scope of the reproduction exercise.
      </Typography>
      <div className={classes.section}>
        <EditPaper
          paper={reproduction.paper}
          updatePaper={updatePaper}
          readOnly={readOnly}
        />
        <QuestionWithRadioGroup
          classes={classes}
          value={reproduction.reproductionPackageAvailable}
          onChange={(e: any) => {
            updateFields({ reproductionPackageAvailable: e.target.value });
          }}
          readOnly={readOnly}
          valuesAndLabels={[
            {
              value: "true:full",
              label: "Yes, with enough materials to carry out a reproduction exercise.",
            },
            {
              value: "false:partial",
              label: "Yes, but not enough materials to carry out a reproduction exercise.",
            },
            {
              value: "false:none",
              label: "No reproduction materials.",
            },
          ]}
        > 
          Is a reproduction package available for this paper?
        </QuestionWithRadioGroup>
        {/* Only shows if user clicks the above second option `Yes, but not enough materials to carry out a reproduction exercise.` */ }
        <QuestionWithRadioGroup
          classes={classes}
          value={reproduction.reproductionPackageAvailablePartialReason}
          onChange={(e: any) => {
            updateFields({ reproductionPackageAvailablePartialReason: e.target.value });
          }}
          readOnly={readOnly}
          disabled={reproduction.reproductionPackageAvailable !== "false:partial"}
          valuesAndLabels={[
            {
              value: "only code",
              label: "Only code is available.",
            },
            {
              value: "partial analysis data",
              label: "Only partial analysis data is available.",
            },
          ]}
        >
            Why is the material not enough for carrying out a reproduction exercise?
        </QuestionWithRadioGroup>
        <QuestionWithTrueFalseRadio
          classes={classes}
          value={reproduction.authorsContacted}
          onChange={(e: any) => {
            updateFields({ authorsContacted: e.target.value });
          }}
          questionText="Have you contacted the authors for a reproduction package?"
          disabled={reproduction.reproductionPackageAvailable.startsWith("true")}
          readOnly={readOnly}
        >
          Consult the&nbsp;
          <ExternalLink href="https://bitss.github.io/ACRE/guidance-for-a-constructive-exchange-between-reproducers-and-original-authors.html">
            ACRe Guide for recommendations on contacting authors.
          </ExternalLink>
        </QuestionWithTrueFalseRadio>
        <Typography
          variant="body1"
          component="div"
          className={(authorsNotContacted
            ? [classes.typographyDisabled, classes.note]
            : [classes.note]
          ).join(" ")}
          gutterBottom
        >
          Wait a few weeks for the authors to reply, then summarize your
          interaction below.
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={(authorsNotContacted
            ? [classes.typographyDisabled, classes.question]
            : [classes.question]
          ).join(" ")}
          gutterBottom
        >
          How did the authors respond? Select all that apply.
        </Typography>

        {readOnly ? (
          <Box className={classes.readOnly}>
            <AuthorsResponseReadOnly reproduction={reproduction} />
          </Box>
        ) : (
          <MultipleSelect
            classes={{
              label: classes.otherField,
            }}
            disabled={authorsNotContacted}
            labels={[
              "Provided reproduction package.",
              "Declined to share reproduction package, citing legal or ethical reasons.",
              "Declined to share reproduction package but did not provide a reason.",
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={"flex-start"}
              >
                <Box mr={1}>
                  <Typography>
                    Declined to share the missing materials, citing not ready to
                    share. Record date when you estimate that the authors may be
                    ready to share the missing materials:
                  </Typography>
                </Box>
                <KeyboardDatePicker
                  disabled={
                    !reproduction.authorsResponse.contains("declined_not_ready")
                  }
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  id="date-picker-inline"
                  value={reproduction.authorsResponseNotReadyDate}
                  onChange={handleDateChange("authorsResponseNotReadyDate")}
                  KeyboardButtonProps={{
                    "aria-label":
                      "author was not ready, but estimates will be by this date",
                  }}
                />
              </Box>,
              "Author(s) state that they no longer have access to the data.",
              "Shared detailed instructions on how to access the data (for restricted access only).",
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={"flex-start"}
              >
                <Box className={classes.didNotRespondLabel} mr={1}>
                  <Typography>Did not respond. As of: </Typography>
                </Box>
                <KeyboardDatePicker
                  disabled={
                    !reproduction.authorsResponse.contains("Did not respond.")
                  }
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  id="date-picker-inline"
                  value={reproduction.authorDidNotRespondAsOfDate}
                  onChange={handleDateChange("authorDidNotRespondAsOfDate")}
                  KeyboardButtonProps={{
                    "aria-label": "author did not respond by this date",
                  }}
                />
              </Box>,
              <TextField
                InputLabelProps={{
                  shrink: reproduction.authorsResponse.contains(
                    "Other (explain briefly)"
                  ),
                }}
                fullWidth
                label="Other (explain briefly)"
                variant="outlined"
                disabled={
                  !reproduction.authorsResponse.contains(
                    "Other (explain briefly)"
                  )
                }
                value={reproduction.authorsResponseOther}
                onChange={(e: any) => {
                  updateFields({ authorsResponseOther: e.target.value });
                }}
              />,
            ]}
            values={[
              "Provided reproduction package.",
              "Declined to share reproduction package, citing legal or ethical reasons.",
              "Declined to share reproduction package but did not provide a reason.",
              "declined_not_ready",
              "no_longer_have_access",
              "Shared detailed instructions on how to access the data (for restricted access only).",
              "Did not respond.",
              "Other (explain briefly)",
            ]}
            selectedValues={reproduction.authorsResponse}
            updateValues={(v: any) => {
              updateFields({ authorsResponse: v });
            }}
          />
        )}
        <QuestionWithTrueFalseRadio
          classes={classes}
          value={reproduction.authorsAvailable}
          onChange={(e: any) => {
            updateFields({ authorsAvailable: e.target.value });
          }}
          questionText="Are the authors available for further questions for ACRe reproductions?"
          readOnly={readOnly}
          disabled={authorsNotContacted}
        />
        <QuestionWithTrueFalseRadio
          classes={classes}
          value={reproduction.reproductionPackageFromScratch}
          onChange={(e: any) => {
            updateFields({ reproductionPackageFromScratch: e.target.value });
          }}
          questionText="If there are no reproduction packages, are you willing to build a reproduction package from scratch?"
          readOnly={readOnly}
          disabled={hasReproductionPackage}
        />
        <div>
          {hasReproductionPackage ? (
            <Box pb={2}>
              <div className={classes.section}>
                {reproduction.originalReproductionPackages.isEmpty() ? (
                  <Typography
                    className={classes.noPackages}
                    variant="body1"
                    gutterBottom
                  >
                    You currently have no reproduction packages specified. Add
                    one by clicking the{" "}
                    <strong>Add reproduction package</strong> button.
                  </Typography>
                ) : (
                  <div>
                    {reproduction.originalReproductionPackages.map(
                      editReproductionPackage(readOnly)
                    )}
                  </div>
                )}
                {!readOnly && (
                  <Button
                    startIcon={<Add />}
                    variant={"outlined"}
                    color={"primary"}
                    onClick={addReproductionPackage}
                  >
                    Add additional reproduction packages for data
                  </Button>
                )}
              </div>
            </Box>
          ) : (
            <></>
          )}
          {canBeDeclared ? (
            <Button
              variant="contained"
              color="primary"
              onClick={declarePaper}
              size="small"
            >
              You can declare this paper and continue the scoping portion of the
              exercise.
            </Button>
          ) : cannotBeDeclared ? (
            <Box mt={4} mb={4}>
              <Typography>
                Without a reproduction package, you cannot declare this paper
                and continue to the next stage of this reproduction. Please
                record this paper as abandoned if you're unable to locate its
                reproduction package.
              </Typography>
              <Box>
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Record this paper as "abandoned"
                </Button>
                <Modal
                  open={modalOpen}
                  onClose={handleClose}
                  aria-labelledby="Abandon candidate paper modal"
                >
                  <div style={getModalStyle()} className={classes.paper}>
                    <p>
                      Abandoned papers will be recorded under the{" "}
                      <em>public, identified</em> privacy settings. Do you want
                      to record this paper as abandoned?
                    </p>
                    <Box display="flex" flexDirection="row">
                      <Box pr={2}>
                        <Button
                          onClick={leavePaperUndeclared}
                          variant="contained"
                          color="primary"
                          size="small"
                        >
                          Yes
                        </Button>
                      </Box>
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="secondary"
                        size="small"
                      >
                        Cancel
                      </Button>
                    </Box>
                  </div>
                </Modal>
              </Box>
            </Box>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({
  editReproduction: { reproduction },
}: {
  editReproduction: EditReproduction;
}) => {
  return { reproduction };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveFields: (nextUrl: string, workflowStage: string) =>
      dispatch(saveFields({ nextUrl, workflowStage })),
    updateFields: (fields: any) => dispatch(updateFields(fields)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBasicInformation);
