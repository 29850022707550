import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import {
  Assessment,
  CodeScriptRow,
  EditAssessment,
} from "../../../state/edit-assessment";
import { updateAssessmentFields } from "../../../actions/assessment.actions";

import drawTree from "diff-tree/src/drawTree";
import ExternalLink from "../../../components/external-link";
import Button from "@material-ui/core/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FileCopy } from "@material-ui/icons";
import { Box, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otherField: {
      minWidth: 320,
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    ...questionStyles(1, theme),
  })
);

const SEPARATOR = ";";

const initializePlaceholderGenerator = () => {
  let counters = {
    input: 0,
    output: 0,
    code: 0,
  };
  const nextPlaceholder = (type) => {
    counters[type] += 1;
    return `${type} placeholder ${counters[type]}`;
  };
  return nextPlaceholder;
};

const usePlaceholderIfNecessary = (
  codeScriptRow: CodeScriptRow,
  dataType: string
) => {
  let placeholderType;
  switch (dataType) {
    case "outputs":
      placeholderType = "output";
      break;
    case "inputs":
      placeholderType = "input";
  }
  let codeScriptArray = codeScriptRow[dataType]
    .split(SEPARATOR)
    .filter((s: string) => s); // filter out any empty strings

  // If every string in cell was empty, we need to replace the whole thing with a placeholder
  if (codeScriptArray.length == 0) {
    codeScriptArray.push(replaceWithPlaceholder(placeholderType, ""));
  }
  return codeScriptArray;
};

let placeholderGenerator;

const replaceWithPlaceholder = (type, value) =>
  value === "" ? placeholderGenerator(type) : value;

function buildOutputToInputMap({ assessment }: { assessment: Assessment }) {
  let map = { codeMap: {} };
  // from codeScriptRow, get fileName,inputs,outputs
  assessment.codeScriptRows.forEach((codeScriptRow) => {
    if (
      codeScriptRow.outputs === "" &&
      codeScriptRow.inputs === "" &&
      codeScriptRow.fileName === ""
    ) {
      return;
    }
    const outputs = usePlaceholderIfNecessary(codeScriptRow, "outputs");
    const processedInputs = usePlaceholderIfNecessary(codeScriptRow, "inputs");
    outputs.forEach((output) => {
      map.codeMap[output] = {
        code: replaceWithPlaceholder("code", codeScriptRow.fileName.trim()),
        inputs: processedInputs,
      };
    });
  });

  return map;
}

function buildChildrenTree(codeMap, output, visitedSet) {
  const inputs = codeMap[output].inputs;
  visitedSet.add(output);
  // Use partition here instead
  const terminalInputs = inputs
    .filter((input) => {
      return !codeMap.hasOwnProperty(input) || visitedSet.has(input);
    })
    .map((input) =>
      visitedSet.has(input) ? `${input} - CYCLE DETECTED` : input
    );
  const recursiveOutputs = inputs.filter((input) => {
    return codeMap.hasOwnProperty(input) && !visitedSet.has(input);
  });
  const recursiveTrees = recursiveOutputs.map((childOutput) => {
    return buildChildrenTree(codeMap, childOutput, visitedSet);
  });
  const tree = {};
  tree[output] = {
    childrenDirs: {
      [codeMap[output].code]: {
        childrenDirs: Object.assign({}, ...recursiveTrees),
        childrenFiles: terminalInputs.map((input) => {
          return {
            path: input,
            status: " ",
          };
        }),
      },
    },
    childrenFiles: [],
  };
  return tree;
}

// Copied from MDN
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
function difference(setA, setB) {
  let _difference = new Set(setA);
  for (let elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

function union(setA, setB) {
  let _union = new Set(setA);
  for (let elem of setB) {
    _union.add(elem);
  }
  return _union;
}

function buildOutputToInputTree({ codeMap }) {
  const allOutputs = Object.keys(codeMap);
  const allInputs = new Set(
    allOutputs.map((output) => codeMap[output].inputs).flat()
  );
  const terminalOutputs = allOutputs.filter((output) => !allInputs.has(output));
  let filesUsedInTerminalOutputTrees = new Set();
  const terminalOutputTrees = terminalOutputs.map((output) => {
    const visitedSet = new Set();
    const terminalOutputTree = buildChildrenTree(codeMap, output, visitedSet);
    // merge nodes visited in this particular tree to overall visited set
    filesUsedInTerminalOutputTrees = new Set([
      ...filesUsedInTerminalOutputTrees,
      ...visitedSet,
    ]);
    return terminalOutputTree;
  });

  const circularOutputs = difference(
    new Set(allOutputs),
    filesUsedInTerminalOutputTrees
  );

  let circularOutputTrees = [];
  while (circularOutputs.size > 0) {
    const circularOutput = circularOutputs.values().next().value;
    const visitedSet = new Set();
    const circularTree = buildChildrenTree(codeMap, circularOutput, visitedSet);
    for (let visited of visitedSet) {
      circularOutputs.delete(visited);
    }
    circularOutputTrees.push(circularTree);
  }
  return [...terminalOutputTrees, ...circularOutputTrees];
}

function DiagramBuilder({ assessment }: { assessment: Assessment }) {
  const [clipboardSnackbarOpen, setClipboardSnackbarOpen] = React.useState(
    false
  );

  const classes = useStyles();

  placeholderGenerator = initializePlaceholderGenerator();

  const outputToInputMap = buildOutputToInputMap({ assessment });
  const trees = buildOutputToInputTree(outputToInputMap);

  const usedOutputs = new Set(Object.keys(outputToInputMap.codeMap));
  const usedInputs = new Set(
    Object.values(outputToInputMap.codeMap).flatMap((x) => x.inputs)
  );

  const allUsedFiles = union(usedOutputs, usedInputs);

  // get dataFiles from dataSourceRows
  const dataSourceFiles = new Set(
    assessment.dataSourceRows.flatMap((dataSourceRow) =>
      dataSourceRow.dataFiles.split(SEPARATOR).map((s) => s.trim())
    )
  );

  // get analyticData from analyticDataRows
  const analyticDataFiles = new Set(
    assessment.analyticDataRows.map(
      (analyticDataRow) => analyticDataRow.analyticData
    )
  );

  const unusedDataSourceFiles = difference(dataSourceFiles, allUsedFiles);

  const unusedAnalyticDataFiles = difference(analyticDataFiles, allUsedFiles);

  const diagramTrees = trees.map((tree) => drawTree(tree));

  return (
    <>
      <Typography variant="h4" gutterBottom>
        ACRe Diagram Builder
      </Typography>
      <Typography variant="body1" component="div" gutterBottom>
        <p>
          This page shows a diagram mapping input files through code to outputs
          based on the <strong>Code Scripts</strong> table in question 1.3.
        </p>
        <p>
          If you notice trees are fragmented when they shouldn't be, you may
          need to amend the table with placeholders. See{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/assessment.html#incomplete-workflow-information">
            the ACRe Guide for more information.
          </ExternalLink>
        </p>
        <p>
          Even if the tree is fragmented, you can still go on to the next step
          of the reproduction.
        </p>
      </Typography>
      <Box>
        <CopyToClipboard
          text={diagramTrees.join("\n")}
          onCopy={() => setClipboardSnackbarOpen(true)}
        >
          <Button>
            <FileCopy />
            Copy tree to clipboard
          </Button>
        </CopyToClipboard>
      </Box>
      {diagramTrees.map((tree, index) => (
        <pre key={index}>{tree}</pre>
      ))}
      <div>
        <strong>Unused data sources:</strong>
        {unusedDataSourceFiles.size == 0 ? (
          <pre>None - all data source files were used</pre>
        ) : (
          <pre>{Array.from(unusedDataSourceFiles).join("\n")}</pre>
        )}
      </div>
      <div>
        <strong>Unused analytic data:</strong>
        {unusedAnalyticDataFiles.size == 0 ? (
          <pre>None - all analytic data files were used</pre>
        ) : (
          <pre>{Array.from(unusedAnalyticDataFiles).join("\n")}</pre>
        )}
      </div>
      <Snackbar
        open={clipboardSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setClipboardSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setClipboardSnackbarOpen(false)}
          severity="success"
        >
          Copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = ({
  editAssessment: { assessment },
}: {
  editAssessment: EditAssessment;
}) => {
  return { assessment };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAssessmentFields: (fields: any) =>
      dispatch(updateAssessmentFields(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiagramBuilder);
