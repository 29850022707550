import React from "react";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import QuestionWithRadioGroup from "../../components/question-with-radio-group";
import { questionStyles } from "../../css";
import ExternalLink from "../../../../components/external-link";

const useStyles = (sectionNumber: String) =>
  makeStyles((theme: Theme) =>
    createStyles({
      readOnly: {
        padding: theme.spacing(1),
        border: "1px solid",
        backgroundColor: "#f1f1f1",
      },
      ...questionStyles(sectionNumber, theme),
    })
  );

function AddOriginalReproductionPackages({
  existingReproductionsLink,
  formik,
  readOnly,
}: {
  existingReproductionsLink: string;
  formik: any;
  readOnly: boolean;
}) {
  const classes = useStyles("2")();

  return (
    <>
      {existingReproductionsLink.length > 0 ? (
        // Scenario 1: SSRP has recorded existing packages already
        <Typography>
          Our database has recorded existing reproduction packages (
          {existingReproductionsLink && (
            <ExternalLink href={existingReproductionsLink}>
              {existingReproductionsLink}
            </ExternalLink>
          )}
          ) for this paper. Kindly check it and decide if this reproduction
          package is enough to carry out a reproduction exercise.
        </Typography>
      ) : (
        // Scenario 2: SSRP has NOT recorded existing packages
        <Typography>
          Our database has recorded 0 reproduction packages for this paper.
          Kindly verify if a reproduction package is available. (Consult the
          ACRe Guide for recommended steps)
        </Typography>
      )}

      <div className={classes.section}>
        <QuestionWithRadioGroup
          classes={classes}
          name="reproductionPackageAvailable"
          value={formik.values.reproductionPackageAvailable}
          onChange={formik.handleChange}
          valuesAndLabels={[
            {
              value: "true:full",
              label:
                "Yes, with enough materials to carry out a reproduction exercise.",
            },
            {
              value: "false:partial",
              label:
                "Yes, but not enough materials to carry out a reproduction exercise.",
            },
            {
              value: "false:none",
              label: "No reproduction materials.",
            },
          ]}
          readOnly={readOnly}
        >
          Is a reproduction package available for this paper?
        </QuestionWithRadioGroup>

        {/* Only shows if user clicks the above second option `Yes, but not enough materials to carry out a reproduction exercise.` */}
        <QuestionWithRadioGroup
          classes={classes}
          name="reproductionPackageAvailablePartialReason"
          value={formik.values.reproductionPackageAvailablePartialReason}
          onChange={formik.handleChange}
          disabled={
            formik.values.reproductionPackageAvailable !== "false:partial"
          }
          valuesAndLabels={[
            {
              value: "only code",
              label: "Only code is available.",
            },
            {
              value: "partial analysis data",
              label: "Only partial analysis data is available.",
            },
          ]}
          readOnly={readOnly}
        >
          Why is the material not enough for carrying out a reproduction
          exercise?
        </QuestionWithRadioGroup>
      </div>
    </>
  );
}

export default AddOriginalReproductionPackages;
