import Typography from "@material-ui/core/Typography";
import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import PreEstimateTable from "../../../components/estimates-table";

import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Button } from "gatsby-material-ui-components";
import { Add, Delete } from "@material-ui/icons";
import {
  ClaimRobustness,
  EditRobustness,
  ReasonableRobustnessCheck,
  Robustness,
  RobustnessMappingRow,
} from "../../../state/edit-robustness";
import {
  deleteRobustnessMappingRow,
  updateRobustnessFields,
  updateRobustnessMappingRow,
} from "../../../actions/robustness.actions";
import { DataGrid } from "../../../components/datagrid";
import CSVDownloadButton from "../components/csv-download-button";
import CSVUploadButton from "../components/csv-upload-button";
import { List } from "immutable";
import { camelizeKeys, decamelize, decamelizeKeys } from "humps";
import QuestionWithTextField from "../components/question-with-text-field";
import { QuestionWithTrueFalseRadio } from "../components/question-with-true-false-radio";
import ExternalLink from "../../../components/external-link";
import SaveMessage from "../../save-message";

const useStyles = (sectionNumber) =>
  makeStyles((theme: Theme) =>
    createStyles({
      otherField: {
        minWidth: 320,
      },
      formField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: 600,
      },
      displayItem: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      paperLevelImprovement: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: 600,
      },
      readOnly: {
        padding: theme.spacing(1),
        border: "1px solid",
        backgroundColor: "#f1f1f1",
      },
      ...questionStyles(sectionNumber, theme),
    })
  );

export function EditClaimRobustness({
  claimRobustness,
  claimIndex,
  updateClaimRobustness,
  updateRobustnessMappingRow,
  deleteRobustnessMappingRow,
  readOnly,
  questionClass,
}: {
  claimRobustness: ClaimRobustness;
  claimIndex: number;
  updateClaimRobustness: Function;
  updateRobustnessMappingRow: Function;
  deleteRobustnessMappingRow: Function;
  readOnly: boolean;
  questionClass: any;
}) {
  const classes = useStyles(`${claimIndex + 1}`)();

  const robustnessMappingColumns = [
    { headerName: "Entry Id", field: "entryId", flex: 1 },
    { headerName: "File Name", field: "fileName", flex: 1 },
    { headerName: "Line Number", field: "lineNumber", flex: 1 },
    { headerName: "Choice Type", field: "choiceType", flex: 1 },
    { headerName: "Choice Value", field: "choiceValue", flex: 2 },
    { headerName: "Choice Range (optional)", field: "choiceRange", flex: 2 },
    { headerName: "Source", field: "source", flex: 1 },
  ];

  const robustnessMappingColumnsForCSV = robustnessMappingColumns.map((rbc) =>
    decamelize(rbc.field)
  );

  const addRobustnessMappingRow = () => {
    updateClaimRobustness(
      claimRobustness.set(
        "robustnessMappingRows",
        claimRobustness.robustnessMappingRows.push(new RobustnessMappingRow())
      )
    );
  };

  const uploadRobustnessMappingRows = (csvRows) => {
    let robustnessMappingRows = List(
      csvRows.map(({ data }) => new RobustnessMappingRow(camelizeKeys(data)))
    );
    updateClaimRobustness(
      claimRobustness.set("robustnessMappingRows", robustnessMappingRows)
    );
  };

  const handleOnError = (err) => {
    alert(
      `Error occurred while uploading CSV: ${err}.\nPlease download template and try again.`
    );
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Increasing the number of feasible specifications
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={questionClass}
          gutterBottom
        >
          <p>
            One option you have for conducting robustness checks is to increase
            the number of feasible robustness checks. This requires that you
            identify the specific line(s) in the code scripts that execute an
            analytical choice. An advantage of this type of contribution is that
            you don’t need to have an in-depth knowledge of the paper and its
            methodology to contribute. This allows you to potentially map
            several code files, achieving a broader understanding of the paper,
            and also building on top of the work of others. The disadvantage is
            that you are not expected to test alternative specifications.
          </p>
          <p>Steps for recording specific analytical choices:</p>
          <ol>
            <li>
              Review a specific code file (e.g. <code>clean_merged_1_2.do</code>
              ) and identify an analytical choice (e.g.{" "}
              <code>regress y x if gender == 1</code>).
            </li>
            <li>
              Record the file name, line number, reproduction package (original
              or name of revised version), choice type, and choice value, as
              they appear in the original reproduction package. For the{" "}
              <code>source</code> field, type “original” whenever the analytical
              choice is identified for the first time, and{" "}
              <code>file_name-line number</code> each time the same analytical
              choice is applied thereafter (for example, if an analytical choice
              is identified for the first time in line #103 and for the second
              time in line #122 their respective values for the{" "}
              <code>source</code> field should be <code>original</code> and{" "}
              <code>code_01.do-L103</code>). For each analytical choice
              recorded, add the specific choice used in the paper, and describe
              what alternatives could have been used in the{" "}
              <code>Choice Range</code>
              column (e.g., "min, max" or "choice 1, choice 2, choice 3").
            </li>
          </ol>
          Read more about increasing the number of feasible specifications in
          the{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/robust.html#feasible-robustness-checks-increasing-the-number-of-feasible-specifications">
            ACRe Guide
          </ExternalLink>{" "}
          and see{" "}
          <ExternalLink href="https://osf.io/57h3s/">
            an example here
          </ExternalLink>
          .
        </Typography>
        <Typography>
          Q: Please record the analytical choices as they appear in the original
          reproduction package. If you need to record analytical choices that
          can only be found in the revised reproduction package, indicate that
          here.
        </Typography>
        {readOnly ? (
          claimRobustness.isRevisedReproductionPackageReferenced === "true" ? (
            <Typography
              className={classes.readOnly}
              style={{ whiteSpace: "pre-wrap" }}
            >
              Revised reproduction package:{" "}
              {claimRobustness.revisedReproductionPackage}
            </Typography>
          ) : (
            <Typography
              className={classes.readOnly}
              style={{ whiteSpace: "pre-wrap" }}
            >
              No revised reproduction package used
            </Typography>
          )
        ) : (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    claimRobustness.isRevisedReproductionPackageReferenced ===
                    "true"
                  }
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      updateClaimRobustness(
                        claimRobustness.set(
                          "isRevisedReproductionPackageReferenced",
                          "true"
                        )
                      );
                    } else {
                      updateClaimRobustness(
                        claimRobustness.set(
                          "isRevisedReproductionPackageReferenced",
                          "false"
                        )
                      );
                    }
                  }}
                  color="primary"
                />
              }
              label="I will need to reference analytical choices in a revised reproduction package."
            />
            {claimRobustness.isRevisedReproductionPackageReferenced ===
              "true" && (
              <QuestionWithTextField
                readOnly={readOnly}
                questionClass={questionClass}
                label="Revised reproduction package location"
                multiline
                fullWidth
                variant="outlined"
                value={claimRobustness.revisedReproductionPackage}
                onChange={(e) =>
                  updateClaimRobustness(
                    claimRobustness.set(
                      "revisedReproductionPackage",
                      e.target.value
                    )
                  )
                }
                maxLength={300}
              >
                Please record the location of the reproduction packages that you
                will be referencing here:
              </QuestionWithTextField>
            )}
          </>
        )}
      </CardContent>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Claim {claimIndex + 1} - {claimRobustness.shortDescription}
        </Typography>
        {!readOnly && (
          <>
            <Button
              startIcon={<Add />}
              variant={"contained"}
              color={"primary"}
              onClick={addRobustnessMappingRow}
            >
              Add analytical choice
            </Button>
            <CSVUploadButton
              onDrop={uploadRobustnessMappingRows}
              onError={handleOnError}
            />
          </>
        )}
        <CSVDownloadButton
          data={decamelizeKeys(claimRobustness.robustnessMappingRows.toJS())}
          filename="analytical_choices"
          columns={robustnessMappingColumnsForCSV}
        />
        <DataGrid
          readOnly={readOnly}
          rows={claimRobustness.robustnessMappingRows}
          columns={robustnessMappingColumns}
          updateRowAtIndex={({ rowIndex, updatedRow }) => {
            updateRobustnessMappingRow({
              claimIndex,
              rowIndex,
              updatedRow,
            });
          }}
          deleteRowAtIndex={({ rowIndex }) => {
            deleteRobustnessMappingRow({
              claimIndex,
              rowIndex,
            });
          }}
        />
      </CardContent>
    </Card>
  );
}

export function JustifyAndTestRobustnessCheck({
  reasonableRobustnessCheck,
  index,
  claimIndex,
  updateReasonableRobustnessCheck,
  deleteReasonableRobustnessCheck,
  readOnly,
  estimates,
}: {
  reasonableRobustnessCheck: ReasonableRobustnessCheck;
  index: number;
  claimIndex: number;
  updateReasonableRobustnessCheck: Function;
  deleteReasonableRobustnessCheck: Function;
  readOnly: boolean;
  estimates: any;
}) {
  const classes = useStyles(`${claimIndex + 1}`)();

  const onChange = (name: string) => (e: any) => {
    updateReasonableRobustnessCheck(
      reasonableRobustnessCheck.merge({ [name]: e.target.value })
    );
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Justifying and testing a reasonable robustness check
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          In this section, you can choose to justify and test a specific
          analytical choice. This requires that you identify a feasible
          analytical choice, conduct a variation on it, and justify its
          reasonableness. The advantage of this approach is that it allows for
          an in-depth inspection of a specific section of the paper.
        </Typography>
        <QuestionWithTextField
          classes={classes}
          questionClass={classes.question}
          readOnly={readOnly}
          fullWidth
          value={reasonableRobustnessCheck.analyticalChoiceIdentifier}
          onChange={onChange("analyticalChoiceIdentifier")}
        >
          Record identifier(s) corresponding to the analytical choice(s) to test
          (<code>Entry Id</code>) from the above tables. Separate multiple
          identifiers using a comma.
        </QuestionWithTextField>
        <QuestionWithTextField
          classes={classes}
          questionClass={classes.question}
          readOnly={readOnly}
          fullWidth
          value={reasonableRobustnessCheck.analyticalChoiceVariation}
          onChange={onChange("analyticalChoiceVariation")}
        >
          Propose a specific variation to this analytical choice.
        </QuestionWithTextField>
        <QuestionWithTextField
          classes={classes}
          questionClass={classes.question}
          readOnly={readOnly}
          multiline
          fullWidth
          variant="outlined"
          rows={10}
          value={reasonableRobustnessCheck.variationSensibleExplanation}
          onChange={onChange("variationSensibleExplanation")}
        >
          Discuss whether you think this variation is sensible, specifically in
          the context of the claim tested.
        </QuestionWithTextField>
        <QuestionWithTextField
          classes={classes}
          questionClass={classes.question}
          readOnly={readOnly}
          multiline
          fullWidth
          variant="outlined"
          rows={10}
          value={reasonableRobustnessCheck.variationAffectValidityExplanation}
          onChange={onChange("variationAffectValidityExplanation")}
        >
          Discuss how this variation could affect the validity of the results
          (e.g., likely effects on omitted variable bias, measurement error,
          change in the Local Average Treatment Effects for the underlying
          population).
        </QuestionWithTextField>
        <QuestionWithTrueFalseRadio
          classes={classes}
          questionText="Confirm that test is not redundant with other tests in the paper or robustness exercise."
          value={reasonableRobustnessCheck.testRedundancyConfirmation}
          onChange={onChange("testRedundancyConfirmation")}
          readOnly={readOnly}
        />
        <br />
        <PreEstimateTable estimates={estimates} />
        <QuestionWithTextField
          classes={classes}
          questionClass={classes.question}
          readOnly={readOnly}
          multiline
          fullWidth
          variant="outlined"
          rows={10}
          value={reasonableRobustnessCheck.results}
          onChange={onChange("results")}
        >
          Report the results from the robustness check (new estimate, standard
          error, and units), and discuss differences with the pre-specified
          estimates for this claim.
        </QuestionWithTextField>
        {index >= 1 && (
          <Button
            className={classes.deleteButton}
            startIcon={<Delete />}
            color="secondary"
            variant="contained"
            onClick={deleteReasonableRobustnessCheck}
            size="small"
          >
            Delete Additional Reasonable Robustness Check
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

function ClaimRobustnessChecks({
  robustness,
  updateRobustnessFields,
  updateRobustnessMappingRow,
  deleteRobustnessMappingRow,
  claim,
  estimates,
  claimIndex,
  readOnly,
}: {
  robustness: Robustness;
  updateRobustnessFields: Function;
  updateRobustnessMappingRow: Function;
  deleteRobustnessMappingRow: Function;
  claim: ClaimRobustness;
  estimates: any;
  claimIndex: number;
  readOnly: boolean;
}) {
  const classes = useStyles(`${claimIndex + 1}`)();

  const updateClaimRobustness = (value: any) => {
    updateRobustnessFields({
      claims: robustness.claims.set(claimIndex, value),
    });
  };

  const addReasonableRobustnessCheck = () => {
    updateClaimRobustness(
      claim.set(
        "reasonableRobustnessChecks",
        claim.reasonableRobustnessChecks.push(new ReasonableRobustnessCheck())
      )
    );
  };

  const deleteReasonableRobustnessCheck = (index: number) => () => {
    updateClaimRobustness(
      claim.set(
        "reasonableRobustnessChecks",
        claim.reasonableRobustnessChecks.delete(index)
      )
    );
  };

  const justifyAndTestRobustnessCheck = (claimRobustness: ClaimRobustness) => (
    reasonableRobustnessCheck: ReasonableRobustnessCheck,
    index: number
  ) => {
    const updateReasonableRobustnessCheck = (
      value: ReasonableRobustnessCheck
    ) => {
      updateClaimRobustness(
        claimRobustness.set(
          "reasonableRobustnessChecks",
          claimRobustness.reasonableRobustnessChecks.set(index, value)
        )
      );
    };
    return (
      <JustifyAndTestRobustnessCheck
        readOnly={readOnly}
        key={index}
        index={index}
        claimIndex={claimIndex}
        questionClass={classes.question}
        reasonableRobustnessCheck={reasonableRobustnessCheck}
        updateReasonableRobustnessCheck={updateReasonableRobustnessCheck}
        deleteReasonableRobustnessCheck={deleteReasonableRobustnessCheck(index)}
        estimates={estimates}
      />
    );
  };

  return claim ? (
    <>
      <Typography variant="h4" gutterBottom>
        Checking for Robustness
      </Typography>
      <Typography variant="body1" component="div" gutterBottom>
        <p>
          The universe of robustness checks can be very large (potentially
          infinite!) and they can pertain both data analysis and data cleaning.
          We will distinguish between <strong>reasonable</strong> and{" "}
          <strong>feasible</strong> robustness checks.
        </p>
        <p>
          <strong>Reasonable robustness checks</strong> (
          <ExternalLink href="https://urisohn.com/sohn_files/wp/wordpress/wp-content/uploads/Paper-Specification-curve-2018-11-02.pdf">
            Simonsohn et. al., 2018
          </ExternalLink>
          ). are defined as (i) sensible tests of the research question, (ii)
          expected to be statistically valid, and (iii) not redundant with other
          specifications in the set.
        </p>
        <p>
          The set of <strong>feasible robustness checks</strong> is defined by
          all the specifications that can be computationally reproduced. We
          assume that the specifications already published in the paper are part
          of the reasonable set of specifications.
        </p>
        <SaveMessage />
      </Typography>
      <div className={classes.section}>
        <EditClaimRobustness
          key={claimIndex}
          claimRobustness={claim}
          claimIndex={claimIndex}
          questionClass={classes.question}
          updateClaimRobustness={updateClaimRobustness}
          updateRobustnessMappingRow={updateRobustnessMappingRow}
          deleteRobustnessMappingRow={deleteRobustnessMappingRow}
          readOnly={readOnly}
        />
        {claim.reasonableRobustnessChecks.map(
          justifyAndTestRobustnessCheck(claim)
        )}
        {!readOnly && (
          <Button
            startIcon={<Add />}
            variant={"outlined"}
            color={"primary"}
            onClick={addReasonableRobustnessCheck}
          >
            Add additional reasonable robustness check
          </Button>
        )}
      </div>
    </>
  ) : (
    <></>
  );
}

const mapStateToProps = ({
  editRobustness: { robustness },
}: {
  editRobustness: EditRobustness;
}) => {
  return { robustness };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateRobustnessFields: (fields: any) =>
      dispatch(updateRobustnessFields(fields)),
    updateRobustnessMappingRow: (fields: any) =>
      dispatch(updateRobustnessMappingRow(fields)),
    deleteRobustnessMappingRow: (fields: any) =>
      dispatch(deleteRobustnessMappingRow(fields)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimRobustnessChecks);
