import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Container, Fab, LinearProgress } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Save } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import PaperLevelImprovements from "./paper-level-improvements";
import DisplayItemImprovements from "./display-item-improvements";
import {
  fetchImprovement,
  saveImprovementFields,
} from "../../../actions/improvement.actions";
import queryString from "query-string";
import { navigate } from "@reach/router";
import ExternalLink from "../../../components/external-link";
import NotFound from "../../../pages/404";
import { EditImprovement } from "../../../state/edit-improvement";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightGroup: {
      marginLeft: "auto",
      display: "inline-flex",
    },
    actionBar: {
      display: "flex",
      padding: 8,
      alignItems: "center",
    },
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    floatingSaveButton: {
      position: "-webkit-sticky",
      position: "sticky",
      left: "90%",
      bottom: "40px",
    },
  })
);

function getFullSteps() {
  return ["Display item improvements", "Paper level improvements"];
}

function getStepContent(
  step: number,
  readOnly: boolean,
  handleGoToRobustness: Function
) {
  switch (step) {
    case 0:
      return <DisplayItemImprovements readOnly={readOnly} />;
    case 1:
      return (
        <PaperLevelImprovements
          readOnly={readOnly}
          handleGoToRobustness={handleGoToRobustness}
        />
      );
    case 2:
      return "";
    default:
      return "";
  }
}

function ActionBar({
  disableBack = false,
  disableNext = false,
  handleBack,
  handleUp,
  handleNext,
}: {
  disableBack: boolean;
  disableNext: boolean;
  handleBack: any;
  handleUp: Function;
  handleNext: any;
}) {
  const classes = useStyles();
  return (
    <div className={classes.actionBar}>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUp}
          className={classes.button}
        >
          Return to Stages Overview
        </Button>
        <Button
          variant="contained"
          onClick={handleBack}
          className={classes.button}
          disabled={disableBack}
        >
          Previous Step
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disabled={disableNext}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
}

const HorizontalNonLinearStepper: React.SFC<any> = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);

  useEffect(() => {
    setActiveStep(props.currentStep || 0);
  }, [props.currentStep]);

  const isAuthor = props.editImprovement.improvement.isAuthor === "true";

  const viewingShareableLink = props.editImprovement.improvement.shareableLink;

  const readOnly =
    "submitted" === props.editImprovement.improvement.workflowStage ||
    !isAuthor ||
    viewingShareableLink;

  useEffect(() => {
    if (!readOnly) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [readOnly]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const steps = getFullSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const isFirstStep = (): boolean => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    handleStep(activeStep + 1)();
  };

  const handleSave = () => {
    props.saveImprovementFields(props.id);
  };

  const handleBack = () => {
    handleStep(activeStep - 1)();
  };

  const handleUp = () => {
    const nextUrl = `index?step=3`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      props.saveImprovementFields(props.id, nextUrl);
    }
  };

  const handleStep = (nextStep: number) => () => {
    const nextUrl = `improvement?step=${nextStep}`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      props.saveImprovementFields(props.id, nextUrl);
    }
    window.scrollTo({ top: 0 });
  };

  const handleGoToRobustness = () => {
    props.saveImprovementFields(
      props.id,
      `/reproductions/${props.id}/robustness`
    );
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          opacity: props.editImprovement.saving ? 1 : 0,
          transition: "opacity 300ms",
        }}
      >
        <LinearProgress />
      </div>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <ActionBar
        disableBack={isFirstStep()}
        disableNext={isLastStep()}
        handleBack={handleBack}
        handleUp={handleUp}
        handleNext={handleNext}
      />
      {!props.editImprovement.loading && (
        <div>{getStepContent(activeStep, readOnly, handleGoToRobustness)}</div>
      )}
      <ActionBar
        disableBack={isFirstStep()}
        disableNext={isLastStep()}
        handleUp={handleUp}
        handleBack={handleBack}
        handleNext={handleNext}
      />
      {!readOnly && (
        <Fab
          variant="extended"
          color="secondary"
          onClick={handleSave}
          className={[classes.button, classes.floatingSaveButton].join(" ")}
        >
          <Save />
          Save
        </Fab>
      )}
    </div>
  );
};

const mapStateToProps = ({
  editImprovement,
  user,
}: {
  editImprovement: EditImprovement;
  user: User;
}) => {
  return { editImprovement, user };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveImprovementFields: (reproductionId: number, nextUrl: string) =>
      dispatch(saveImprovementFields({ reproductionId, nextUrl })),
    fetchImprovement: (id: number, mode: string) =>
      dispatch(fetchImprovement({ id, mode })),
  };
};

function Index(props: {
  id: number;
  path: string;
  location: string;
  editImprovement: EditImprovement;
  fetchImprovement: (id: number, mode: string) => void;
}) {
  const isPublishedView = location.pathname.split("/").includes("published");
  useEffect(() => {
    props.fetchImprovement(props.id, isPublishedView && "published");
  }, [isPublishedView]);

  const queryParams = queryString.parse(props.location.search);
  const currentStep = parseInt(queryParams.step);
  return props.editImprovement.notFound ? (
    <NotFound />
  ) : (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" align="center">
          Improvement
        </Typography>
        <Typography>
          Record and/or propose ways to improve the reproducibility of
          individual display items and the overall paper.{" "}
          <strong>
            Note that you can start with either segment or work on both
            concurrently.
          </strong>{" "}
          See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/improvements.html">
            here
          </ExternalLink>
          .
        </Typography>
        <HorizontalNonLinearStepper {...props} currentStep={currentStep} />
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
