import React, { useEffect } from "react";
import { Box, Container, LinearProgress } from "@material-ui/core";
import { navigate } from "@reach/router";
import axios from "axios";
import { camelizeKeys } from "humps";
import { useFormik } from "formik";
import MinimumReproduction from "./minimum-reproduction";
import { ReproductionPackage } from "../../../state/edit-reproduction";

function Index(props: {
  id: number;
  path: string;
  location: string;
  readOnly: boolean;
}) {
  const [saving, setSaving] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [formValues, setFormValues] = React.useState({
    paper: {},
    reproductionPackageAvailable: "",
    revisedReproductionPackages: [
      new ReproductionPackage({ stage: "revised", contentType: "code" }),
    ],
    paperLevelProprietaryConfidentialDataRequired: "",
    paperLevelProprietaryConfidentialDataOther: "",
    paperLevelReproducibilityScore: "",
    additionalReport: "",
  });

  const isPublishedView = location.pathname.split("/").includes("published");

  const fetchMinimumReproduction = async () => {
    setLoading(true);
    const queryParams = isPublishedView ? "?mode=published" : "";

    axios
      .get(`/api/minimum_reproductions/${props.id}.json${queryParams}`)
      .then(function (response) {
        const updatedValues = camelizeKeys(response.data);
        if (response.data.revised_reproduction_packages?.length > 0) {
          updatedValues.revisedReproductionPackages = response.data.revised_reproduction_packages.map(
            (rrp) => new ReproductionPackage(rrp)
          );
        }

        setFormValues({
          ...formValues,
          ...updatedValues,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMinimumReproduction();
  }, []);

  const handleSave = async () => {
    await saveMinimumReproduction(formik.values);
  };

  const saveMinimumReproduction = async (values) => {
    setSaving(true);
    await axios.patch(`/api/minimum_reproductions/${props.id}.json`, values);
    setSaving(false);
  };

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await saveMinimumReproduction({
        ...values,
        workflowStage: "submit",
      });

      navigate(`/reproductions/home`);
    },
  });

  return (
    <Container maxWidth="md">
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          opacity: saving ? 1 : 0,
          transition: "opacity 300ms",
        }}
      >
        <LinearProgress />
      </div>
      <Box my={4}>
        <MinimumReproduction
          loading={loading}
          formik={formik}
          handleSave={handleSave}
          readOnly={props.readOnly}
        />
      </Box>
    </Container>
  );
}

export default Index;
