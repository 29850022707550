import { updateFields } from "../../../actions/edit-reproductions.actions";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import React from "react";
import {
  EditReproduction,
  Reproduction,
} from "../../../state/edit-reproduction";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { questionStyles } from "../css";
import { Button } from "gatsby-material-ui-components";
import QuestionWithTextField from "../components/question-with-text-field";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
    ...questionStyles(4, theme),
  })
);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function RobustnessChecks({
  reproduction,
  updateFields,
  handleGoToAssessment,
  readOnly,
}: {
  reproduction: Reproduction;
  updateFields: Function;
  handleGoToAssessment: Function;
  readOnly: boolean;
}) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Declare possible robustness checks
      </Typography>
      <div className={classes.section}>
        <QuestionWithTextField
          classes={classes}
          readOnly={readOnly}
          label="Possible robustness check"
          multiline
          fullWidth
          variant="outlined"
          rows={10}
          value={reproduction.possibleRobustnessChecks}
          onChange={(e) =>
            updateFields({ possibleRobustnessChecks: e.target.value })
          }
        >
          After reading the paper, you may wonder what the results would look
          like under a specific robustness check. If you think that such
          analysis could have been done within the same methodology and using
          the same data (e.g., by including or excluding a subset of the data
          like “high-school dropouts” or “women”), please specify a robustness
          test that you would like to conduct as part of this project.
        </QuestionWithTextField>
      </div>
      {!readOnly && (
        <Box mt={4}>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="primary"
            size="small"
          >
            Save and move to assessment stage
          </Button>
          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="Advance to assessment stage modal"
          >
            <div style={getModalStyle()} className={classes.paper}>
              <p>
                Note that once you progress past this stage, you will no longer
                be able to edit your responses in the Scoping stage, though you
                will be able to see them.
              </p>
              <Box display="flex" flexDirection="row">
                <Box pr={2}>
                  <Button
                    onClick={handleGoToAssessment}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Advance to assessment
                  </Button>
                </Box>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                  size="small"
                >
                  Cancel and stay in scoping
                </Button>
              </Box>
            </div>
          </Modal>
        </Box>
      )}
    </>
  );
}

const mapStateToProps = ({
  editReproduction: { reproduction },
}: {
  editReproduction: EditReproduction;
}) => {
  return { reproduction };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateFields: (fields: any) => dispatch(updateFields(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RobustnessChecks);
