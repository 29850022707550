import { List } from "immutable";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import React from "react";

function MultipleSelect({
  labels,
  values,
  disabled,
  selectedValues,
  updateValues,
  classes,
}: {
  labels: Array<any>;
  values: Array<string>;
  disabled: boolean;
  selectedValues: List<string>;
  updateValues: Function;
  classes: any;
}) {
  const onChange = (event: any) => {
    if (event.target.checked && !selectedValues.contains(event.target.name)) {
      return updateValues(selectedValues.push(event.target.name));
    } else {
      return updateValues(
        selectedValues.filter((v) => v !== event.target.name)
      );
    }
  };

  return (
    <FormControl fullWidth disabled={disabled} component="fieldset">
      <FormGroup>
        {labels.map((label, i) => {
          const value = values[i];
          const isSelected = selectedValues.contains(value);
          return (
            <FormControlLabel
              key={i}
              value={value}
              classes={classes}
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={onChange}
                  name={value}
                />
              }
              label={label}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}

export default MultipleSelect;
