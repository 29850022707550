import { updateFields } from "../../../actions/edit-reproductions.actions";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  EditReproduction,
  Reproduction,
  ReproductionPackage,
} from "../../../state/edit-reproduction";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import { Button } from "gatsby-material-ui-components";
import { Add } from "@material-ui/icons";
import { EditReproductionPackage } from "./components/edit-reproduction-package";
import ExternalLink from "../../../components/external-link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: "auto",
    },
    title: {
      fontSize: 14,
    },
    noPackages: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    ...questionStyles(2, theme),
  })
);

function AddReproductionPackages({
  reproduction,
  updateFields,
  readOnly,
}: {
  reproduction: Reproduction;
  updateFields: Function;
  readOnly: boolean;
}) {
  const updateReproductionPackage = (index: number) => (value: any) => {
    updateFields({
      revisedReproductionPackages: reproduction.revisedReproductionPackages.set(
        index,
        value
      ),
    });
  };

  const deleteReproductionPackage = (index: number) => () => {
    updateFields({
      revisedReproductionPackages: reproduction.revisedReproductionPackages.delete(
        index
      ),
    });
  };

  const addReproductionPackage = () => {
    updateFields({
      revisedReproductionPackages: reproduction.revisedReproductionPackages.push(
        new ReproductionPackage({ stage: "revised", contentType: "data" })
      ),
    });
  };

  const classes = useStyles();

  const editReproductionPackage = (readOnly: boolean) => (
    reproductionPackage: ReproductionPackage,
    index: number
  ) => (
    <EditReproductionPackage
      key={index}
      reproductionPackage={reproductionPackage}
      updateReproductionPackage={updateReproductionPackage(index)}
      deleteReproductionPackage={deleteReproductionPackage(index)}
      readOnly={readOnly}
    />
  );

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Add revised reproduction package
      </Typography>
      <div className={classes.section}>
        <Typography
          variant="body1"
          component="div"
          className={classes.question}
          gutterBottom
        >
          Download the original reproduction package, upload it to a new
          repository, and record it below. This will be the{" "}
          <em>revised reproduction package</em> and will store any improvements
          that you will implement as part of this project. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/scoping.html#setup-your-own-revised-reproduction-package">
            here
          </ExternalLink>
          .
        </Typography>
        {reproduction.revisedReproductionPackages.isEmpty() ? (
          <Typography
            className={classes.noPackages}
            variant="body1"
            gutterBottom
          >
            You currently have no reproduction packages specified. Add one by
            clicking the <strong>Add revised reproduction package</strong>{" "}
            button.
          </Typography>
        ) : (
          <div>
            {reproduction.revisedReproductionPackages.map(
              editReproductionPackage(readOnly)
            )}
          </div>
        )}
        {!readOnly && (
          <Button
            startIcon={<Add />}
            variant={"outlined"}
            color={"primary"}
            onClick={addReproductionPackage}
          >
            Add additional reproduction packages for data
          </Button>
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({
  editReproduction: { reproduction },
}: {
  editReproduction: EditReproduction;
}) => {
  return { reproduction };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateFields: (fields: any) => dispatch(updateFields(fields)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddReproductionPackages);
