import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Container, Fab, LinearProgress } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Save } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import DescribeInput from "./describe-input";
import DiagramBuilder from "./diagram-builder";
import DisplayItems from "./display-items";
import PaperLevelReproducibility from "./paper-level-reproducibility";
import {
  fetchAssessment,
  saveAssessmentFields,
} from "../../../actions/assessment.actions";
import { navigate } from "@reach/router";
import queryString from "query-string";
import ExternalLink from "../../../components/external-link";
import { EditAssessment } from "../../../state/edit-assessment";
import NotFound from "../../../pages/404";
import MasterScript from "./master-script";
import DescribeCode from "./describe-code";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightGroup: {
      marginLeft: "auto",
      display: "inline-flex",
    },
    actionBar: {
      display: "flex",
      padding: 8,
      alignItems: "center",
    },
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    floatingSaveButton: {
      position: "-webkit-sticky",
      position: "sticky",
      left: "90%",
      bottom: "40px",
    },
  })
);

function getFullSteps() {
  return [
    "Describe input",
    "Describe code",
    "Diagram builder",
    "Master script",
    "Assess display items",
    "Paper level reproducibility",
  ];
}

function getStepContent(
  step: number,
  readOnly: boolean,
  handleGoToImprovement: Function,
  setNextStepEnabled: Function
) {
  switch (step) {
    case 0:
      return <DescribeInput readOnly={readOnly} />;
    case 1:
      return <DescribeCode readOnly={readOnly} />;
    case 2:
      return <DiagramBuilder />;
    case 3:
      return <MasterScript readOnly={readOnly} />;
    case 4:
      return (
        <DisplayItems
          readOnly={readOnly}
          setNextStepEnabled={setNextStepEnabled}
        />
      );
    case 5:
      return (
        <PaperLevelReproducibility
          readOnly={readOnly}
          handleGoToImprovement={handleGoToImprovement}
        />
      );
    default:
      return "";
  }
}

function ActionBar({
  disableBack = false,
  disableNext = false,
  handleBack,
  handleUp,
  handleNext,
}: {
  disableBack: boolean;
  disableNext: boolean;
  handleBack: any;
  handleUp: Function;
  handleNext: any;
}) {
  const classes = useStyles();
  return (
    <div className={classes.actionBar}>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUp}
          className={classes.button}
        >
          Return to Stages Overview
        </Button>
        <Button
          variant="contained"
          onClick={handleBack}
          className={classes.button}
          disabled={disableBack}
        >
          Previous Step
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disabled={disableNext}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
}

const HorizontalNonLinearStepper: React.SFC<any> = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [nextStepEnabled, setNextStepEnabled] = React.useState<boolean>(true);

  useEffect(() => {
    setNextStepEnabled(true);
    setActiveStep(props.currentStep || 0);
  }, [props.currentStep]);

  const isAuthor = props.editAssessment.assessment.isAuthor === "true";

  const viewingShareableLink = props.editAssessment.assessment.shareableLink;

  const readOnly =
    "submitted" === props.editAssessment.assessment.workflowStage ||
    !isAuthor ||
    viewingShareableLink;

  useEffect(() => {
    if (!readOnly) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [readOnly]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const steps = getFullSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const isFirstStep = (): boolean => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    handleStep(activeStep + 1)();
  };

  const handleSave = () => {
    props.saveAssessmentFields(props.id);
  };

  const handleBack = () => {
    handleStep(activeStep - 1)();
  };

  const handleUp = () => {
    const nextUrl = `index?step=2`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      props.saveAssessmentFields(props.id, nextUrl);
    }
  };

  const handleStep = (nextStep: number) => () => {
    const nextUrl = `assessment?step=${nextStep}`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      props.saveAssessmentFields(props.id, nextUrl);
    }
    window.scrollTo({ top: 0 });
  };

  const handleGoToImprovement = () => {
    props.saveAssessmentFields(
      props.id,
      `/reproductions/${props.id}/improvement`
    );
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          opacity: props.editAssessment.saving ? 1 : 0,
          transition: "opacity 300ms",
        }}
      >
        <LinearProgress />
      </div>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <ActionBar
        disableBack={isFirstStep()}
        disableNext={isLastStep() || !nextStepEnabled}
        handleBack={handleBack}
        handleUp={handleUp}
        handleNext={handleNext}
      />
      {!props.editAssessment.loading && (
        <div>
          {getStepContent(
            activeStep,
            readOnly,
            handleGoToImprovement,
            setNextStepEnabled
          )}
        </div>
      )}
      <ActionBar
        disableBack={isFirstStep()}
        disableNext={isLastStep() || !nextStepEnabled}
        handleBack={handleBack}
        handleUp={handleUp}
        handleNext={handleNext}
      />
      {!readOnly && (
        <Fab
          variant="extended"
          color="secondary"
          onClick={handleSave}
          className={[classes.button, classes.floatingSaveButton].join(" ")}
        >
          <Save />
          Save
        </Fab>
      )}
    </div>
  );
};

const mapStateToProps = ({
  editAssessment,
  user,
}: {
  editAssessment: EditAssessment;
  user: User;
}) => {
  return { editAssessment, user };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveAssessmentFields: (reproductionId: number, nextUrl: string) =>
      dispatch(saveAssessmentFields({ reproductionId, nextUrl })),
    fetchAssessment: (id: number, mode: string) =>
      dispatch(fetchAssessment({ id, mode })),
  };
};

function Index(props: {
  id: number;
  path: string;
  location: string;
  editAssessment: EditAssessment;
  fetchAssessment: (id: number, mode: string) => void;
}) {
  const isPublishedView = location.pathname.split("/").includes("published");
  useEffect(() => {
    props.fetchAssessment(props.id, isPublishedView && "published");
  }, [isPublishedView]);

  const queryParams = queryString.parse(props.location.search);
  const currentStep = parseInt(queryParams.step);
  return props.editAssessment.notFound ? (
    <NotFound />
  ) : (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" align="center">
          Assessment
        </Typography>
        <Typography>
          Describe the available reproduction materials and assign a
          reproducibility score to your selected display items and the overall
          paper. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/assessment.html">
            here
          </ExternalLink>
          .
        </Typography>
        <HorizontalNonLinearStepper {...props} currentStep={currentStep} />
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
