import Typography from "@material-ui/core/Typography";
import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Button } from "gatsby-material-ui-components";
import { Add, Delete } from "@material-ui/icons";
import {
  EditImprovement,
  Improvement,
  PaperLevelImprovement,
} from "../../../state/edit-improvement";
import { updateImprovementFields } from "../../../actions/improvement.actions";
import QuestionWithTextField from "../components/question-with-text-field";
import ExternalLink from "../../../components/external-link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otherField: {
      minWidth: 320,
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    displayItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paperLevelImprovement: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
  })
);

export function EditPaperLevelImprovement({
  paperLevelImprovement,
  section,
  updatePaperLevelImprovement,
  deletePaperLevelImprovement,
  readOnly,
}: {
  paperLevelImprovement: PaperLevelImprovement;
  section: string;
  updatePaperLevelImprovement: Function;
  deletePaperLevelImprovement: Function;
  readOnly: boolean;
}) {
  const classes = useStyles();

  const onChange = (name: string) => (e: any) => {
    updatePaperLevelImprovement(
      paperLevelImprovement.merge({ [name]: e.target.value })
    );
  };

  return (
    <Card className={classes.paperLevelImprovement}>
      <CardContent>
        <Typography>
          {section === "futurePaperLevelImprovements"
            ? "Which of the following reproducibility tools and practices are future improvements to the reproduction package?"
            : "Which of the following reproducibility tools and practices did you add or improve in your reproduction package?"}
        </Typography>
        {readOnly ? (
          <div className={classes.readOnly}>
            {((answer) => {
              switch (answer) {
                case "master_script":
                  return "Master script to execute all steps";
                case "file_organization":
                  return "Standard/clear file organization";
                case "readme":
                  return "A readme file";
                case "version_control":
                  return "Version control software (e.g., Git)";
                case "dynamic_document":
                  return "Dynamic document (e.g., RMarkdown, Jupyter Notebooks, etc.)";
                case "open_source_software":
                  return "Open source software (e.g., R, Python, Julia, etc.)";
                case "computing_capsule":
                  return "Computing capsule (e.g., mybinder.org, codeocean.com., etc.)";
                case "data_availability":
                  return "Data Availability Statement";
                case "other":
                  return "Other";
              }
            })(paperLevelImprovement.improvementType)}
          </div>
        ) : (
          <FormControl>
            <Select
              id="paper-level-improvement-type"
              value={paperLevelImprovement.improvementType}
              onChange={onChange("improvementType")}
            >
              <MenuItem value={"master_script"}>
                Master script to execute all steps
              </MenuItem>
              <MenuItem value={"file_organization"}>
                Standard/clear file organization
              </MenuItem>
              <MenuItem value={"readme"}>A readme file</MenuItem>
              <MenuItem value={"version_control"}>
                Version control software (e.g., Git)
              </MenuItem>
              <MenuItem value={"dynamic_document"}>
                Dynamic document (e.g., RMarkdown, Jupyter Notebooks, etc.)
              </MenuItem>
              <MenuItem value={"open_source_software"}>
                Open source software (e.g., R, Python, Julia, etc.)
              </MenuItem>
              <MenuItem value={"computing_capsule"}>
                Computing capsule (e.g., mybinder.org, codeocean.com., etc.)
              </MenuItem>
              <MenuItem value={"data_availability"}>
                Data availability statement
              </MenuItem>
              <MenuItem value={"other"}>Other.</MenuItem>
            </Select>
          </FormControl>
        )}
        <Box mb={4} />
        <QuestionWithTextField
          classes={classes}
          readOnly={readOnly}
          multiline
          variant="outlined"
          fullWidth
          placeholder={
            section === "futurePaperLevelImprovements"
              ? "e.g. I would create a master script to run every script"
              : "e.g. I created a master script named run.sh to run every script"
          }
          value={paperLevelImprovement.description}
          onChange={onChange("description")}
          noQuestionNumber={true}
        >
          {section === "futurePaperLevelImprovements"
            ? "Describe in detail what you would do"
            : "Describe in detail what you did"}
        </QuestionWithTextField>
        {!readOnly && (
          <Button
            startIcon={<Delete />}
            color="secondary"
            variant="contained"
            onClick={deletePaperLevelImprovement}
            size="small"
          >
            Delete
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

function PaperLevelImprovements({
  improvement,
  updateImprovementFields,
  handleGoToRobustness,
  readOnly,
}: {
  improvement: Improvement;
  updateImprovementFields: Function;
  handleGoToRobustness: Function;
  readOnly: boolean;
}) {
  const classes = useStyles();

  const addPaperLevelImprovement = (section: string) => () => {
    updateImprovementFields({
      [section]: improvement[section].push(new PaperLevelImprovement()),
    });
  };

  const updatePaperLevelImprovement = (index: number, section: string) => (
    value: any
  ) => {
    updateImprovementFields({
      [section]: improvement[section].set(index, value),
    });
  };

  const deletePaperLevelImprovement = (
    index: number,
    section: string
  ) => () => {
    updateImprovementFields({
      [section]: improvement[section].delete(index),
    });
  };

  const editPaperLevelImprovement = (section: string) => (
    paperLevelImprovement: PaperLevelImprovement,
    index: number
  ) => (
    <EditPaperLevelImprovement
      readOnly={readOnly}
      key={index}
      section={section}
      paperLevelImprovement={paperLevelImprovement}
      updatePaperLevelImprovement={updatePaperLevelImprovement(index, section)}
      deletePaperLevelImprovement={deletePaperLevelImprovement(index, section)}
    />
  );

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Paper level improvements
      </Typography>
      <Box mb={4}>
        <Typography variant="body1" component="div" gutterBottom>
          There are several kinds of improvements that you can make to improve
          the computational reproducibility of the overall paper, including:
          <ul>
            <li>
              Compile the reproduction package using version control software,
              such as Git.
            </li>
            <li>Improve the documentation by adding comments to the code.</li>
            <li>
              Combine the documentation with the code by adapting the paper into
              a literate programming environment (e.g., in Jupyter notebooks,
              RMarkdown, or a Stata Dynamic Doc).
            </li>
            <li>
              Re-write the code in a different statistical software, especially
              open-source software (e.g., R, Python, or Julia).
            </li>
            <li>
              Re-organize the reproduction package following a{" "}
              <ExternalLink href="https://www.projecttier.org/tier-protocol/specifications-3-0/">
                specific convention for folder organization.
              </ExternalLink>
            </li>
            <li>
              Write a master script that executes all the code in order, with no
              further modifications (e.g. using the{" "}
              <ExternalLink href="https://github.com/AEADataEditor/replication-template">
                AEA's reproduction template
              </ExternalLink>
              ).
            </li>
            <li>
              Set up a computing capsule that executes the entire reproduction
              in a computing capsule (e.g. using{" "}
              <ExternalLink href="https://mybinder.org/">Binder</ExternalLink>,{" "}
              <ExternalLink href="https://codeocean.com/">
                Code Ocean
              </ExternalLink>
              , or Docker)
            </li>
          </ul>
          Please record any paper-level improvements you did here.
        </Typography>
      </Box>
      <Box mb={4}>
        <div className={classes.section}>
          {improvement.currentPaperLevelImprovements.isEmpty() ? (
            <Typography
              className={classes.noPackages}
              variant="body1"
              gutterBottom
            >
              You currently have no paper level improvements specified. Add one
              by clicking the <strong>Add paper level improvement</strong>{" "}
              button.
            </Typography>
          ) : (
            <div>
              {improvement.currentPaperLevelImprovements.map(
                editPaperLevelImprovement("currentPaperLevelImprovements")
              )}
            </div>
          )}
        </div>
        {!readOnly && (
          <Button
            startIcon={<Add />}
            variant={"outlined"}
            color={"primary"}
            onClick={addPaperLevelImprovement("currentPaperLevelImprovements")}
          >
            Add a paper-level improvement
          </Button>
        )}
      </Box>
      <Typography variant="h4" gutterBottom>
        Future paper level improvements
      </Typography>
      <div className={classes.section}>
        {improvement.futurePaperLevelImprovements.isEmpty() ? (
          <Typography
            className={classes.noPackages}
            variant="body1"
            gutterBottom
          >
            You currently have no future paper level improvements specified. Add
            one by clicking the{" "}
            <strong>Add a future paper-level improvement</strong> button.
          </Typography>
        ) : (
          <div>
            {improvement.futurePaperLevelImprovements.map(
              editPaperLevelImprovement("futurePaperLevelImprovements")
            )}
          </div>
        )}
      </div>
      {!readOnly && (
        <Button
          startIcon={<Add />}
          variant={"outlined"}
          color={"primary"}
          onClick={addPaperLevelImprovement("futurePaperLevelImprovements")}
        >
          Add a future paper-level improvement
        </Button>
      )}
      {!readOnly && (
        <Box mt={4}>
          <Button
            onClick={handleGoToRobustness}
            variant="contained"
            color="primary"
            size="small"
          >
            Save and move to robustness stage
          </Button>
        </Box>
      )}
    </>
  );
}

const mapStateToProps = ({
  editImprovement: { improvement },
}: {
  editImprovement: EditImprovement;
}) => {
  return { improvement };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateImprovementFields: (fields: any) =>
      dispatch(updateImprovementFields(fields)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaperLevelImprovements);
