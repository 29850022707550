import ExternalLink from "../../../../components/external-link";
import React from "react";

const ReproducibilityLevels = (adjustedLevels: boolean) => {
  const adjustLevels = (level) => {
    return adjustedLevels ? `Adjusted Level ${level}*` : `Level ${level}`;
  };
  return [
    {
      value: "1",
      label: (
        <>
          <strong>{adjustLevels(1)}:</strong> No data or code are available.
        </>
      ),
    },
    {
      value: "2",
      label: (
        <>
          <strong>{adjustLevels(2)}:</strong> Code scripts are available
          (partial or complete), but no data are available.
        </>
      ),
    },
    {
      value: "3",
      label: (
        <>
          <strong>{adjustLevels(3)}:</strong>{" "}
          {adjustedLevels ? (
            <>
              All analysis code is provided, but only partial instructions to
              access the <em>analysis data</em> are available. This means that
              the original authors have provided some, but not all, of the
              following information:
              <ol type="a">
                <li>
                  <em>Contact information</em>, including name of the
                  organization(s) that provides access to at least one
                  individual's data and contact information.
                </li>
                <li>
                  <em>Terms of use</em>, including licenses and eligibility
                  criteria for accessing the data, if any.
                </li>
                <li>
                  <em>Information on data files (meta-data)</em>, including the
                  name(s) and number of files, file size(s), relevant file
                  version(s), and number of variables and observations in each
                  file. Though not required, other relevant information may be
                  included, including a dataset dictionary, summary statistics,
                  and synthetic data (fake data with the same statistical
                  properties as the original data).
                </li>
                <li>
                  <em>Estimated costs for access</em>, including monetary costs
                  such as fees and licences required to access the data, and
                  non-monetary costs such as wait times and specific
                  geographical locations from where researchers need to access
                  it.
                </li>
              </ol>
            </>
          ) : (
            <>
              Analytic data and code are partially available, but raw data and
              cleaning code are missing.
            </>
          )}
        </>
      ),
    },
    {
      value: "4",
      label: (
        <>
          <strong>{adjustLevels(4)}:</strong>{" "}
          {adjustedLevels ? (
            <>
              All analysis code is provided, and complete and detailed
              instructions on how to access the <em>analysis data</em> are
              available.
            </>
          ) : (
            <>
              Analytic data sets and analysis code are available, but the code
              fails to run or produces results inconsistent with the paper (not
              CRA).
            </>
          )}
        </>
      ),
    },
    {
      value: "5",
      label: (
        <>
          <strong>{adjustLevels(5)}:</strong>{" "}
          {adjustedLevels ? (
            <>
              All requirements for Level 4* are met, and the authors provide a
              certification that a third party was able to reproduce the display
              item (or the paper as a whole) from the analysis data (CRA). Such
              certification may include a signed letter by a disinterested
              reproducer or a certificate from a certification agency for data
              and code (e.g., see{" "}
              <ExternalLink href="https://www.cascad.tech/">
                CASCaD
              </ExternalLink>
              ).
            </>
          ) : (
            <>
              Analytic data sets and analysis code are available and they
              produce the same results as presented in the paper (CRA).
            </>
          )}
        </>
      ),
    },
    {
      value: "6",
      label: (
        <>
          <strong>{adjustLevels(6)}:</strong> Cleaning code scripts are
          available (partial) or complete), but raw data is missing.
        </>
      ),
    },
    {
      value: "7",
      label: (
        <>
          <strong>{adjustLevels(7)}:</strong>{" "}
          {adjustedLevels ? (
            <>
              All requirements for Level 6* are met, but instructions for
              accessing the <em>raw data</em> are incomplete. Use the
              instructions described in Level 3 above to assess the
              instructions' completeness.
            </>
          ) : (
            <>
              Cleaning code is available and complete, and raw data is partially
              available.
            </>
          )}
        </>
      ),
    },
    {
      value: "8",
      label: (
        <>
          <strong>{adjustLevels(8)}:</strong>{" "}
          {adjustedLevels ? (
            <>
              All requirements for Level 7* are met, and instructions for
              accessing the <em>raw data</em> are complete.
            </>
          ) : (
            <>
              All the materials (raw data, analytic data, cleaning code, and
              analysis code) are available. However, the cleaning code fails to
              run or produces different results from those presented in the
              paper (not CRR) or the analysis code fails to run or produces
              results inconsistent with the paper (not CRA).
            </>
          )}
        </>
      ),
    },
    {
      value: "9",
      label: (
        <>
          <strong>{adjustLevels(9)}:</strong>{" "}
          {adjustedLevels ? (
            <>
              All requirements for Level 8* are met, and a certification is
              provided that the display item can be reproduced from the analysis
              data (CRA).
            </>
          ) : (
            <>
              All the materials (raw data, analytic data, cleaning code, and
              analysis code) are available. The analysis code produces the same
              output as presented in the paper (CRA). However, the cleaning code
              fails to run or produces different results from those presented in
              the paper (not CRR).
            </>
          )}
        </>
      ),
    },
    {
      value: "10",
      label: (
        <>
          <strong>{adjustLevels(10)}:</strong>{" "}
          {adjustedLevels ? (
            <>
              All requirements for Level 9* are met, and a certification is
              provided that the display item can be reproduced from the raw data
              (CRR).
            </>
          ) : (
            <>
              All necessary materials are available and produce consistent
              results with those presented in the paper. The reproduction
              involves minimal effort and can be conducted starting from the
              analytic data (yes CRA) or the raw data (yes CRR).{" "}
              <em>
                Note that Level 10 is aspirational and may be unattainable for
                most research published today.
              </em>
            </>
          )}
        </>
      ),
    },
  ];
};

export default ReproducibilityLevels;
