import Typography from "@material-ui/core/Typography";
import React from "react";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format } from "date-fns";

function QuestionWithDatePicker(props: {
  classes: ClassNameMap;
  readOnly: boolean;
  children: React.ReactNode;
  datePickerId: string;
  value: null;
  onChange: (e: any) => void;
  KeyboardButtonProps?: any;
  disabled?: boolean;
}) {
  return props.readOnly ? (
    <>
      <Typography
        variant="body1"
        component="div"
        className={props.classes.question}
        gutterBottom
      >
        {props.children}
      </Typography>
      <Typography
        className={props.classes.readOnly}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {props.value
          ? format(new Date(props.value), "MM/dd/yyyy")
          : "No date specified"}
      </Typography>
    </>
  ) : (
    <>
      <Typography
        variant="body1"
        component="div"
        className={(props.disabled
          ? [props.classes.typographyDisabled, props.classes.question]
          : [props.classes.question]
        ).join(" ")}
        gutterBottom
      >
        {props.children}
      </Typography>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        placeholder="MM/DD/YYYY"
        margin="normal"
        id={props.datePickerId}
        value={props.value}
        onChange={props.onChange}
        KeyboardButtonProps={props.KeyboardButtonProps}
      />
    </>
  );
}

export default QuestionWithDatePicker;
