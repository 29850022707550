import Typography from "@material-ui/core/Typography";
import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import { Assessment, EditAssessment } from "../../../state/edit-assessment";
import { updateAssessmentFields } from "../../../actions/assessment.actions";
import { Box } from "@material-ui/core";
import MultipleSelect from "../components/multiple-select";
import TextField from "@material-ui/core/TextField";
import { Button } from "gatsby-material-ui-components";
import { QuestionWithTrueFalseRadio } from "../components/question-with-true-false-radio";
import ExternalLink from "../../../components/external-link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otherField: {
      flexGrow: 1,
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    displayItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
    correctionsTable: {
      borderCollapse: "collapse",
      "& td": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
    },
    ...questionStyles(6, theme),
  })
);

function PaperLevelReproducibility({
  assessment,
  readOnly,
  updateAssessmentFields,
  handleGoToImprovement,
}: {
  assessment: Assessment;
  readOnly: boolean;
  updateAssessmentFields: Function;
  handleGoToImprovement: Function;
}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Paper level components
      </Typography>
      <div className={classes.section}>
        <Typography variant="body1" component="div" gutterBottom>
          <p>
            There are many tools and practices that facilitate the overall
            computational reproducibility of a paper, such as using version
            control software, literal programming, open source software, among
            others. In this section, you will detect whether the reproduction
            materials contain any of these tools and practices. See more in the{" "}
            <ExternalLink href="https://bitss.github.io/ACRE/assessment.html#reproducibility-dimensions-at-the-paper-level">
              ACRe Guide
            </ExternalLink>
            .
          </p>
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.question}
          gutterBottom
        >
          In addition to the master script, mark which of the following
          reproducibility tools and practices were used in the original
          reproduction package.
        </Typography>

        {readOnly ? (
          assessment.reproductionPackageTools.map((reason) => (
            <div className={classes.readOnly} key={reason}>
              {reason}
              {reason === "Other. Describe." && (
                <p>{assessment.reproductionPackageToolsOther}</p>
              )}
            </div>
          ))
        ) : (
          <MultipleSelect
            classes={{
              label: classes.otherField,
            }}
            labels={[
              "A readme file",
              "Standard/clear file organization",
              "Version control software (e.g., Git)",
              "Open source software (e.g., R, Python, Julia, etc.)",
              "Dynamic documentation (e.g., using RMarkdown, Jupyter Notebooks, etc.)",
              "Computing capsule (e.g., mybinder.org, codeocean.com., etc.)",
              <div>
                Other. Describe.
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  disabled={
                    !assessment.reproductionPackageTools.contains(
                      "Other. Describe."
                    )
                  }
                  value={assessment.reproductionPackageToolsOther}
                  onChange={(e: any) => {
                    updateAssessmentFields({
                      reproductionPackageToolsOther: e.target.value,
                    });
                  }}
                />
              </div>,
            ]}
            values={[
              "A readme file",
              "Standard/clear file organization",
              "Version control software (e.g., Git)",
              "Open source software (e.g., R, Python, Julia, etc.)",
              "Dynamic documentation (e.g., using RMarkdown, Jupyter Notebooks, etc.)",
              "Computing capsule (e.g., mybinder.org, codeocean.com., etc.)",
              "Other. Describe.",
            ]}
            selectedValues={assessment.reproductionPackageTools}
            updateValues={(v: any) => {
              updateAssessmentFields({
                reproductionPackageTools: v,
              });
            }}
          />
        )}
      </div>
      {!readOnly && (
        <Box mt={4}>
          <Button
            onClick={handleGoToImprovement}
            variant="contained"
            color="primary"
            size="small"
          >
            Save and move to improvements stage
          </Button>
        </Box>
      )}
    </>
  );
}

const mapStateToProps = ({
  editAssessment: { assessment },
}: {
  editAssessment: EditAssessment;
}) => {
  return { assessment };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAssessmentFields: (fields: any) =>
      dispatch(updateAssessmentFields(fields)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaperLevelReproducibility);
