import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Container, Fab, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  updateFields,
  saveFields,
  fetchReproduction,
} from "../../../actions/edit-reproductions.actions";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { EditReproduction } from "../../../state/edit-reproduction";
import AddBasicInformation from "./add-basic-information";
import { Save } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { navigate } from "@reach/router";
import ExternalLink from "../../../components/external-link";
import NotFound from "../../../pages/404";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightGroup: {
      marginLeft: "auto",
      display: "inline-flex",
    },
    actionBar: {
      display: "flex",
      padding: 8,
      alignItems: "center",
    },
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    floatingSaveButton: {
      position: "-webkit-sticky",
      position: "sticky",
      left: "90%",
      bottom: "40px",
    },
  })
);

function ActionBar({ handleUp }: { handleUp: Function }) {
  const classes = useStyles();
  return (
    <div className={classes.actionBar}>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUp}
          className={classes.button}
        >
          Return to Stages Overview
        </Button>
      </div>
    </div>
  );
}

const SelectPaperContainer: React.SFC<any> = (props) => {
  const classes = useStyles();

  const isAuthor = props.editReproduction.reproduction.isAuthor === "true";

  const viewingShareableLink =
    props.editReproduction.reproduction.shareableLink;

  const readOnly =
    props.editReproduction.reproduction.paperType === "abandoned candidate" ||
    ["submitted", "assessment", "scoping"].includes(
      props.editReproduction.reproduction.workflowStage
    ) ||
    !isAuthor ||
    viewingShareableLink;

  useEffect(() => {
    if (!readOnly) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [readOnly]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleSave = () => {
    props.saveFields();
  };

  const handleUp = () => {
    const nextUrl = `index?step=0`;
    if (!readOnly) {
      props.saveFields(nextUrl);
    } else {
      navigate(nextUrl);
    }
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          opacity: props.editReproduction.saving ? 1 : 0,
          transition: "opacity 300ms",
        }}
      >
        <LinearProgress />
      </div>
      <ActionBar handleUp={handleUp} />
      {!props.editReproduction.loading && (
        <AddBasicInformation readOnly={readOnly} />
      )}
      <ActionBar handleUp={handleUp} />
      {!readOnly && (
        <Fab
          variant="extended"
          color="secondary"
          onClick={handleSave}
          className={[classes.button, classes.floatingSaveButton].join(" ")}
        >
          <Save />
          Save
        </Fab>
      )}
    </div>
  );
};

const mapStateToProps = ({
  editReproduction,
  user,
}: {
  editReproduction: EditReproduction;
  user: User;
}) => {
  return { editReproduction, user };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveFields: (nextUrl: string, workflowStage: string) =>
      dispatch(saveFields({ nextUrl, workflowStage })),
    fetchReproduction: (id: number, mode: string) =>
      dispatch(fetchReproduction({ id, mode })),
    updateFields: (fields: any) => dispatch(updateFields(fields)),
  };
};

function Index(props: {
  path: string;
  editReproduction: EditReproduction;
  fetchReproduction: (id: number, mode: string) => void;
}) {
  const isPublishedView = location.pathname.split("/").includes("published");
  useEffect(() => {
    props.fetchReproduction(props.id, isPublishedView && "published");
  }, [isPublishedView]);
  return props.editReproduction.notFound ? (
    <NotFound />
  ) : (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" align="center">
          Select a paper
        </Typography>
        <Typography>
          Declare the paper that you will analyze in the remainder of the
          exercise and record other "candidate" papers for which you were unable
          to obtain access to a reproduction package. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/scoping.html#declare">
            here
          </ExternalLink>
          .
        </Typography>
        <SelectPaperContainer {...props} />
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
