import { List } from "immutable";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import React from "react";

function MultipleSelectArray({
  name,
  labels,
  values,
  disabled,
  selectedValues,
  onChange,
  classes,
}: {
  name: string;
  labels: Array<any>;
  values: Array<string>;
  disabled: boolean;
  selectedValues: Array<string>;
  onChange: Function;
  classes: any;
}) {
  return (
    <FormControl fullWidth disabled={disabled} component="fieldset">
      <FormGroup>
        {labels.map((label, i) => {
          const value = values[i];
          const isSelected = selectedValues.includes(value);
          return (
            <FormControlLabel
              key={i}
              value={value}
              classes={classes}
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={onChange}
                  name={name}
                />
              }
              label={label}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}

export default MultipleSelectArray;
