import { updateFields } from "../../../actions/edit-reproductions.actions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import React from "react";
import {
  EditReproduction,
  Outputs,
  Reproduction,
} from "../../../state/edit-reproduction";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Checkbox,
  FormGroup,
  Link,
} from "@material-ui/core";
import { questionStyles } from "../css";
import QuestionWithTextField from "../components/question-with-text-field";
import QuestionWithDatePicker from "../components/question-with-date-picker";
import QuestionWithRadioGroup from "../components/question-with-radio-group";
import { QuestionWithTrueFalseRadio } from "../components/question-with-true-false-radio";
import ExternalLink from "../../../components/external-link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otherField: {
      flexGrow: 1,
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    outputsTable: {
      borderCollapse: "collapse",
      "& td": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
    ...questionStyles(1, theme),
  })
);

function EditOutputs({
  readOnly,
  outputs,
  updateOutput,
}: {
  readOnly: boolean;
  outputs: Outputs;
  updateOutput: Function;
}) {
  const classes = useStyles();

  const onChange = (name: string) => (e: any) => {
    updateOutput({ [name]: e.target.value });
  };

  return readOnly ? (
    <div>
      <Typography
        variant="body1"
        component="div"
        className={classes.question}
        gutterBottom
      >
        How many display items (tables, figures and inline results) are included
        in the paper's main body?
      </Typography>
      <table className={[classes.outputsTable, classes.readOnly].join(" ")}>
        <tbody>
          <tr>
            <td># of tables in main body</td>
            <td>{outputs.numTablesBody}</td>
          </tr>
          <tr>
            <td># of figures in main body</td>
            <td>{outputs.numFiguresBody}</td>
          </tr>
          <tr>
            <td># of inline results in main body</td>
            <td>{outputs.numInlineResultsBody}</td>
            <td>
              {outputs.attemptAllInlineResultsBody === "true"
                ? "attempted to count all"
                : "did not attempt to count all"}
            </td>
          </tr>
        </tbody>
      </table>
      <Typography
        variant="body1"
        component="div"
        className={classes.question}
        gutterBottom
      >
        How many display items (tables and figures) are included in the paper's
        appendix?
      </Typography>
      <table className={[classes.outputsTable, classes.readOnly].join(" ")}>
        <tbody>
          <tr>
            <td># of tables in appendix</td>
            <td>{outputs.numTablesAppendix}</td>
            <td>
              {outputs.attemptAllTablesAppendix === "true"
                ? "attempted to count all"
                : "did not attempt to count all"}
            </td>
          </tr>
          <tr>
            <td># of figures in appendix</td>
            <td>{outputs.numFiguresAppendix}</td>
            <td>
              {outputs.attemptAllFiguresAppendix === "true"
                ? "attempted to count all"
                : "did not attempt to count all"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div>
      <Typography
        variant="body1"
        component="div"
        className={classes.question}
        gutterBottom
      >
        How many display items (tables, figures and inline results) are included
        in the paper's main body?
      </Typography>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.formField}
        type="number"
        fullWidth
        label="# of tables included in the main body"
        value={outputs.numTablesBody}
        onChange={onChange("numTablesBody")}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        type="number"
        className={classes.formField}
        fullWidth
        label="# of figures included in the main body"
        value={outputs.numFiguresBody}
        onChange={onChange("numFiguresBody")}
      />
      <FormGroup row>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={"flex-start"}
          flexGrow={1}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            className={classes.formField}
            fullWidth
            label="# of inline results are included in the main body "
            value={outputs.numInlineResultsBody}
            onChange={onChange("numInlineResultsBody")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={outputs.attemptAllInlineResultsBody === "true"}
                onChange={(e: any) => {
                  if (e.target.checked) {
                    updateOutput({ attemptAllInlineResultsBody: "true" });
                  } else {
                    updateOutput({ attemptAllInlineResultsBody: "false" });
                  }
                }}
                color="primary"
              />
            }
            label="I attempted to count all."
          />
        </Box>
      </FormGroup>
      <Typography
        variant="body1"
        component="div"
        className={classes.question}
        gutterBottom
      >
        How many display items (tables and figures) are included in the paper's
        appendix?
      </Typography>
      <FormGroup row>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={"flex-start"}
          flexGrow={1}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            className={classes.formField}
            fullWidth
            label="# of tables included in appendix"
            value={outputs.numTablesAppendix}
            onChange={onChange("numTablesAppendix")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={outputs.attemptAllTablesAppendix === "true"}
                onChange={(e: any) => {
                  if (e.target.checked) {
                    updateOutput({ attemptAllTablesAppendix: "true" });
                  } else {
                    updateOutput({ attemptAllTablesAppendix: "false" });
                  }
                }}
                color="primary"
              />
            }
            label="I attempted to count all."
          />
        </Box>
      </FormGroup>
      <FormGroup row>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={"flex-start"}
          flexGrow={1}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            className={classes.formField}
            fullWidth
            label="# of figures included in appendix"
            value={outputs.numFiguresAppendix}
            onChange={onChange("numFiguresAppendix")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={outputs.attemptAllFiguresAppendix === "true"}
                onChange={(e: any) => {
                  if (e.target.checked) {
                    updateOutput({ attemptAllFiguresAppendix: "true" });
                  } else {
                    updateOutput({ attemptAllFiguresAppendix: "false" });
                  }
                }}
                color="primary"
              />
            }
            label="I attempted to count all."
          />
        </Box>
      </FormGroup>
    </div>
  );
}

function SummarizePaper({
  reproduction,
  updateFields,
  readOnly,
}: {
  reproduction: Reproduction;
  updateFields: Function;
  readOnly: boolean;
}) {
  const handleDateChange = (name: string) => (value: any) => {
    updateFields({ [name]: value });
  };

  const updateOutputs = (values: any) => {
    updateFields({ outputs: reproduction.outputs.merge(values) });
  };
  const onChange = (name: string) => (e: any) => {
    updateFields({ [name]: e.target.value });
  };

  const classes = useStyles();

  let willAssessWholePaper = reproduction.willAssessWholePaper !== "false";

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Summarize paper
      </Typography>
      <div className={classes.section}>
        <QuestionWithTextField
          classes={classes}
          value={reproduction.projectNickname}
          placeholder="e.g. Railroads of the Raj Attempt #1 - Jan 2021"
          onChange={(e: any) => {
            updateFields({ projectNickname: e.target.value });
          }}
          readOnly={readOnly}
          fullWidth
        >
          Please give your reproduction attempt a nickname that you can use for
          identification on the "My Work" page and the{" "}
          <Link href="/reproductions/search">database of reproductions</Link>.
          We recommend using the convention of "Reproduction of '[Paper Title]'"
          or "Reproduction of [Author LastName, (YYYY)]."
        </QuestionWithTextField>
        <QuestionWithDatePicker
          classes={classes}
          readOnly={readOnly}
          datePickerId="date-picker-startDate"
          value={reproduction.startDate}
          onChange={handleDateChange("startDate")}
          KeyboardButtonProps={{
            "aria-label": "start date",
          }}
        >
          When did you <strong>start</strong> this exercise? (The exercise
          started when you began reviewing candidate papers in stage 0.)
        </QuestionWithDatePicker>
        <QuestionWithDatePicker
          classes={classes}
          readOnly={readOnly}
          datePickerId="date-picker-endDate"
          value={reproduction.endDate}
          onChange={handleDateChange("endDate")}
          KeyboardButtonProps={{
            "aria-label": "end date",
          }}
        >
          When do you expect to <strong>complete</strong> this exercise? This
          includes your work on all stages of the activity, including Scoping,
          Assessment, Improvements, and (optionally) Robustness.
        </QuestionWithDatePicker>
        <QuestionWithTextField
          classes={classes}
          type="number"
          value={reproduction.expectedTotalHours}
          onChange={(e: any) => {
            updateFields({ expectedTotalHours: e.target.value });
          }}
          readOnly={readOnly}
        >
          How many hours <strong>in total</strong> do you expect to work on this
          project from start to finish?
        </QuestionWithTextField>
        <QuestionWithRadioGroup
          classes={classes}
          readOnly={readOnly}
          value={reproduction.familiarityLevel}
          onChange={(e: any) => {
            updateFields({ familiarityLevel: e.target.value });
          }}
          valuesAndLabels={[
            {
              value: "1",
              label:
                "I have never read the paper, nor attempted to reproduce any of the analyses.",
            },
            {
              value: "2",
              label:
                "I have read the paper, but have not attempted to reproduce any of the analyses.",
            },
            {
              value: "3",
              label:
                "I have read the paper and have attempted to reproduce some of the analyses.",
            },
          ]}
        >
          How would you rate your familiarity with this paper?
        </QuestionWithRadioGroup>
        <EditOutputs
          readOnly={readOnly}
          outputs={reproduction.outputs}
          updateOutput={updateOutputs}
        />
        <QuestionWithTextField
          classes={classes}
          value={reproduction.wholePopulation}
          placeholder="e.g. low income households in the US that are below the federal poverty line"
          onChange={(e: any) => {
            updateFields({ wholePopulation: e.target.value });
          }}
          readOnly={readOnly}
          multiline
          fullWidth
          variant="outlined"
        >
          Describe the main population that is the focus of the paper as a
          whole. The population is usually described in the paper's introduction
          or motivation.
        </QuestionWithTextField>
        <QuestionWithTextField
          classes={classes}
          readOnly={readOnly}
          multiline
          fullWidth
          variant="outlined"
          value={reproduction.additionalPopulation}
          placeholder="e.g. low income households around the world"
          onChange={(e: any) => {
            updateFields({ additionalPopulation: e.target.value });
          }}
        >
          To which other populations do you think that the results of the paper
          can be applied (generalized)?
        </QuestionWithTextField>
        <QuestionWithTextField
          classes={classes}
          readOnly={readOnly}
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          value={reproduction.numClaims}
          onChange={(e: any) => {
            let newValue;
            if (e.target.value === "") {
              newValue = "";
            } else if (e.target.value < 1) {
              newValue = 1;
            } else {
              newValue = e.target.value;
            }
            if (willAssessWholePaper) {
              updateFields({ numClaimsWillAssess: newValue });
            }
            updateFields({ numClaims: newValue });
          }}
        >
          According to your reading of the paper, how many scientific claims
          (descriptive or causal) are investigated in the paper?
          <p>
            <small>
              A research claim is a single major finding from a published study
              (for example, a journal article), as well as details of the
              methods and results that support this finding. A research claim is
              not equivalent to an entire article and sometimes the claim as
              described in the abstract does not exactly match the claim that is
              tested. Identifying them may require some subjective judgment on
              your part. For reference, scientific claims are typically
              structured as the following: "This paper estimates the effect of X
              on Y for population P, using method F" or "This paper estimates
              the value of Y (estimated or predicted) for population P under
              condition X using method M."
            </small>
          </p>
          <p>
            ⚠️{" "}
            <small>
              This number <strong>(minimum value of 1)</strong> may affect the
              number of claims in step 3 - Outline Claims. If you change this
              value after editing that section, you risk data loss. Exercise
              caution
            </small>{" "}
            ⚠️
          </p>
        </QuestionWithTextField>
        <Typography
          variant="body1"
          component="div"
          className={classes.question}
          gutterBottom
        >
          Would you classify the paper's scientific claims as mainly focused on
          estimating a causal relationship, estimating/predicting a descriptive
          statistic of a population, or something else?
        </Typography>
        {readOnly ? (
          <div className={classes.readOnly}>
            {((claimType) => {
              switch (claimType) {
                case "causal":
                  return "Causal claim";
                case "descriptive":
                  return "Descriptive/predictive claim";
                default:
                  return reproduction.claimTypeOtherDescription;
              }
            })(reproduction.claimType)}
          </div>
        ) : (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Would you classify the paper's scientific claims as mainly focused on estimating a causal relationship, estimating/predicting a descriptive statistic of a population, or something else?"
              value={reproduction.claimType}
              onChange={onChange("claimType")}
            >
              <FormControlLabel
                value="causal"
                control={<Radio />}
                label={
                  <>
                    <p>
                      <strong>Causal claim</strong>
                    </p>
                    <p>
                      <small>
                        This paper estimates the effect of X on Y for population
                        P, using method M. Example: "This paper investigates the
                        impact of bicycle provision (X) on secondary school
                        enrollment (Y) among young women in Bihar/India (P),
                        using a Difference in Difference approach (M)."
                      </small>
                    </p>
                  </>
                }
              />
              <FormControlLabel
                value="descriptive"
                control={<Radio />}
                label={
                  <>
                    <p>
                      <strong>Descriptive/predictive claim</strong>
                    </p>
                    <p>
                      <small>
                        This paper estimates the value of Y (estimated or
                        predicted) for population P under conditions X
                        (optional) using method M. Example: "Drawing on a unique
                        Swiss data set (P) and exploiting systematic anomalies
                        in countries’ portfolio investment positions (M), I find
                        that around 8% of the global financial wealth of
                        households is held in tax havens (Y) "
                      </small>
                    </p>
                  </>
                }
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                classes={{
                  label: classes.otherField,
                }}
                label={
                  <TextField
                    multiline
                    fullWidth
                    label="Other"
                    variant="outlined"
                    disabled={reproduction.claimType !== "other"}
                    value={reproduction.claimTypeOtherDescription}
                    onChange={onChange("claimTypeOtherDescription")}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        )}
        <QuestionWithTrueFalseRadio
          classes={classes}
          value={reproduction.willAssessWholePaper}
          onChange={(e: any) => {
            updateFields({ willAssessWholePaper: e.target.value });
            if (e.target.value) {
              updateFields({ numClaimsWillAssess: reproduction.numClaims });
            }
          }}
          questionText="Do you plan to investigate the entire paper?"
          readOnly={readOnly}
        />
        {!willAssessWholePaper && (
          <QuestionWithTextField
            classes={classes}
            readOnly={readOnly}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            value={reproduction.numClaimsWillAssess}
            onChange={(e: any) => {
              let newValue;
              if (e.target.value === "") {
                newValue = "";
              } else if (e.target.value < 1) {
                newValue = 1;
              } else {
                newValue = e.target.value;
              }
              updateFields({ numClaimsWillAssess: newValue });
            }}
          >
            How many scientific claims (descriptive or causal) do you plan to
            assess?
            <p>
              ⚠️{" "}
              <small>
                This number <strong>(minimum value of 1)</strong> affects the
                number of claims in step 3 - Outline Claims. If you change this
                value after editing that section, you risk data loss. Exercise
                caution
              </small>{" "}
              ⚠️
            </p>
          </QuestionWithTextField>
        )}
        <QuestionWithTextField
          classes={classes}
          readOnly={readOnly}
          label="Summary"
          multiline
          fullWidth
          variant="outlined"
          rows={10}
          value={reproduction.summary}
          onChange={(e) => updateFields({ summary: e.target.value })}
        >
          <strong>Optional:</strong> We recommend writing a short summary of the
          paper since that can help you identify the key elements of the paper
          and allow you to demonstrate your understanding of the paper. In your
          summary, try to address the following:
          <ul>
            <li>
              Would you classify the paper’s scientific claims as mainly focused
              on estimating a causal relationship, estimating/predicting a
              descriptive statistic of a population, or something else?
            </li>
            <li>
              How many scientific claims (descriptive or causal) are
              investigated in the paper?
            </li>
            <li>What is the population for which the estimates apply?</li>
            <li>
              What is the population that is the focus of the paper as a whole?
            </li>
            <li>What are the main data sources used in the paper?</li>
            <li>
              How many display items are there in the paper (tables, figures,
              and inline results)?
            </li>
            <li>
              What is the main statistical or econometric method used to examine
              each claim?
            </li>
            <li>
              What is the author’s preferred specification (or yours, if the
              authors are not clear)?
            </li>
            <li>
              What are some robustness checks for the preferred specification?"
            </li>
          </ul>
          See more in the{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/scoping.html#read-sum">
            ACRe Guide
          </ExternalLink>
          .
          <p>
            <small>
              Please limit your write-up to 1,000 words or less in a plaintext
              format. We recommend you draft your summary in another editor and
              paste the contents here afterwards.
            </small>
          </p>
        </QuestionWithTextField>
      </div>
    </>
  );
}

const mapStateToProps = ({
  editReproduction: { reproduction },
}: {
  editReproduction: EditReproduction;
}) => {
  return { reproduction };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateFields: (fields: any) => dispatch(updateFields(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummarizePaper);
