import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import {
  Box,
  Container,
  Card,
  CardContent,
  CardActions,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Reproduction } from "../state/edit-reproduction";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { MyReproductions } from "../state/my-reproductions";
import {
  createReproduction,
  createMinimumReproduction,
  deleteReproduction,
  fetchMyReproductions,
} from "../actions/my-reproductions.actions";
import { List } from "immutable";
import {
  Add,
  Delete,
  Edit,
  FileCopy,
  Info,
  Visibility,
} from "@material-ui/icons";
import { Button } from "gatsby-material-ui-components";
import Modal from "@material-ui/core/Modal";
import NotFound from "../pages/404";
import axios from "axios";
import { navigate } from "@reach/router";
import { User } from "../state/user";
import { camelizeKeys } from "humps";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reproduction: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    modal: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    },
  })
);

function ReproductionCard({ reproduction }: { reproduction: Reproduction }) {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        {reproduction.projectNickname ? (
          <span>
            <strong>{reproduction.projectNickname}</strong>
          </span>
        ) : (
          reproduction.paperType === "declared" && (
            <span>
              Please give your project a <strong>nickname</strong> in the{" "}
              <strong>Scoping</strong> step.
            </span>
          )
        )}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {reproduction?.paper?.title ? (
          <span>
            Title of paper: <strong>{reproduction.paper.title}</strong>
          </span>
        ) : (
          <span>
            Please enter a <strong>title</strong> in the{" "}
            <strong>Select a paper</strong> step.
          </span>
        )}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Created on: {reproduction.createdAt}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Paper status: <strong>{reproduction.paperType}</strong>
      </Typography>
      {reproduction.paperType !== "abandoned candidate" && (
        <Typography variant="body1" gutterBottom>
          Current stage: <strong>{reproduction.currentStage}</strong>
        </Typography>
      )}
      {reproduction.paperType === "abandoned candidate" && (
        <Typography variant="body1" gutterBottom>
          Abandoned on: <strong>{reproduction.candidateAbandonedAt}</strong>
        </Typography>
      )}
      {reproduction.workflowStage === "submitted" && (
        <Typography variant="body1" gutterBottom>
          Submitted on: <strong>{reproduction.submittedAt}</strong>
        </Typography>
      )}
      {reproduction.reproductionType === "full" ? (
        <>
          <Typography variant="body1" gutterBottom>
            Number of display items assessed:{" "}
            <strong>{reproduction.numDisplayItemsAssessed}</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Number of claims assessed:{" "}
            <strong>{reproduction.numClaimsAssessed}</strong>
          </Typography>
        </>
      ) : (
        <></>
      )}
      <Typography variant="body1" gutterBottom>
        Reproduction type:{" "}
        <strong>
          {displayReproductionType(reproduction.reproductionType)}
        </strong>
      </Typography>
      {reproduction.reproductionType === "minimum" ? (
        <>
          <Typography variant="body1" gutterBottom>
            Paper level score:{" "}
            <strong>{reproduction.paperLevelReproducibilityScore}</strong>
          </Typography>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

function displayReproductionType(backendReproductionType) {
  if (backendReproductionType === "minimum") {
    return "minimal";
  } else {
    return backendReproductionType;
  }
}

function ViewReproduction({
  reproduction,
  deleteReproduction,
}: {
  reproduction: Reproduction;
  deleteReproduction: () => void;
}) {
  const classes = useStyles();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const stepParam = ((stage: string) => {
    switch (stage) {
      case "select_paper":
        return 0;
      case "scoping":
        return 1;
      case "assessment":
        return 2;
      default:
        return 0;
    }
  })(reproduction.workflowStage);

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const cloneReproduction = async () => {
    const response = await axios.post(
      `/api/reproductions/${reproduction.id}/clones`
    );
    const cloneId = response.data.id;
    const nextUrl = `/reproductions/${cloneId}/index?step=0`;
    navigate(nextUrl);
  };

  return (
    <Card className={classes.reproduction}>
      <CardContent>
        <ReproductionCard reproduction={reproduction} />
      </CardContent>
      <CardActions>
        {reproduction.workflowStage === "submitted" ||
        reproduction.paperType === "abandoned candidate" ? (
          <Button
            className={classes.button}
            startIcon={<Visibility />}
            color="primary"
            variant="contained"
            href={`/reproductions/${reproduction.id}/index?step=${stepParam}`}
            size="small"
          >
            View
          </Button>
        ) : (
          <>
            {reproduction.reproductionType === "full" ? (
              <Button
                className={classes.button}
                startIcon={<Edit />}
                color="primary"
                variant="contained"
                href={`/reproductions/${reproduction.id}/index?step=${stepParam}`}
                size="small"
              >
                Edit
              </Button>
            ) : (
              <Button
                className={classes.button}
                startIcon={<Edit />}
                color="primary"
                variant="contained"
                href={`/reproductions/${reproduction.id}/minimum-reproduction`}
                size="small"
              >
                Edit
              </Button>
            )}
            <Button
              className={classes.button}
              startIcon={<Delete />}
              color="secondary"
              onClick={handleOpenDeleteModal}
              size="small"
            >
              Delete
            </Button>
          </>
        )}
        <Box flexGrow="1" />

        {reproduction.reproductionType === "full" ? (
          <Box display="flex" alignItems="center">
            <Button
              className={classes.button}
              startIcon={<FileCopy />}
              color="secondary"
              onClick={cloneReproduction}
              size="small"
            >
              Copy (up to Scoping)
            </Button>
            <Tooltip
              title="Creates an editable copy of the reproduction that is pre-populated through the Assessment stage."
              placement="top"
              arrow
            >
              <Info fontSize="small" />
            </Tooltip>
          </Box>
        ) : (
          <></>
        )}
        <Modal
          open={deleteModalOpen}
          onClose={handleCloseDeleteModal}
          aria-labelledby="Delete reproduction"
        >
          <div className={classes.modal}>
            <p>
              <strong>This action cannot be undone.</strong> This will
              permanently delete your reproduction attempt.
            </p>
            <Box display="flex" flexDirection="row">
              <Box pr={2}>
                <Button
                  onClick={deleteReproduction}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Delete
                </Button>
              </Box>
              <Button
                onClick={handleCloseDeleteModal}
                variant="outlined"
                color="secondary"
                size="small"
              >
                Cancel
              </Button>
            </Box>
          </div>
        </Modal>
      </CardActions>
    </Card>
  );
}

function Home({
  path,
  user,
  reproductions,
  myReproductions,
  fetchMyReproductions,
  createReproduction,
  createMinimumReproduction,
  deleteReproduction,
  signOut,
}: {
  path: string;
  user: User;
  reproductions: List<Reproduction>;
  myReproductions: MyReproductions;
  fetchMyReproductions: () => void;
  createReproduction: () => void;
  createMinimumReproduction: () => void;
  deleteReproduction: Function;
  signOut: Function;
}) {
  const classes = useStyles();

  if (user.unconfirmed_orcid) {
    // Can't use navigate here because this route isn't part of the SPA
    window.location = "/users/orcid_email";
    return;
  }

  if (
    user.verified &&
    (user.given_name === null ||
      user.family_name === null ||
      user.gender === null ||
      user.ethnicities.length == 0)
  ) {
    // Can't use navigate here because this route isn't part of the SPA
    window.location = "/users/edit";
    return;
  }

  const [filteredWorkflowStage, setFilteredWorkflowStage] = useState(
    "all_reproductions"
  );

  const [collaboratorInvitations, setCollaboratorInvitations] = React.useState(
    []
  );

  const fetchCollaboratorInvitations = async () => {
    axios
      .get(`/api/my/collaborator_invitations.json`)
      .then(function (response) {
        setCollaboratorInvitations(camelizeKeys(response.data));
      });
  };

  useEffect(() => {
    fetchCollaboratorInvitations();
  }, []);

  const acceptCollaboratorInvitation = (reproduction_id) => async () => {
    await axios
      .post(
        `/api/reproductions/${reproduction_id}/collaborator_invitations/accept`
      )
      .then(async function (response) {
        fetchMyReproductions();
        await fetchCollaboratorInvitations();
      });
  };

  const declineCollaboratorInvitation = (reproduction_id) => async () => {
    await axios
      .post(
        `/api/reproductions/${reproduction_id}/collaborator_invitations/decline`
      )
      .then(async function (response) {
        fetchMyReproductions();
        await fetchCollaboratorInvitations();
      });
  };

  useEffect(() => {
    fetchMyReproductions();
  }, []);

  if (myReproductions.notFound) {
    window.location = "/sign_in";
    return <NotFound />;
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            My work
          </Typography>
          <Button
            className={classes.button}
            color="primary"
            onClick={signOut}
            size="small"
            variant={"contained"}
          >
            Sign out
          </Button>
        </Box>

        <Button
          className={classes.button}
          startIcon={<Add />}
          color="primary"
          onClick={createReproduction}
          size="small"
          variant="contained"
        >
          Start a full reproduction
        </Button>

        <Button
          className={classes.button}
          startIcon={<Add />}
          color="primary"
          onClick={createMinimumReproduction}
          size="small"
          variant="contained"
        >
          Start a minimum reproduction
        </Button>

        <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
          <Box mr={2}>
            <InputLabel id="select-reproduction-stage">
              Filter reproductions by stage:
            </InputLabel>
          </Box>
          <Select
            id="selected-reproduction-stage"
            value={filteredWorkflowStage}
            onChange={(e) => setFilteredWorkflowStage(e.target.value)}
          >
            <MenuItem value="all_reproductions">All reproductions</MenuItem>
            <MenuItem value="select_paper">Selecting a Paper</MenuItem>
            <MenuItem value="abandoned_candidate">Abandoned Paper</MenuItem>
            <MenuItem value="scoping">Scoping</MenuItem>
            <MenuItem value="assessment">
              Assessment, Improvements, Robustness
            </MenuItem>
            <MenuItem value="submitted">Submitted</MenuItem>
          </Select>
        </Box>
        {collaboratorInvitations.map((invitation) => (
          <Card
            className={classes.reproduction}
            key={invitation.reproduction.id}
          >
            <CardContent>
              <Typography variant="h4" gutterBottom>
                {invitation.invitedBy} has invited you to collaborate on the
                reproduction listed below. Would you like to accept the invite?
              </Typography>
              <Typography>
                Caution: only one user can edit the form at a time. If a
                collaborator edits and saves the form while you edit, all of
                your unsaved edits will be overwritten.
              </Typography>
              <Box display="flex" pb={4} pt={2}>
                <Button
                  onClick={acceptCollaboratorInvitation(
                    invitation.reproduction.id
                  )}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Yes
                </Button>
                <Box pl={2}>
                  <Button
                    onClick={declineCollaboratorInvitation(
                      invitation.reproduction.id
                    )}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    No
                  </Button>
                </Box>
              </Box>
              <ReproductionCard reproduction={invitation.reproduction} />
            </CardContent>
          </Card>
        ))}
        {reproductions
          .filter((reproduction) => {
            if (filteredWorkflowStage === "all_reproductions") {
              return true;
            } else {
              return reproduction.workflowStage === filteredWorkflowStage;
            }
          })
          .map((reproduction, i) => {
            return (
              <ViewReproduction
                key={reproduction.id}
                deleteReproduction={() => deleteReproduction(reproduction.id)}
                reproduction={reproduction}
              />
            );
          })}
      </Box>
    </Container>
  );
}

const mapStateToProps = ({
  myReproductions,
  user,
}: {
  myReproductions: MyReproductions;
  user: User;
}) => {
  return {
    myReproductions,
    reproductions: myReproductions.reproductions,
    user,
  };
};

const signOut = async () => {
  const res = await fetch("/users/sign_out.json", {
    method: "DELETE",
    credentials: "same-origin",
  });

  if (res.status === 204) {
    (window.location as any) = "/";
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchMyReproductions: () => dispatch(fetchMyReproductions()),
    createReproduction: () => dispatch(createReproduction()),
    deleteReproduction: (id: number) => dispatch(deleteReproduction({ id })),
    createMinimumReproduction: () => dispatch(createMinimumReproduction()),
    signOut,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
