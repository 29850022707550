import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      "& th": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
      "& td": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
    },
  })
);

function PreEstimateTable({ estimates }: { estimates: any }) {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="body1">
        Pre-specified estimates associated with this claim
      </Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th></th>
            <th>Estimate</th>
            <th>SD</th>
          </tr>
        </thead>
        <tbody>
          {estimates &&
            estimates.length > 0 &&
            estimates.map((data, index) => {
              return (
                <tr key={index}>
                  <td>
                    {data.display_item_name} - {data.estimate_spec_number}
                  </td>
                  <td>{data.estimate_value}</td>
                  <td>{data.standard_error}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Box>
  );
}
export default PreEstimateTable;
