import Typography from "@material-ui/core/Typography";
import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import {
  Assessment,
  CodeScriptRow,
  EditAssessment,
} from "../../../state/edit-assessment";
import { List } from "immutable";
import {
  deleteCodeScriptRow,
  updateAssessmentFields,
  updateCodeScriptRow,
} from "../../../actions/assessment.actions";
import { Button } from "gatsby-material-ui-components";
import { Add } from "@material-ui/icons";
import { DataGrid } from "../../../components/datagrid";
import { Box } from "@material-ui/core";
import CSVDownloadButton from "../components/csv-download-button";
import CSVUploadButton from "../components/csv-upload-button";
import { camelizeKeys, decamelize, decamelizeKeys } from "humps";
import SaveMessage from "../../save-message";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otherField: {
      minWidth: 320,
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    ...questionStyles(2, theme),
  })
);

function DescribeCode({
  assessment,
  readOnly,
  updateAssessmentFields,
  updateCodeScriptRow,
  deleteCodeScriptRow,
}: {
  assessment: Assessment;
  readOnly: boolean;
  updateAssessmentFields: Function;
  updateCodeScriptRow: Function;
  deleteCodeScriptRow: Function;
}) {
  const classes = useStyles();

  const codeScriptColumns = [
    { headerName: "File Name", field: "fileName", flex: 1 },
    { headerName: "Location", field: "location", flex: 1 },
    { headerName: "Inputs", field: "inputs", flex: 2 },
    { headerName: "Outputs", field: "outputs", flex: 2 },
    { headerName: "Description", field: "description", flex: 1 },
    { headerName: "Primary Type", field: "primaryType", flex: 1 },
  ];

  const codeScriptColumnsForCSV = codeScriptColumns.map((csc) =>
    decamelize(csc.field)
  );

  const addCodeScriptRow = () => {
    updateAssessmentFields(
      assessment.set(
        "codeScriptRows",
        assessment.codeScriptRows.push(new CodeScriptRow())
      )
    );
  };

  const uploadCodeScriptRows = (csvRows) => {
    let codeScriptRows = List(
      csvRows.map(({ data }) => new CodeScriptRow(camelizeKeys(data)))
    );
    updateAssessmentFields(assessment.set("codeScriptRows", codeScriptRows));
  };

  const handleOnError = (err) => {
    alert(
      `Error occurred while uploading CSV: ${err}.\nPlease download template and try again.`
    );
  };

  return (
    <>
      <SaveMessage />
      <Typography variant="body1" component="p" gutterBottom>
        In this section, you will first provide a detailed description of the
        reproduction package. You will then connect display items with their
        corresponding inputs, such as data and code files. With these elements
        in place, you can score each display item's reproducibility level and
        record various paper-level dimensions of reproducibility.
      </Typography>
      <Box mb={4}></Box>
      <div className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Code scripts
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.question}
          gutterBottom
        >
          <p>
            You will enter data about code in the reproduction package and their
            inputs and outputs. In combination with the{" "}
            <strong>diagram builder</strong> in the next section, this will help
            you judge the reproducibility as well as identify missing files
            which can help you when following up with authors.
          </p>
          <p>
            First, identify all <b>code files</b> in the reproduction package
            and record their names in the file_name column in the mapping tool.
          </p>

          <p>
            Second, record the <b>location</b> of each code file relative to the
            main folder of the reproduction package in the location column.
          </p>

          <p>
            Third, review the beginning and end of each code file to identify
            the <b>inputs</b> required to successfully run the file and the
            outputs produced. Examples of inputs are data sets or other code
            scripts that are typically found at the beginning of the script
            (e.g., <code>load</code>, <code>read</code>, <code>source</code>,{" "}
            <code>run</code>, <code>do</code> ). Examples of outputs are other
            data sets, or plain text files that are typically at the end of a
            script (e.g., <code>save</code>, <code>write</code>,{" "}
            <code>export</code>). Record this in the inputs and outputs columns.
          </p>

          <p>
            Fourth, provide a <b>brief description of the function</b> produced
            by the code file (e.g., "produces table 1") in the description
            column.
          </p>

          <p>
            Finally, <b>classify</b> each data file as analysis or cleaning
            and/or construction code based on its primary function.
          </p>

          <p>
            Sometimes the reproduction package will not produce display items as
            final outputs. In this situation, the final code script will
            generate some type of output (e.g., "results1.log", "results2.csv")
            that will require manual copying and pasting to reproduce a desired
            display item (e.g. "Table 1"). In this case, we recommend adding one
            auxiliary line to the spreadsheet linking the final output to the
            desired display item (e.g., File Name: aux1, Inputs: "results1.log;
            results2.csv", Outputs: "Table 1").
          </p>

          <p>
            Note: If you notice that some files iterate between each other (eg,
            file1.do calls data1.csv to generate data2.csv, and file2.R calls
            data2.csv to generate data1.csv) please look within the files to
            identify the one that contains some stopping criteria (eg, stop when
            SSR is minimized), and rename only in the tree one output as final
            (eg, if file2.R contains the stopping criteria rename, only in the
            tree and not it the actual script, its output to data1_final.csv)
          </p>
        </Typography>
        {!readOnly && (
          <>
            <Button
              startIcon={<Add />}
              variant={"contained"}
              color={"primary"}
              onClick={addCodeScriptRow}
            >
              Add code script
            </Button>
            <CSVUploadButton
              onDrop={uploadCodeScriptRows}
              onError={handleOnError}
            />
          </>
        )}
        <CSVDownloadButton
          data={decamelizeKeys(assessment.codeScriptRows.toJS())}
          filename="code_scripts"
          columns={codeScriptColumnsForCSV}
        />
        <DataGrid
          readOnly={readOnly}
          rows={assessment.codeScriptRows}
          columns={codeScriptColumns}
          updateRowAtIndex={updateCodeScriptRow}
          deleteRowAtIndex={deleteCodeScriptRow}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({
  editAssessment: { assessment },
}: {
  editAssessment: EditAssessment;
}) => {
  return { assessment };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAssessmentFields: (fields: any) =>
      dispatch(updateAssessmentFields(fields)),
    updateCodeScriptRow: (fields: any) => dispatch(updateCodeScriptRow(fields)),
    deleteCodeScriptRow: (index: any) => dispatch(deleteCodeScriptRow(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DescribeCode);
