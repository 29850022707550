import React, { useEffect } from "react";
import { Reproduction } from "../../state/edit-reproduction";
import { format } from "date-fns";
import axios from "axios";
import ExternalLink from "../../components/external-link";
import { Link, Typography, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4),
      marginTop: theme.spacing(4),
    },
    summaryPara: {
      color: `#EF8733`,
      marginTop: theme.spacing(2),
    },
    detailPara: {
      fontSize: 18,
    },
    hoverClick: {
      cursor: "pointer",
    },
  })
);

function Summary(props: { reproduction: Reproduction }) {
  const classes = useStyles();

  const [summaryData, setSummaryData] = React.useState({});
  const [showDetails, setShowDetails] = React.useState(false);

  useEffect(() => {
    axios
      .get(
        `/api/reproductions/${props.reproduction.id}/summary.json?mode=published`
      )
      .then(function (response) {
        setSummaryData(response.data);
      });
  }, [props.reproduction]);

  const createdAtDate = props.reproduction.createdAt
    ? format(new Date(props.reproduction.createdAt), "MMMM dd yyyy")
    : "";

  const submittedAtDate = props.reproduction.submittedAt
    ? format(new Date(props.reproduction.submittedAt), "MMMM dd yyyy")
    : "Not submitted yet";

  const candidateAbandonedAt = props.reproduction.candidateAbandonedAt
    ? format(
        new Date(props.reproduction.candidateAbandonedAt),
        "MMMM dd yyyy"
      ) +
      " - The reproducer was unable to locate a reproduction package and proceed with a reproduction."
    : false;

  const originalReproductionPackage = props.reproduction.originalReproductionPackages.get(
    0
  ).url;
  const revisedReproductionPackage = props.reproduction.revisedReproductionPackages.get(
    0
  ).url;

  const fullDoi = `https://doi.org/${props.reproduction.doi}`;

  // Calculate average score across all display items
  let totalScore = summaryData.display_items
    ? summaryData.display_items.reduce(
        (acc, display_item) => acc + Number(display_item.reproducibility_score),
        0
      )
    : 0;

  let averageScore = summaryData.num_display_items_assessed
    ? (totalScore / summaryData.num_display_items_assessed).toFixed(1)
    : "N/A";

  const toggleDetails = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        {summaryData.reproduction_type === "full" ? (
          <Typography variant="h6" className={classes.summaryPara}>
            This reproduction assessed
            <strong>
              {" "}
              {summaryData.candidate_abandoned_at
                ? "N/A"
                : summaryData.num_claims}{" "}
            </strong>
            claim{summaryData.num_claims !== 1 ? "s" : ""} that{" "}
            {summaryData.num_claims !== 1 ? "are" : "is"} supported by
            <strong>
              {" "}
              {summaryData.candidate_abandoned_at
                ? "N/A"
                : summaryData.num_display_items_assessed}{" "}
            </strong>
            display item
            {summaryData.num_display_items_assessed !== 1 ? "s" : ""}.
          </Typography>
        ) : (
          <></>
        )}
        {summaryData.reproduction_type === "full" ? (
          <Typography variant="h6" className={classes.summaryPara}>
            The average reproducibility score across all
            <strong>
              {" "}
              {summaryData.candidate_abandoned_at
                ? "N/A"
                : summaryData.num_display_items_assessed}{" "}
            </strong>
            display item
            {summaryData.num_display_items_assessed !== 1 ? "s" : ""}{" "}
            {summaryData.num_display_items_assessed !== 1 ? "is" : "are"}
            <strong>
              {" "}
              {summaryData.candidate_abandoned_at ? "N/A" : averageScore}
            </strong>{" "}
            (on a scale from 1 to 10, where 5 and 10 represent reproducible from
            analysis and raw data respectively).
          </Typography>
        ) : (
          <Typography variant="h6" className={classes.summaryPara}>
            The reproducibility score of the overall paper is{" "}
            <strong>{summaryData.paper_level_reproducibility_score}</strong> (on
            a scale from 1 to 10, where 5 and 10 represent reproducible from
            analysis and raw data respectively).
          </Typography>
        )}

        <p className={classes.detailPara}>
          {summaryData.reproduction_type === "full" ? (
            <em>
              Click{" "}
              <Link onClick={toggleDetails} className={classes.hoverClick}>
                {" "}
                here{" "}
              </Link>{" "}
              to get more detailed information about this reproduction.
            </em>
          ) : (
            <>
              <em>Reproduction DOI:</em>{" "}
              {props.reproduction.doi ? (
                <ExternalLink href={fullDoi}>{fullDoi}</ExternalLink>
              ) : (
                "DOI not available"
              )}
              <br />
              <em>
                See below to get more information about this reproduction.
              </em>
            </>
          )}
        </p>

        {showDetails && (
          <>
            <p>
              <em>Reproducer:</em> {props.reproduction.authors.join(", ")}
              <br />
              <em>Date created:</em> {createdAtDate}
              <br />
              <em>Date submitted:</em> {candidateAbandonedAt || submittedAtDate}
              <br />
              <em>Reproduction DOI:</em>{" "}
              {props.reproduction.doi ? (
                <ExternalLink href={fullDoi}>{fullDoi}</ExternalLink>
              ) : (
                "DOI not available"
              )}
            </p>
            <p>
              <Link
                href={`/reproductions/${props.reproduction.id}/published/scoping?step=2`}
              >
                <em>Claims assessed:</em>{" "}
                {summaryData.candidate_abandoned_at
                  ? "N/A"
                  : summaryData.num_claims}
              </Link>
              <br />
              <Link
                href={`/reproductions/${props.reproduction.id}/published/assessment?step=3`}
              >
                <em>Display Items (DI) assessed:</em>{" "}
                {summaryData.candidate_abandoned_at
                  ? "N/A"
                  : summaryData.num_display_items_assessed}
              </Link>
              <br />
              <Link
                href={`/reproductions/${props.reproduction.id}/published/improvement?step=0`}
              >
                <em>Improvements:</em>{" "}
                {summaryData.candidate_abandoned_at
                  ? "N/A"
                  : summaryData.num_improvements}
              </Link>
            </p>
            <section>
              <Link
                href={`/reproductions/${props.reproduction.id}/published/robustness?step=0`}
              >
                <em>Robustness tests:</em>
              </Link>
              <ul style={{ margin: 0 }}>
                <li>
                  Feasible choices added:{" "}
                  {summaryData.candidate_abandoned_at
                    ? "N/A"
                    : summaryData.num_robustness_mapping_rows}
                </li>
                <li>
                  Specifications justified:{" "}
                  {summaryData.candidate_abandoned_at
                    ? "N/A"
                    : summaryData.reasonable_robustness_tests_exist}
                </li>
              </ul>
            </section>
            <br />
            <section>
              <Link
                href={`/reproductions/${props.reproduction.id}/published/scoping?step=2`}
              >
                <em>Claims identified by reproducer:</em>
              </Link>
              <ol style={{ margin: 0 }}>
                {props.reproduction.claims.map((claim) => {
                  return <li key={claim.id}>"{claim.claimSummary}"</li>;
                })}
              </ol>
            </section>
            <br />
            {summaryData.display_items && (
              <section>
                <Link
                  href={`/reproductions/${props.reproduction.id}/published/assessment?step=3`}
                >
                  <em>Reproducibility of Display Items:</em>
                </Link>
                <ol style={{ margin: 0 }}>
                  {summaryData.display_items.map((display_item) => {
                    return (
                      <li key={display_item.id}>
                        {display_item.name} - {display_item.description}:{" "}
                        {display_item.reproducibility_score}
                      </li>
                    );
                  })}
                </ol>
              </section>
            )}
            <p>
              <em>Original reproduction package:</em>{" "}
              {originalReproductionPackage && (
                <ExternalLink href={originalReproductionPackage}>
                  {originalReproductionPackage}
                </ExternalLink>
              )}
              <br />
              <em>Revised reproduction package:</em>{" "}
              {revisedReproductionPackage ===
              "This field is currently anonymized" ? (
                "This field is currently anonymized"
              ) : (
                <ExternalLink href={revisedReproductionPackage}>
                  {revisedReproductionPackage}
                </ExternalLink>
              )}
              <br />
              <em>
                Original authors' availability for further inquiries:
              </em>{" "}
              {props.reproduction.authorsAvailable
                ? "Yes"
                : props.reproduction.authorsAvailable === false
                ? "No"
                : "Not sure"}
            </p>
            <section>
              <section>
                <em>Did the reproducer contact the original authors?</em>
                <ul style={{ margin: 0 }}>
                  {summaryData.authors_contacted_reasons?.map((reason) => {
                    return <li key={reason}>{reason}</li>;
                  })}
                </ul>
              </section>
              <section>
                <em>Response from original authors:</em>
                <ul style={{ margin: 0 }}>
                  {summaryData.authors_response_before_scoping && (
                    <li>
                      <Link
                        href={`/reproductions/${props.reproduction.id}/published/select-paper`}
                      >
                        <em>Requesting Reproduction Package</em>
                      </Link>
                    </li>
                  )}
                  {summaryData.authors_response_after_scoping && (
                    <li>
                      <Link
                        href={`/reproductions/${props.reproduction.id}/published/conclusion`}
                      >
                        <em>Help and Guidance</em>
                      </Link>
                    </li>
                  )}
                  {!summaryData.authors_response_before_scoping &&
                    !summaryData.authors_response_after_scoping && (
                      <li>
                        <em>None</em>
                      </li>
                    )}
                </ul>
              </section>
            </section>
          </>
        )}
      </Paper>
    </>
  );
}

export default Summary;
