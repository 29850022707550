import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import ExternalLink from "../../../../components/external-link";
import { questionStyles } from "../../css";
import QuestionWithRadioGroup from "../../components/question-with-radio-group";
import ReproducibilityLevels from "../../assessment/components/reproducibility-levels";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
    otherField: {
      flexGrow: 1,
    },
    ...questionStyles(4, theme),
  })
);

function AssignReproducibilityScore({ formik, readOnly }) {
  const classes = useStyles();

  const adjustedLevels =
    formik.values.paperLevelProprietaryConfidentialDataRequired === "yes";

  return (
    <div className={classes.section}>
      <Typography
        variant="body1"
        component="div"
        gutterBottom
        className={classes.question}
      >
        Are there proprietary or confidential data required to reproduce this
        paper that could not be included in the original reproduction package?
      </Typography>
      <FormControl fullWidth component="fieldset" disabled={readOnly}>
        <RadioGroup
          aria-label="Are there proprietary or confidential data required to reproduce this paper that could not be included in the original reproduction package?"
          name={"paperLevelProprietaryConfidentialDataRequired"}
          value={formik.values.paperLevelProprietaryConfidentialDataRequired}
          onChange={formik.handleChange}
          readOnly={readOnly}
        >
          <FormControlLabel value="yes" control={<Radio />} label={"Yes"} />
          <FormControlLabel value="no" control={<Radio />} label={"No"} />
          <FormControlLabel
            value="other"
            control={<Radio />}
            classes={{
              label: classes.otherField,
            }}
            label={
              <>
                I'm not sure. Explain.
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  disabled={
                    formik.values
                      .paperLevelProprietaryConfidentialDataRequired !== "other"
                  }
                  name={"paperLevelProprietaryConfidentialDataOther"}
                  value={
                    formik.values.paperLevelProprietaryConfidentialDataOther
                  }
                  onChange={formik.handleChange}
                />
              </>
            }
          />
        </RadioGroup>
      </FormControl>
      <QuestionWithRadioGroup
        questionClass={classes.question}
        readOnly={readOnly}
        name={"paperLevelReproducibilityScore"}
        value={formik.values.paperLevelReproducibilityScore}
        onChange={formik.handleChange}
        valuesAndLabels={ReproducibilityLevels(adjustedLevels)}
      >
        You can now score the computational reproducibility of this paper using
        the{" "}
        <ExternalLink href="https://bitss.github.io/ACRE/assessment.html#score">
          ACRe Reproducibility Scale
        </ExternalLink>
        {adjustedLevels && (
          <>
            {" "}
            (refer to the section "Adjusting Levels To Account for
            Confidential/Proprietary Data")
          </>
        )}
        . You will use the following concepts in this section:
        <p>
          <b>Computationally Reproducible from Analytic Data (CRA)</b>: The
          paper can be reproduced with minimal effort starting from the analytic
          data.
        </p>
        <p>
          <b>Computationally Reproducible from Raw Data (CRR)</b>: The paper can
          be reproduced with minimal effort starting from the raw data.
        </p>
        <p>
          <b>Minimal effort</b>: One hour or less is required to run the code,
          not including computing time.
        </p>
      </QuestionWithRadioGroup>
    </div>
  );
}

export default AssignReproducibilityScore;
