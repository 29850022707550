import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Reproduction } from "../state/edit-reproduction";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { List } from "immutable";
import { Edit, Visibility } from "@material-ui/icons";
import { Button } from "gatsby-material-ui-components";
import { fetchAdminReproductions } from "../actions/admin-reproductions.actions";
import { AdminReproductions } from "../state/admin-reproductions";
import NotFound from "../pages/404";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reproduction: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
  })
);

function ViewReproduction({ reproduction }: { reproduction: Reproduction }) {
  const classes = useStyles();

  const stepParam = ((stage: string) => {
    switch (stage) {
      case "select_paper":
        return 0;
      case "scoping":
        return 1;
      case "assessment":
        return 2;
      default:
        return 0;
    }
  })(reproduction.workflowStage);

  return (
    <Card className={classes.reproduction}>
      <CardContent>
        <Typography variant="body1" gutterBottom>
          {reproduction.projectNickname ? (
            <span>
              <strong>{reproduction.projectNickname}</strong>
            </span>
          ) : (
            reproduction.paperType === "declared" && (
              <span>Missing project nickname</span>
            )
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {reproduction.paper.title ? (
            <span>
              Reproduction for <strong>{reproduction.paper.title}</strong>
            </span>
          ) : (
            <span>Missing paper title</span>
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Created by: {reproduction.authors.join(", ")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Created on: {reproduction.createdAt}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Paper status: <strong>{reproduction.paperType}</strong>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Current stage: <strong>{reproduction.currentStage}</strong>
        </Typography>
        {reproduction.workflowStage === "submitted" && (
          <Typography variant="body1" gutterBottom>
            Submitted on: <strong>{reproduction.submittedAt}</strong>
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          className={classes.button}
          startIcon={<Visibility />}
          color="primary"
          variant="contained"
          href={`/reproductions/${reproduction.id}/index?step=${stepParam}`}
          size="small"
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
}

function Admin({
  path,
  adminReproductions,
  reproductions,
  fetchAdminReproductions,
}: {
  path: string;
  adminReproductions: AdminReproductions;
  reproductions: List<Reproduction>;
  fetchAdminReproductions: () => void;
}) {
  const classes = useStyles();

  useEffect(() => {
    fetchAdminReproductions();
  }, []);

  return adminReproductions.notFound ? (
    <NotFound />
  ) : (
    <Container maxWidth="md">
      <Box my={4}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Admin dashboard
          </Typography>
        </Box>

        {reproductions.map((reproduction, i) => {
          return (
            <ViewReproduction
              key={reproduction.id}
              reproduction={reproduction}
            />
          );
        })}
      </Box>
    </Container>
  );
}

const mapStateToProps = ({
  adminReproductions,
}: {
  adminReproductions: AdminReproductions;
}) => {
  return {
    adminReproductions,
    reproductions: adminReproductions.reproductions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchAdminReproductions: () => dispatch(fetchAdminReproductions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
