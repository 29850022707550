import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import {
  Assessment,
  DisplayItem,
  EditAssessment,
} from "../../../state/edit-assessment";
import { updateAssessmentFields } from "../../../actions/assessment.actions";

import TextField from "@material-ui/core/TextField";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
} from "@material-ui/core";
import MultipleSelect from "../components/multiple-select";
import { Add, Delete } from "@material-ui/icons";
import TabPanel from "../components/tab-panel";
import QuestionWithRadioGroup from "../components/question-with-radio-group";
import QuestionWithTextField from "../components/question-with-text-field";
import { QuestionWithTrueFalseRadio } from "../components/question-with-true-false-radio";
import ExternalLink from "../../../components/external-link";
import ReproducibilityLevels from "./components/reproducibility-levels";
import Modal from "@material-ui/core/Modal";

const useStyles = (sectionNumber) =>
  makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      select: {
        minWidth: 240,
      },
      otherField: {
        flexGrow: 1,
      },
      formField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      ...questionStyles(sectionNumber, theme),
      questionTabPanelOne: {
        "&:before": {
          content: `"${sectionNumber}.1." counter(question) "."`,
        },
      },
      questionTabPanelTwo: {
        "&:before": {
          content: `"${sectionNumber}.2." counter(question) "."`,
        },
      },
      questionTabPanelThree: {
        "&:before": {
          content: `"${sectionNumber}.3." counter(question) "."`,
        },
      },
      questionTabPanelFour: {
        "&:before": {
          content: `"${sectionNumber}.4." counter(question) "."`,
        },
      },
      readOnly: {
        padding: theme.spacing(1),
        border: "1px solid",
        backgroundColor: "#f1f1f1",
      },
      table: {
        borderCollapse: "collapse",
        "& th": {
          border: "1px solid #999",
          padding: "0.5rem",
        },
        "& td": {
          border: "1px solid #999",
          padding: "0.5rem",
        },
      },
    })
  );

function EditDisplayItem({
  readOnly,
  displayItem,
  updateDisplayItem,
  nextDisplayItem,
  disableNextDisplayItem,
  setNextStepEnabled,
  index,
}: {
  readOnly: boolean;
  displayItem: DisplayItem;
  updateDisplayItem: Function;
  nextDisplayItem: Function;
  setNextStepEnabled: Function;
  disableNextDisplayItem: boolean;
  index: number;
}) {
  const classes = useStyles("5." + (index + 1))();

  const [currentTab, setCurrentTab] = React.useState(0);

  useEffect(() => {
    setNextStepEnabled(disableNextDisplayItem && currentTab == 3);
  }, [currentTab]);

  const onChange = (name: string) => (e: any) => {
    updateDisplayItem(displayItem.merge({ [name]: e.target.value }));
  };

  const displayItemName = () => {
    return displayItem.name === "" ? "this display item" : displayItem.name;
  };

  let ableToExecuteAllSteps = displayItem.ableToExecuteAllSteps !== "false";
  let ableToExecuteEventually =
    displayItem.ableToExecuteAllSteps === "true" ||
    displayItem.ableToExecuteAfterMinorCorrections === "true";

  let displayItemFromScoping = displayItem.containsEstimates === "true";

  let reproductionTreeExists = displayItem.reproductionTreeInDiagram === "true";
  let reproductionTreeNeedsAmending =
    displayItem.reproductionTreeFragmentedButShouldBeConnected === "true";

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeTabBottomBar = (event, newValue) => {
    setCurrentTab(newValue);
    window.scrollTo({ top: 0 });
  };

  const questionTabPanelOneClass = [
    classes.question,
    classes.questionTabPanelOne,
  ].join(" ");

  const questionTabPanelTwoClass = [
    classes.question,
    classes.questionTabPanelTwo,
  ].join(" ");

  const questionTabPanelThreeClass = [
    classes.question,
    classes.questionTabPanelThree,
  ].join(" ");

  const questionTabPanelFourClass = [
    classes.question,
    classes.questionTabPanelFour,
  ].join(" ");

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box pr={1}>
          <Typography variant="h4">
            <span>Display Item {index + 1} - </span>
          </Typography>
        </Box>
        <Box flexGrow={1}>
          {displayItemFromScoping || readOnly ? (
            <Typography variant="h4">{displayItem.name}</Typography>
          ) : (
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              value={displayItem.name}
              placeholder={"e.g. Table 1"}
              onChange={onChange("name")}
            />
          )}
        </Box>
      </Box>
      <Paper>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Required Materials" />
          <Tab label="Availability of Materials" />
          <Tab label="Computation" />
          <Tab label="Subjective Assessment" />
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          {displayItemFromScoping && (
            <QuestionWithRadioGroup
              classes={classes}
              questionClass={questionTabPanelOneClass}
              readOnly={readOnly}
              value={displayItem.willAssessAllEstimates}
              onChange={onChange("willAssessAllEstimates")}
              valuesAndLabels={[
                {
                  value: "entirety",
                  label: "In its entirety",
                },
                {
                  value: "pre-specified estimates",
                  label: "Only the pre-specified estimates",
                },
              ]}
            >
              Will you assess {displayItemName()} in its entirety or only the
              pre-specified estimate(s)?
            </QuestionWithRadioGroup>
          )}
          <QuestionWithTextField
            classes={classes}
            questionClass={questionTabPanelOneClass}
            readOnly={readOnly}
            multiline
            fullWidth
            variant="outlined"
            value={displayItem.description}
            placeholder="e.g. Density Plots For Control and Treatment Groups"
            onChange={onChange("description")}
          >
            Enter the description of this display item in the paper.
            <p>
              <small>
                For figures and tables, copy and paste the corresponding title.
                For inline results, copy and paste the sentence that includes
                the result.
              </small>
            </p>
          </QuestionWithTextField>
          <QuestionWithTrueFalseRadio
            classes={classes}
            questionClass={questionTabPanelOneClass}
            questionText="Does the output that you obtained from the Diagram Builder contain a reproduction tree for this display item?"
            value={displayItem.reproductionTreeInDiagram}
            onChange={onChange("reproductionTreeInDiagram")}
            readOnly={readOnly}
          >
            <p>
              <em>Reminder</em>: Sometimes the reproduction package will not
              produce display items as final outputs directly. In this
              situation, the final code script will generate some type of output
              (e.g., "results1.log", "results2.csv") that will require manual
              copying and pasting to reproduce a desired display item (e.g.
              "Table 1"). In this case, we recommend adding one auxiliary line
              to the code spreadsheet linking the final output to the desired
              display item (e.g., File Name: aux1, Inputs: "results1.log;
              results2.csv", Outputs: "Table 1").
            </p>
          </QuestionWithTrueFalseRadio>
          <QuestionWithTrueFalseRadio
            classes={classes}
            questionClass={questionTabPanelOneClass}
            questionText="Does the reproduction tree include the raw files identified in the data sources of the paper for this display item?"
            value={displayItem.reproductionTreeIncludeDataSourceFiles}
            onChange={onChange("reproductionTreeIncludeDataSourceFiles")}
            readOnly={readOnly}
            disabled={!reproductionTreeExists}
          />
          <QuestionWithTrueFalseRadio
            classes={classes}
            questionClass={questionTabPanelOneClass}
            questionText="Does the reproduction tree seem to be fragmented into two or more separate branches that, based on your understanding of the paper, should be connected?"
            value={displayItem.reproductionTreeFragmentedButShouldBeConnected}
            onChange={onChange(
              "reproductionTreeFragmentedButShouldBeConnected"
            )}
            readOnly={readOnly}
            disabled={!reproductionTreeExists}
          />
          <QuestionWithTrueFalseRadio
            classes={classes}
            questionClass={questionTabPanelOneClass}
            questionText=""
            value={displayItem.reproductionTreeAmended}
            onChange={onChange("reproductionTreeAmended")}
            readOnly={readOnly}
            disabled={!reproductionTreeNeedsAmending}
          >
            You can amend the tree by suggesting missing files that combine two
            or more disconnected branches that seem to be part of the same tree
            behind the current display item. Please see{" "}
            <ExternalLink href="https://bitss.github.io/ACRE/assessment.html#incomplete-workflow-information">
              these instructions
            </ExternalLink>{" "}
            in the Guide on how to suggest missing files to amend the tree.
            <p>Were you able to amend the tree?</p>
          </QuestionWithTrueFalseRadio>
          <Box mt={4}>
            <Typography>
              Even if the tree is fragmented, you can still go on to the next
              step of the reproduction.
            </Typography>
          </Box>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {!reproductionTreeExists && (
            <Box mt={4}>
              <Typography>
                <strong>
                  Please make sure you have completed the "Required Materials"
                  section before moving forward. If you have completed it
                  indicating that the Diagram Builder did not contain a
                  reproduction tree for this display item, then you can skip
                  this section
                </strong>
              </Typography>
            </Box>
          )}
          <Typography
            variant="body1"
            component="div"
            className={(reproductionTreeExists
              ? [questionTabPanelTwoClass]
              : [classes.typographyDisabled, questionTabPanelTwoClass]
            ).join(" ")}
            gutterBottom
          >
            Based on the diagram from the builder (or the one you drew to fill
            in missing files) do you have all of the necessary files to
            reproduce this display item?
          </Typography>

          {readOnly ? (
            <div className={classes.readOnly}>
              {((answer) => {
                switch (answer) {
                  case "all_including_raw":
                    return (
                      <>
                        Yes, including the <em>raw</em> data sets.
                      </>
                    );
                  case "all_including_analytic_not_raw":
                    return (
                      <>
                        Yes, including the <em>analytic</em> datasets, but not
                        the <em>raw</em> datasets.
                      </>
                    );
                  case "in_between":
                    return (
                      <>
                        <p>Somewhere in between.</p>
                        <p>
                          {displayItem.allNecessaryFilesInBetweenExplanation}
                        </p>
                      </>
                    );
                  case "not_enough_files":
                    return (
                      <>
                        There are not enough files to produce this display item
                        from any point in the diagram.
                      </>
                    );
                  case "will_not_assess":
                    return (
                      <>
                        I will not assess the reproducibility of this display
                        item.
                      </>
                    );
                }
              })(displayItem.allNecessaryFiles)}
            </div>
          ) : (
            <FormControl
              component="fieldset"
              disabled={!reproductionTreeExists}
            >
              <RadioGroup
                aria-label="Based on the diagram from the builder (or the one you drew to fill in missing files) do you have all of the necessary files to reproduce this display item?"
                value={displayItem.allNecessaryFiles}
                onChange={onChange("allNecessaryFiles")}
              >
                <FormControlLabel
                  value="all_including_raw"
                  control={<Radio />}
                  label={
                    <>
                      Yes, including the <em>raw</em> data sets.
                    </>
                  }
                />
                <FormControlLabel
                  value="all_including_analytic_not_raw"
                  control={<Radio />}
                  label={
                    <>
                      Yes, including the <em>analytic</em> datasets, but not the{" "}
                      <em>raw</em> datasets.
                    </>
                  }
                />
                <FormControlLabel
                  value="in_between"
                  control={<Radio />}
                  classes={{
                    label: classes.otherField,
                  }}
                  label={
                    <>
                      Somewhere in between. If possible, specify up to which
                      component in the diagram.
                      <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        disabled={
                          !reproductionTreeExists ||
                          displayItem.allNecessaryFiles !== "in_between"
                        }
                        value={
                          displayItem.allNecessaryFilesInBetweenExplanation
                        }
                        onChange={onChange(
                          "allNecessaryFilesInBetweenExplanation"
                        )}
                      />
                    </>
                  }
                />
                <FormControlLabel
                  value="not_enough_files"
                  control={<Radio />}
                  label={
                    <>
                      There are not enough files to produce this display item
                      from any point in the diagram.
                    </>
                  }
                />
                <FormControlLabel
                  value="will_not_assess"
                  control={<Radio />}
                  label={
                    <>
                      I will not assess the reproducibility of this display
                      item.
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          {!reproductionTreeExists && (
            <Box mt={4}>
              <Typography>
                <strong>
                  Please make sure you have completed the "Required Materials"
                  section before moving forward. If you have completed it
                  indicating that the Diagram Builder did not contain a
                  reproduction tree for this display item, then you can skip
                  this section
                </strong>
              </Typography>
            </Box>
          )}
          <QuestionWithTrueFalseRadio
            classes={classes}
            questionClass={questionTabPanelThreeClass}
            questionText="Were you able to execute all of the steps in the diagram for this display item in the proper order without any errors? If there’s a master .do file, it will do this step for you."
            value={displayItem.ableToExecuteAllSteps}
            onChange={onChange("ableToExecuteAllSteps")}
            readOnly={readOnly}
            disabled={!reproductionTreeExists}
          />
          <Typography
            variant="body1"
            component="div"
            className={(!reproductionTreeExists || ableToExecuteAllSteps
              ? [classes.typographyDisabled, questionTabPanelThreeClass]
              : [questionTabPanelThreeClass]
            ).join(" ")}
            gutterBottom
          >
            Why not?
          </Typography>
          {readOnly ? (
            displayItem.unableToExecuteAllStepsReasons.map((reason) => (
              <div className={classes.readOnly} key={reason}>
                {reason}
                {reason ===
                  "I don't have the necessary software or version of the software installed. Specify the required software and the required version." && (
                  <p>
                    {displayItem.unableToExecuteAllStepsRequiredSoftwareVersion}
                  </p>
                )}
                {reason === "Other errors. Explain." && (
                  <p>{displayItem.unableToExecuteAllStepsOther}</p>
                )}
              </div>
            ))
          ) : (
            <MultipleSelect
              disabled={!reproductionTreeExists || ableToExecuteAllSteps}
              classes={{
                label: classes.otherField,
              }}
              labels={[
                <div>
                  I don't have the necessary software or version of the software
                  installed. Specify the required software and the required
                  version.
                  <TextField
                    multiline
                    fullWidth
                    variant="outlined"
                    disabled={
                      !displayItem.unableToExecuteAllStepsReasons.contains(
                        "I don't have the necessary software or version of the software installed. Specify the required software and the required version."
                      )
                    }
                    value={
                      displayItem.unableToExecuteAllStepsRequiredSoftwareVersion
                    }
                    onChange={onChange(
                      "unableToExecuteAllStepsRequiredSoftwareVersion"
                    )}
                  />
                </div>,
                "Files are missing.",
                "There are path/directory errors.",
                "I need to install additional packages, libraries, or updates.",
                <div>
                  Other errors. Explain.
                  <TextField
                    multiline
                    fullWidth
                    variant="outlined"
                    disabled={
                      !displayItem.unableToExecuteAllStepsReasons.contains(
                        "Other errors. Explain."
                      )
                    }
                    value={displayItem.unableToExecuteAllStepsOther}
                    onChange={onChange("unableToExecuteAllStepsOther")}
                  />
                </div>,
              ]}
              values={[
                "I don't have the necessary software or version of the software installed. Specify the required software and the required version.",
                "Files are missing.",
                "There are path/directory errors.",
                "I need to install additional packages, libraries, or updates.",
                "Other errors. Explain.",
              ]}
              selectedValues={displayItem.unableToExecuteAllStepsReasons}
              updateValues={(v: any) => {
                updateDisplayItem(
                  displayItem.merge({ unableToExecuteAllStepsReasons: v })
                );
              }}
            />
          )}
          <Typography
            variant="body1"
            component="div"
            className={(!reproductionTreeExists || ableToExecuteAllSteps
              ? [classes.typographyDisabled, questionTabPanelThreeClass]
              : [questionTabPanelThreeClass]
            ).join(" ")}
            gutterBottom
          >
            If you attempted to correct any minor issues, please record how much
            time you spent on this.
          </Typography>
          {readOnly ? (
            <table className={[classes.table, classes.readOnly].join(" ")}>
              <tbody>
                <tr>
                  <td>
                    Time spent installing additional packages, libraries,
                    updates (in minutes)
                  </td>
                  <td>{displayItem.numMinutesInstallingAdditionalPackages}</td>
                </tr>
                <tr>
                  <td>
                    Time spent changing directory paths and/or moving files (in
                    minutes)
                  </td>
                  <td>{displayItem.numMinutesChangingDirectoryPaths}</td>
                </tr>
                <tr>
                  <td>Time spent doing other minor corrections (in minutes)</td>
                  <td>{displayItem.numMinutesOtherImprovements}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <TextField
                disabled={!reproductionTreeExists || ableToExecuteAllSteps}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.formField}
                type="number"
                fullWidth
                label="Time spent installing additional packages, libraries, updates (in minutes)"
                value={displayItem.numMinutesInstallingAdditionalPackages}
                onChange={onChange("numMinutesInstallingAdditionalPackages")}
              />
              <TextField
                disabled={!reproductionTreeExists || ableToExecuteAllSteps}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.formField}
                type="number"
                fullWidth
                label="Time spent changing directory paths and/or moving files (in minutes)"
                value={displayItem.numMinutesChangingDirectoryPaths}
                onChange={onChange("numMinutesChangingDirectoryPaths")}
              />
              <TextField
                disabled={!reproductionTreeExists || ableToExecuteAllSteps}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.formField}
                type="number"
                fullWidth
                label="Time spent doing other minor corrections (in minutes)"
                value={displayItem.numMinutesOtherImprovements}
                onChange={onChange("numMinutesOtherImprovements")}
              />
            </>
          )}
          <QuestionWithTrueFalseRadio
            classes={classes}
            questionClass={questionTabPanelThreeClass}
            questionText="After minor corrections, were you able to execute all code necessary to reproduce this display item in the proper order without any errors?"
            value={displayItem.ableToExecuteAfterMinorCorrections}
            onChange={onChange("ableToExecuteAfterMinorCorrections")}
            readOnly={readOnly}
            disabled={!reproductionTreeExists || ableToExecuteAllSteps}
          >
            <p>
              <em>Note</em>: If you are trying to reproduce a figure, please
              describe any potential differences using numerical examples (e.g.,
              "the value that I reproduce for x = 10 is much higher than that of
              the original figure") and add your version of the figure to your
              revised reproduction package.
            </p>
          </QuestionWithTrueFalseRadio>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            className={(!reproductionTreeExists || !ableToExecuteEventually
              ? [classes.typographyDisabled, questionTabPanelThreeClass]
              : [questionTabPanelThreeClass]
            ).join(" ")}
          >
            In your subjective view, is the output produced by this process the
            same as in the display item from the paper?{" "}
          </Typography>
          {displayItem.prespecifiedEstimates.length > 0 && (
            <Box
              mt={4}
              className={
                !reproductionTreeExists || !ableToExecuteEventually
                  ? classes.typographyDisabled
                  : ""
              }
            >
              <Typography variant="body1">
                Pre-specified estimates associated with this display item
              </Typography>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Estimate</th>
                    <th>SD</th>
                  </tr>
                </thead>
                <tbody>
                  {displayItem.prespecifiedEstimates.map(
                    (prespecifiedEstimate, index) => (
                      <tr key={index}>
                        <td>
                          {prespecifiedEstimate.claimName} -{" "}
                          {prespecifiedEstimate.estimateSpecNumber}
                        </td>
                        <td>{prespecifiedEstimate.estimateValue}</td>
                        <td>{prespecifiedEstimate.standardError}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <ul></ul>
            </Box>
          )}
          {readOnly ? (
            <div className={classes.readOnly}>
              {((answer) => {
                switch (answer) {
                  case "same":
                    return <>Yes, it is exactly the same.</>;
                  case "similar":
                    return (
                      <>
                        <p>
                          No, but it is similar. Report your result and explain.
                        </p>
                        <p>{displayItem.isOutputSimilarExplanation}</p>
                      </>
                    );
                  case "different":
                    return (
                      <>
                        <p>
                          No, and it is meaningfully different. Report your
                          result and explain.
                        </p>
                        <p>{displayItem.isOutputDifferentExplanation}</p>
                      </>
                    );
                }
              })(displayItem.isOutputSame)}
            </div>
          ) : (
            <FormControl
              fullWidth
              component="fieldset"
              disabled={!reproductionTreeExists || !ableToExecuteEventually}
            >
              <RadioGroup
                aria-label="In your subjective view, is the output produced by this process the same as in the display item from the paper?"
                value={displayItem.isOutputSame}
                onChange={onChange("isOutputSame")}
              >
                <FormControlLabel
                  value="same"
                  control={<Radio />}
                  label={<>Yes, it is exactly the same.</>}
                />
                <FormControlLabel
                  value="similar"
                  control={<Radio />}
                  classes={{
                    label: classes.otherField,
                  }}
                  label={
                    <>
                      No, but it is similar. Explain.
                      <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        disabled={displayItem.isOutputSame !== "similar"}
                        value={displayItem.isOutputSimilarExplanation}
                        onChange={onChange("isOutputSimilarExplanation")}
                      />
                    </>
                  }
                />
                <FormControlLabel
                  value="different"
                  control={<Radio />}
                  classes={{
                    label: classes.otherField,
                  }}
                  label={
                    <>
                      No, and it is meaningfully different. Explain.
                      <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        disabled={displayItem.isOutputSame !== "different"}
                        value={displayItem.isOutputDifferentExplanation}
                        onChange={onChange("isOutputDifferentExplanation")}
                      />
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <Typography variant="body1" component="div" gutterBottom>
            As a complement to the previous steps, please score which of the
            following 10 levels corresponds best to your assessment of
            reproducibility for this display item.
          </Typography>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            className={questionTabPanelFourClass}
          >
            Are there proprietary or confidential data required to reproduce
            this display item that could not be included in the original
            reproduction package?
          </Typography>
          {readOnly ? (
            <div className={classes.readOnly}>
              {((answer) => {
                switch (answer) {
                  case "yes":
                    return "Yes";
                  case "no":
                    return "No";
                  case "other":
                    return (
                      <>
                        <p>I'm not sure. Explain.</p>
                        <p>{displayItem.proprietaryConfidentialDataOther}</p>
                      </>
                    );
                }
              })(displayItem.proprietaryConfidentialDataRequired)}
            </div>
          ) : (
            <FormControl fullWidth component="fieldset">
              <RadioGroup
                aria-label="Are there proprietary or confidential data required to reproduce this display item that could not be included in the original reproduction package?"
                value={displayItem.proprietaryConfidentialDataRequired}
                onChange={onChange("proprietaryConfidentialDataRequired")}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={"Yes"}
                />
                <FormControlLabel value="no" control={<Radio />} label={"No"} />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  classes={{
                    label: classes.otherField,
                  }}
                  label={
                    <>
                      I'm not sure. Explain.
                      <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        disabled={
                          displayItem.proprietaryConfidentialDataRequired !==
                          "other"
                        }
                        value={displayItem.proprietaryConfidentialDataOther}
                        onChange={onChange("proprietaryConfidentialDataOther")}
                      />
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
          <ReproducibilityScore
            displayItem={displayItem}
            updateDisplayItem={updateDisplayItem}
            questionClass={questionTabPanelFourClass}
            readOnly={readOnly}
          />
          <Box display="flex" flexDirection="row-reverse">
            <Button
              variant="contained"
              color="primary"
              onClick={nextDisplayItem}
              disabled={disableNextDisplayItem}
            >
              {disableNextDisplayItem
                ? "This is the last display item"
                : "Next display item"}
            </Button>
          </Box>
        </TabPanel>
        <Box mt={2} pb={1}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTabBottomBar}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Required Materials" />
            <Tab label="Availability of Materials" />
            <Tab label="Computation" />
            <Tab label="Subjective Assessment" />
          </Tabs>
        </Box>
      </Paper>
    </>
  );
}

function ReproducibilityScore({
  displayItem,
  updateDisplayItem,
  questionClass,
  readOnly,
}: {
  displayItem: DisplayItem;
  updateDisplayItem: Function;
  questionClass: any;
  readOnly: boolean;
}) {
  const adjustedLevels =
    displayItem.proprietaryConfidentialDataRequired === "yes";

  return (
    <div>
      <QuestionWithRadioGroup
        questionClass={questionClass}
        readOnly={readOnly}
        value={displayItem.reproducibilityScore}
        onChange={(e: any) => {
          updateDisplayItem(
            displayItem.merge({ reproducibilityScore: e.target.value })
          );
        }}
        valuesAndLabels={ReproducibilityLevels(adjustedLevels)}
      >
        You can now score the computational reproducibility of this display item
        using the{" "}
        <ExternalLink href="https://bitss.github.io/ACRE/assessment.html#score">
          ACRe Reproducibility Scale
        </ExternalLink>
        {adjustedLevels && (
          <>
            {" "}
            (refer to the section "Adjusting Levels To Account for
            Confidential/Proprietary Data")
          </>
        )}
        . You will use the following concepts in this section:
        <p>
          <b>Computationally Reproducible from Analytic Data (CRA)</b>: The
          display item can be reproduced with minimal effort starting from the
          analytic data.
        </p>
        <p>
          <b>Computationally Reproducible from Raw Data (CRR)</b>: The display
          item can be reproduced with minimal effort starting from the raw data.
        </p>
        <p>
          <b>Minimal effort</b>: One hour or less is required to run the code,
          not including computing time.
        </p>
      </QuestionWithRadioGroup>
    </div>
  );
}

function DisplayItems({
  assessment,
  readOnly,
  updateAssessmentFields,
  setNextStepEnabled,
}: {
  assessment: Assessment;
  readOnly: boolean;
  updateAssessmentFields: Function;
  setNextStepEnabled: Function;
}) {
  const classes = useStyles("3.0")();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [modalOpen, setModalOpen] = React.useState(false);

  const openDeleteConfirmDialog = () => {
    setModalOpen(true);
  };

  const closeDeleteConfirmDialog = () => {
    setModalOpen(false);
  };

  const menuOptions = [
    "None selected",
    ...assessment.displayItems.map(
      (displayItem: DisplayItem, index: number) =>
        `Display Item ${index + 1}: ${displayItem.name}`
    ),
  ];

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const addDisplayItem = () => {
    updateAssessmentFields({
      displayItems: assessment.displayItems.push(new DisplayItem()),
    });
    setSelectedIndex(menuOptions.length);
  };

  const currentDisplayItemDeleteable =
    selectedIndex > 0 &&
    assessment.displayItems.get(selectedIndex - 1) &&
    assessment.displayItems.get(selectedIndex - 1).containsEstimates ===
      "false";

  const deleteDisplayItem = () => {
    updateAssessmentFields({
      displayItems: assessment.displayItems.delete(selectedIndex - 1),
    });
    setSelectedIndex(0);
    closeDeleteConfirmDialog();
  };

  const updateDisplayItem = (index: number) => (value: any) => {
    updateAssessmentFields({
      displayItems: assessment.displayItems.set(index, value),
    });
  };

  const editDisplayItem = (displayItem: DisplayItem, index: number) => (
    <EditDisplayItem
      readOnly={readOnly}
      key={index}
      displayItem={displayItem}
      index={index}
      updateDisplayItem={updateDisplayItem(index)}
      // selectedIndex and index are off by 1 to accommodate default menu option
      nextDisplayItem={() => {
        setSelectedIndex(index + 2);
        window.scrollTo({ top: 0 });
      }}
      setNextStepEnabled={setNextStepEnabled}
      disableNextDisplayItem={index + 1 === assessment.displayItems.size}
    />
  );

  const getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  setNextStepEnabled(false);

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Assess reproducibility of display items
      </Typography>
      <Box mb={4} className={classes.section}>
        <Typography variant="body1" component="p" gutterBottom>
          In this section, you will assess the level of reproducibility of all
          of the display items that contain estimates related to your claims of
          interest. You will begin by identifying all the files required to
          reproduce a display item, then you will verify the availability of
          those files and then assess computation. By the end of this process
          you will be presented with a 10 point scale to assign a
          reproducibility score to each display item.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Note that you can add additional display items that you did not
          specified in the scoping section, the only difference between these
          two is that the scores aggregated in the SSRP will distinguish between
          those display items that were pre-specify and those that were not.
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          my={4}
        >
          <FormControl variant="filled" className={classes.select}>
            <InputLabel id="select-display-item-label">
              Select Display Item:
            </InputLabel>
            <Select
              id="select-display-item"
              className={classes.select}
              value={menuOptions[selectedIndex]}
            >
              {menuOptions.map((option, index) => (
                <MenuItem
                  key={option}
                  value={option}
                  disabled={index === 0}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!readOnly && currentDisplayItemDeleteable && (
            <Button
              startIcon={<Delete />}
              variant="outlined"
              color="secondary"
              onClick={openDeleteConfirmDialog}
            >
              Delete current display item
            </Button>
          )}
          {!readOnly && (
            <Button
              startIcon={<Add />}
              variant="contained"
              color="primary"
              onClick={addDisplayItem}
            >
              Assess additional display item
            </Button>
          )}
        </Box>

        {selectedIndex > 0 &&
          assessment.displayItems.get(selectedIndex - 1) && (
            <div>
              {editDisplayItem(
                assessment.displayItems.get(selectedIndex - 1),
                selectedIndex - 1
              )}
            </div>
          )}

        <Modal
          open={modalOpen}
          onClose={closeDeleteConfirmDialog}
          aria-labelledby="Delete display item modal"
        >
          <div style={getModalStyle()} className={classes.paper}>
            <p>Are you sure you want to delete this?</p>
            <Box display="flex" flexDirection="row">
              <Box pr={2}>
                <Button
                  onClick={deleteDisplayItem}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Delete
                </Button>
              </Box>
              <Button
                onClick={closeDeleteConfirmDialog}
                variant="outlined"
                color="secondary"
                size="small"
              >
                Cancel
              </Button>
            </Box>
          </div>
        </Modal>
      </Box>
    </>
  );
}

const mapStateToProps = ({
  editAssessment: { assessment },
}: {
  editAssessment: EditAssessment;
}) => {
  return { assessment };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAssessmentFields: (fields: any) =>
      dispatch(updateAssessmentFields(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayItems);
