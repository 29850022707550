import React from "react";
import { Fab, Grid } from "@material-ui/core";
import { Save } from "@material-ui/icons";

function SaveButton({
  onClick,
  readOnly,
}: {
  onClick: Function;
  readOnly: boolean;
}) {
  return readOnly ? (
    <></>
  ) : (
    <Grid container justify="flex-end">
      <Fab variant="extended" color="secondary" onClick={() => onClick()}>
        <Save /> Save
      </Fab>
    </Grid>
  );
}

export default SaveButton;
