import { ReproductionPackage } from "../../../../state/edit-reproduction";
import { Card, CardActions, CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "gatsby-material-ui-components";
import { Delete } from "@material-ui/icons";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reproductionPackage: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
  })
);

export function EditReproductionPackage({
  reproductionPackage,
  updateReproductionPackage,
  deleteReproductionPackage,
  readOnly,
}: {
  reproductionPackage: ReproductionPackage;
  updateReproductionPackage: Function;
  deleteReproductionPackage: Function;
  readOnly: true;
}) {
  const classes = useStyles();

  const onChange = (name: string) => (e: any) => {
    updateReproductionPackage(
      reproductionPackage.merge({ [name]: e.target.value })
    );
  };

  return readOnly ? (
    <Card className={[classes.reproductionPackage, classes.readOnly].join(" ")}>
      <Typography component="div" gutterBottom>
        <p>
          <strong>Package name</strong>: {reproductionPackage.name}
        </p>
        <p>
          <strong>Package URL</strong>: {reproductionPackage.url}
        </p>
      </Typography>
    </Card>
  ) : (
    <Card className={classes.reproductionPackage}>
      <CardContent>
        {reproductionPackage.contentType === "code" &&
        reproductionPackage.stage === "original" ? (
          <Typography>
            Record the main repository that stores the code for the reproduction
            package provided by the authors.
          </Typography>
        ) : (
          <></>
        )}
        {reproductionPackage.contentType === "code" &&
        reproductionPackage.stage === "revised" ? (
          <Typography>
            Record your main repository that will store the code and data for
            the reproduction package. If data cannot be stored in the same
            repository as code, please add any additional repositories where
            data is stored.
          </Typography>
        ) : (
          <></>
        )}
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.formField}
          label="Contents of reproduction package"
          fullWidth
          placeholder="e.g. Main code repository with data"
          value={reproductionPackage.name}
          onChange={onChange("name")}
        />
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.formField}
          label="URL"
          fullWidth
          placeholder="e.g. https://github.com/username/revise_repro_railroads"
          value={reproductionPackage.url}
          onChange={onChange("url")}
        />
      </CardContent>
      <CardActions>
        {reproductionPackage.contentType === "data" ? (
          <Button
            className={classes.deleteButton}
            startIcon={<Delete />}
            color="secondary"
            variant="contained"
            onClick={deleteReproductionPackage}
            size="small"
          >
            Delete
          </Button>
        ) : (
          <Typography>
            Are there additional data in different repositories? Use the button
            below to add links to these as well.
          </Typography>
        )}
      </CardActions>
    </Card>
  );
}
