import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Container, Fab, LinearProgress } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import {
  updateFields,
  saveFields,
  fetchReproduction,
} from "../../../actions/edit-reproductions.actions";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { EditReproduction } from "../../../state/edit-reproduction";
import SummarizePaper from "./summarize-paper";
import AddReproductionPackages from "./add-reproduction-packages";
import OutlineClaims from "./outline-claims";
import { Save } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import RobustnessChecks from "./robustness-checks";
import queryString from "query-string";
import { navigate } from "@reach/router";
import ExternalLink from "../../../components/external-link";
import NotFound from "../../../pages/404";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightGroup: {
      marginLeft: "auto",
      display: "inline-flex",
    },
    actionBar: {
      display: "flex",
      padding: 8,
      alignItems: "center",
    },
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    floatingSaveButton: {
      position: "-webkit-sticky",
      position: "sticky",
      left: "90%",
      bottom: "40px",
    },
  })
);

function getSteps() {
  return [
    "Summarize paper",
    "Add revised reproduction packages",
    "Outline claims",
    "Declare robustness checks",
  ];
}

function getStepContent(
  step: number,
  readOnly: boolean,
  handleGoToAssessment: Function
) {
  switch (step) {
    case 0:
      return <SummarizePaper readOnly={readOnly} />;
    case 1:
      return <AddReproductionPackages readOnly={readOnly} />;
    case 2:
      return <OutlineClaims readOnly={readOnly} />;
    case 3:
      return (
        <RobustnessChecks
          readOnly={readOnly}
          handleGoToAssessment={handleGoToAssessment}
        />
      );
    default:
      return "";
  }
}

function ActionBar({
  disableBack = false,
  disableNext = false,
  handleBack,
  handleUp,
  handleNext,
}: {
  disableBack: boolean;
  disableNext: boolean;
  handleBack: any;
  handleUp: Function;
  handleNext: any;
}) {
  const classes = useStyles();
  return (
    <div className={classes.actionBar}>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUp}
          className={classes.button}
        >
          Return to Stages Overview
        </Button>
        <Button
          variant="contained"
          onClick={handleBack}
          className={classes.button}
          disabled={disableBack}
        >
          Previous Step
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disabled={disableNext}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
}

const HorizontalNonLinearStepper: React.SFC<any> = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);

  useEffect(() => {
    setActiveStep(props.currentStep || 0);
  }, [props.currentStep]);

  const isAuthor = props.editReproduction.reproduction.isAuthor === "true";

  const viewingShareableLink =
    props.editReproduction.reproduction.shareableLink;

  const readOnly =
    ["submitted", "assessment"].includes(
      props.editReproduction.reproduction.workflowStage
    ) ||
    !isAuthor ||
    viewingShareableLink;

  useEffect(() => {
    if (!readOnly) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [readOnly]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const isFirstStep = (): boolean => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    handleStep(activeStep + 1)();
  };

  const handleSave = () => {
    props.saveFields();
  };

  const handleBack = () => {
    handleStep(activeStep - 1)();
  };

  const handleUp = () => {
    const nextUrl = `index?step=1`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      props.saveFields(nextUrl);
    }
  };

  const handleGoToAssessment = () => {
    props.saveFields(
      `/reproductions/${props.id}/index?step=2`,
      "advance_to_assessment"
    );
  };

  const handleStep = (nextStep: number) => () => {
    const nextUrl = `scoping?step=${nextStep}`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      props.saveFields(nextUrl);
    }
    window.scrollTo({ top: 0 });
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          opacity: props.editReproduction.saving ? 1 : 0,
          transition: "opacity 300ms",
        }}
      >
        <LinearProgress />
      </div>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <ActionBar
        disableBack={isFirstStep()}
        disableNext={isLastStep()}
        handleBack={handleBack}
        handleUp={handleUp}
        handleNext={handleNext}
      />
      {!props.editReproduction.loading && (
        <div>{getStepContent(activeStep, readOnly, handleGoToAssessment)}</div>
      )}
      <ActionBar
        disableBack={isFirstStep()}
        disableNext={isLastStep()}
        handleBack={handleBack}
        handleUp={handleUp}
        handleNext={handleNext}
      />
      {!readOnly && (
        <Fab
          variant="extended"
          color="secondary"
          onClick={handleSave}
          className={[classes.button, classes.floatingSaveButton].join(" ")}
        >
          <Save />
          Save
        </Fab>
      )}
    </div>
  );
};

const mapStateToProps = ({
  editReproduction,
  user,
}: {
  editReproduction: EditReproduction;
  user: User;
}) => {
  return { editReproduction, user };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveFields: (nextUrl: string, workflowStage: string) =>
      dispatch(saveFields({ nextUrl, workflowStage })),
    fetchReproduction: (id: number, mode: string) =>
      dispatch(fetchReproduction({ id, mode })),
    updateFields: (fields: any) => dispatch(updateFields(fields)),
  };
};

function Index(props: {
  path: string;
  editReproduction: EditReproduction;
  fetchReproduction: (id: number, mode: string) => void;
  location: string;
}) {
  const isPublishedView = location.pathname.split("/").includes("published");
  useEffect(() => {
    props.fetchReproduction(props.id, isPublishedView && "published");
  }, [isPublishedView]);
  const queryParams = queryString.parse(props.location.search);
  const currentStep = parseInt(queryParams.step);
  return props.editReproduction.notFound ? (
    <NotFound />
  ) : (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" align="center">
          Scoping
        </Typography>
        <Typography>
          Focusing on the declared paper from the previous stage, define the
          scope of your exercise by identifying the display items and claims on
          which you will focus in the later stages. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/scoping.html">
            here
          </ExternalLink>
          .
        </Typography>
        <HorizontalNonLinearStepper {...props} currentStep={currentStep} />
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
