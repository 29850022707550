import Typography from "@material-ui/core/Typography";
import React from "react";
import { ReproductionPackage } from "../../../../state/edit-reproduction";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../../css";
import { Button } from "gatsby-material-ui-components";
import { Add } from "@material-ui/icons";
import { EditReproductionPackage } from "../../scoping/components/edit-reproduction-package";
import ExternalLink from "../../../../components/external-link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: "auto",
    },
    title: {
      fontSize: 14,
    },
    noPackages: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    ...questionStyles(3, theme),
  })
);

function AddRevisedReproductionPackages({ formik, readOnly }) {
  const updateReproductionPackage = (index: number) => (value: any) => {
    const revisedReproductionPackages = [
      ...formik.values.revisedReproductionPackages,
    ];
    revisedReproductionPackages[index] = value;
    formik.setFieldValue(
      "revisedReproductionPackages",
      revisedReproductionPackages
    );
  };

  const deleteReproductionPackage = (index: number) => () => {
    const revisedReproductionPackages = [
      ...formik.values.revisedReproductionPackages,
    ];
    revisedReproductionPackages.splice(index, 1);
    formik.setFieldValue(
      "revisedReproductionPackages",
      revisedReproductionPackages
    );
  };

  const addReproductionPackage = () => {
    const revisedReproductionPackages = [
      ...formik.values.revisedReproductionPackages,
    ];
    revisedReproductionPackages.push(
      new ReproductionPackage({ stage: "revised", contentType: "data" })
    );
    formik.setFieldValue(
      "revisedReproductionPackages",
      revisedReproductionPackages
    );
  };

  const classes = useStyles();

  const editReproductionPackage = (
    reproductionPackage: ReproductionPackage,
    index: number
  ) => (
    <EditReproductionPackage
      key={index}
      reproductionPackage={reproductionPackage}
      updateReproductionPackage={updateReproductionPackage(index)}
      deleteReproductionPackage={deleteReproductionPackage(index)}
      readOnly={readOnly}
    />
  );

  return (
    <>
      <div className={classes.section}>
        <Typography
          variant="body1"
          component="div"
          className={classes.question}
          gutterBottom
        >
          Download the original reproduction package, upload it to a new
          repository, and record it below. This will be the{" "}
          <em>revised reproduction package</em> and will store any improvements
          that you will implement as part of this project. See detailed guidance{" "}
          <ExternalLink href="https://bitss.github.io/ACRE/scoping.html#setup-your-own-revised-reproduction-package">
            here
          </ExternalLink>
          .
        </Typography>
        {formik.values.revisedReproductionPackages.length === 0 ? (
          <Typography
            className={classes.noPackages}
            variant="body1"
            gutterBottom
          >
            You currently have no reproduction packages specified. Add one by
            clicking the <strong>Add revised reproduction package</strong>{" "}
            button.
          </Typography>
        ) : (
          <div>
            {formik.values.revisedReproductionPackages.map(
              editReproductionPackage
            )}
          </div>
        )}
        {readOnly ? (
          <></>
        ) : (
          <Button
            startIcon={<Add />}
            variant={"outlined"}
            color={"primary"}
            onClick={addReproductionPackage}
          >
            Add additional reproduction packages for data
          </Button>
        )}
      </div>
    </>
  );
}

export default AddRevisedReproductionPackages;
