import React from "react";
import { Router } from "@reach/router";
import Home from "../reproduction-pages/home";
import CreateEdit from "../reproduction-pages/edit";
import SelectPaper from "../reproduction-pages/edit/select-paper";
import Scoping from "../reproduction-pages/edit/scoping";
import Assessment from "../reproduction-pages/edit/assessment";
import Improvement from "../reproduction-pages/edit/improvement";
import Robustness from "../reproduction-pages/edit/robustness";
import Conclusion from "../reproduction-pages/edit/conclusion";
import Admin from "../reproduction-pages/admin";
import Search from "../reproduction-pages/search";
import PrivacySetting from "../reproduction-pages/edit/privacy-setting";
import MinimumReproduction from "../reproduction-pages/edit/minimum-reproduction";

export default function Reproductions() {
  return (
    <div>
      <Router>
        <Admin path="/reproductions/admin" />
        <Home path="/reproductions/home" />

        <CreateEdit path="/reproductions/:id/index" />
        <SelectPaper path="/reproductions/:id/select-paper" />
        <Scoping path="/reproductions/:id/scoping" />
        <Assessment path="/reproductions/:id/assessment" />
        <Improvement path="/reproductions/:id/improvement" />
        <Robustness path="/reproductions/:id/robustness" />
        <Conclusion path="/reproductions/:id/conclusion" />
        <PrivacySetting path="/reproductions/:id/privacy_setting" />
        <MinimumReproduction path="/reproductions/:id/minimum-reproduction" />

        <CreateEdit path="/reproductions/:id/published/index" />
        <SelectPaper path="/reproductions/:id/published/select-paper" />
        <Scoping path="/reproductions/:id/published/scoping" />
        <Assessment path="/reproductions/:id/published/assessment" />
        <Improvement path="/reproductions/:id/published/improvement" />
        <Robustness path="/reproductions/:id/published/robustness" />
        <Conclusion path="/reproductions/:id/published/conclusion" />

        <Search path="/reproductions/search" />
        <Search path="/reproductions/search/:canonical_paper_id" />
      </Router>
    </div>
  );
}
