import Typography from "@material-ui/core/Typography";
import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import {
  AnalyticDataRow,
  Assessment,
  DataSourceRow,
  EditAssessment,
} from "../../../state/edit-assessment";
import { List } from "immutable";
import {
  deleteAnalyticDataRow,
  deleteDataSourceRow,
  updateAnalyticDataRow,
  updateAssessmentFields,
  updateDataSourceRow,
} from "../../../actions/assessment.actions";
import { Button } from "gatsby-material-ui-components";
import { Add } from "@material-ui/icons";
import { DataGrid } from "../../../components/datagrid";
import { Box } from "@material-ui/core";
import { set } from "immutable";
import CSVDownloadButton from "../components/csv-download-button";
import CSVUploadButton from "../components/csv-upload-button";
import { camelizeKeys, decamelize, decamelizeKeys } from "humps";
import SaveMessage from "../../save-message";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otherField: {
      minWidth: 320,
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    ...questionStyles(1, theme),
  })
);

function DescribeInput({
  assessment,
  readOnly,
  updateAssessmentFields,
  updateDataSourceRow,
  deleteDataSourceRow,
  updateAnalyticDataRow,
  deleteAnalyticDataRow,
}: {
  assessment: Assessment;
  readOnly: boolean;
  updateAssessmentFields: Function;
  updateDataSourceRow: Function;
  deleteDataSourceRow: Function;
  updateAnalyticDataRow: Function;
  deleteAnalyticDataRow: Function;
}) {
  const classes = useStyles();

  const dataSourceColumns = [
    { headerName: "Data Source", field: "dataSource", flex: 1 },
    { headerName: "Page", field: "page", flex: 1 },
    { headerName: "Data Files", field: "dataFiles", flex: 2 },
    { headerName: "Location", field: "location", flex: 1 },
    { headerName: "Notes", field: "notes", flex: 2 },
    {
      headerName: "Provided",
      field: "provided",
      flex: 1,
      editable: false, //. This should only be editable via the checkbox
      cellRenderer: "checkboxRenderer",
      cellRendererParams: {
        checked: function (data, checked, rowIndex) {
          if (readOnly) return false;
          let updatedRow = set(data, "provided", checked);
          updateDataSourceRow({
            rowIndex,
            updatedRow,
          });
          return true;
        },
      },
    },
    {
      headerName: "Cited",
      field: "cited",
      flex: 1,
      editable: false,
      cellRenderer: "checkboxRenderer",
      cellRendererParams: {
        checked: function (data, checked, rowIndex) {
          if (readOnly) return false;
          let updatedRow = set(data, "cited", checked);
          updateDataSourceRow({
            rowIndex,
            updatedRow,
          });
          return true;
        },
      },
    },
  ];

  const dataSourceColumnsForCSV = dataSourceColumns.map((dsc) =>
    decamelize(dsc.field)
  );

  const addDataSourceRow = () => {
    updateAssessmentFields(
      assessment.set(
        "dataSourceRows",
        assessment.dataSourceRows.push(new DataSourceRow())
      )
    );
  };

  const uploadDataSourceRows = (csvRows) => {
    let dataSourceRows = List(
      csvRows.map(({ data }) => new DataSourceRow(camelizeKeys(data)))
    );
    updateAssessmentFields(assessment.set("dataSourceRows", dataSourceRows));
  };

  const analyticDataColumns = [
    { headerName: "Analytic Data", field: "analyticData", flex: 1 },
    { headerName: "Location", field: "location", flex: 2 },
    { headerName: "Description", field: "description", flex: 1 },
  ];

  const analyticDataColumnsForCSV = analyticDataColumns.map((adc) =>
    decamelize(adc.field)
  );

  const addAnalyticDataRow = () => {
    updateAssessmentFields(
      assessment.set(
        "analyticDataRows",
        assessment.analyticDataRows.push(new AnalyticDataRow())
      )
    );
  };

  const uploadAnalyticDataRows = (csvRows) => {
    let analyticDataRows = List(
      csvRows.map(({ data }) => new AnalyticDataRow(camelizeKeys(data)))
    );
    updateAssessmentFields(
      assessment.set("analyticDataRows", analyticDataRows)
    );
  };

  const handleOnError = (err) => {
    alert(
      `Error occurred while uploading CSV: ${err}.\nPlease download template and try again.`
    );
  };

  return (
    <>
      <SaveMessage />
      <Typography variant="body1" component="p" gutterBottom>
        In this section, you will first provide a detailed description of the
        reproduction package. You will then connect display items with their
        corresponding inputs, such as data and code files. With these elements
        in place, you can score each display item's reproducibility level and
        record various paper-level dimensions of reproducibility.
      </Typography>
      <Box mb={4}></Box>
      <div className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Data sources and raw data
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.question}
          gutterBottom
        >
          <p>
            First, find references to all <b>data sources</b> used in the
            analysis. A data source is usually described in narrative form in
            the body of the text, e.g., "for earnings in 2018, we use the
            Current Population Survey" – in this example, the data source is the
            2018 Current Population Survey. It is mentioned for the first time
            on page 1 of the appendix, so its location should be recorded as
            “A1” in the page column. Do this for all the data sources mentioned
            in the paper. Each row represents a unique data source.
          </p>
          <p>
            Second, map the data sources mentioned in the paper to the relevant{" "}
            <b>data files</b> in the reproduction package. Data sources are not
            the same as data files. Whenever a data source contains multiple
            files, enter them on the same cell, separated by a semicolon (;).
          </p>
          <p>
            In the <b>Location</b> column, record the folder location of each
            data file relative to the main folder of the original reproduction
            package or a new folder, if you need to create a new reproduction
            package from scratch.
          </p>
          <p>
            Finally, in the <b>Notes</b> column, add comments to help the
            interpretation of the data source you entered, including in cases
            when you've identified data sources that were not referenced in the
            paper, but were used in the analysis.
          </p>
        </Typography>
        {!readOnly && (
          <>
            <Button
              startIcon={<Add />}
              variant={"contained"}
              color={"primary"}
              onClick={addDataSourceRow}
            >
              Add a row
            </Button>
            <CSVUploadButton
              onDrop={uploadDataSourceRows}
              onError={handleOnError}
            />
          </>
        )}
        <CSVDownloadButton
          data={decamelizeKeys(assessment.dataSourceRows.toJS())}
          filename="data_sources"
          columns={dataSourceColumnsForCSV}
        />
        <DataGrid
          readOnly={readOnly}
          rows={assessment.dataSourceRows}
          columns={dataSourceColumns}
          updateRowAtIndex={updateDataSourceRow}
          deleteRowAtIndex={deleteDataSourceRow}
        />
        <Typography variant="h4" gutterBottom>
          Analytic data
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.question}
          gutterBottom
        >
          <p>
            First, identify all <b>analytic data files</b> in the reproduction
            package and record their names in the analytic_data column in the
            mapping tool. You will recognize analytic data files based on the
            documentation, their location folder, or if they are produced by a
            code file.
          </p>

          <p>
            Second, record the <b>location</b> of each analytic data file
            relative to the main folder of the reproduction package in the
            location column.
          </p>

          <p>
            Finally, provide a <b>short description</b> of each file in the
            description column. This may be difficult at first glance, but as
            you progress through the exercise, it will become easier to return
            to the mapping tool to add a one-line description of the main
            content in each file (e.g., all_waves.csv is described as "data for
            region-level analysis").
          </p>
        </Typography>
        {!readOnly && (
          <>
            <Button
              startIcon={<Add />}
              variant={"contained"}
              color={"primary"}
              onClick={addAnalyticDataRow}
            >
              Add a row
            </Button>
            <CSVUploadButton
              onDrop={uploadAnalyticDataRows}
              onError={handleOnError}
            />
          </>
        )}
        <CSVDownloadButton
          data={decamelizeKeys(assessment.analyticDataRows.toJS())}
          filename="analytic_data"
          columns={analyticDataColumnsForCSV}
        />
        <DataGrid
          readOnly={readOnly}
          rows={assessment.analyticDataRows}
          columns={analyticDataColumns}
          updateRowAtIndex={updateAnalyticDataRow}
          deleteRowAtIndex={deleteAnalyticDataRow}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({
  editAssessment: { assessment },
}: {
  editAssessment: EditAssessment;
}) => {
  return { assessment };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAssessmentFields: (fields: any) =>
      dispatch(updateAssessmentFields(fields)),
    updateDataSourceRow: (fields: any) => dispatch(updateDataSourceRow(fields)),
    deleteDataSourceRow: (index: any) => dispatch(deleteDataSourceRow(index)),
    updateAnalyticDataRow: (fields: any) =>
      dispatch(updateAnalyticDataRow(fields)),
    deleteAnalyticDataRow: (index: any) =>
      dispatch(deleteAnalyticDataRow(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DescribeInput);
