import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Container, Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { navigate } from "@reach/router";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { add, format, min, parse } from "date-fns";
import StyledLink from "../../components/styled-link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBar: {
      display: "flex",
      padding: 8,
      alignItems: "center",
    },
    button: {
      marginRight: theme.spacing(1),
    },
  })
);

function ActionBar({ handleUp }: { handleUp: Function }) {
  const classes = useStyles();
  return (
    <div className={classes.actionBar}>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUp}
          className={classes.button}
        >
          Return to Stages Overview
        </Button>
      </div>
    </div>
  );
}

function PrivacySettingPublicIdentified() {
  return (
    <Typography variant="body1" align="left">
      <em>Your reproduction's privacy setting:</em> Public, identified.
      <br />
      You are listed as the reproduction’s author, and other users can see the
      entire reproduction.
    </Typography>
  );
}

function PrivacySettingTemporaryAnonymous({
  reproductionId,
  embargoDate,
  maxEmbargoDate,
}) {
  function computeExtendEmbargoDate(embargoDate, maxEmbargoDate) {
    const dateFnsReferenceDate = new Date();
    const dateFormatString = "MM/dd/yyyy";

    return min([
      add(parse(embargoDate, dateFormatString, dateFnsReferenceDate), {
        years: 1,
      }),
      parse(maxEmbargoDate, dateFormatString, dateFnsReferenceDate),
    ]);
  }

  const extendEmbargoDate = computeExtendEmbargoDate(
    embargoDate,
    maxEmbargoDate
  );
  const extendEmbargoDateString = format(extendEmbargoDate, "MM/dd/yyyy");
  async function updateEmbargoDate() {
    await axios.patch(
      `/api/conclusions/${reproductionId}/extend_embargo_date`,
      { extend_embargo_date: extendEmbargoDateString }
    );
    const nextUrl = `/reproductions/${reproductionId}/index?step=0`;
    navigate(nextUrl);
  }

  return (
    <Typography variant="body1" align="left" component="div">
      <em>Your reproduction's privacy setting:</em> Temporary individual
      anonymous.
      <br />
      You have embargoed your identity and free-form narrative answers (e.g.,
      notes, descriptions, summaries, explanations, but not one-sentence claim
      summaries as inputted in Scoping).
      <p>
        <em>Your embargo date is:</em> {embargoDate}
      </p>
      <p>
        <strong>
          Please note, at the expiration of the embargo period, privacy settings
          are updated to “public, identified.”
        </strong>
      </p>
      {/* <div>
        {maxEmbargoDate != embargoDate ? (
          <>
            <p>
              You may extend your embargo date to the latest of:{" "}
              {maxEmbargoDate}
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={updateEmbargoDate}
            >
              Extend embargo to {extendEmbargoDateString}
            </Button>
          </>
        ) : (
          <p>
            Your embargo has already been extended to the maximum possible date.
          </p>
        )}
      </div> */}
    </Typography>
  );
}
function PrivacySettingClassAnonymous() {
  return (
    <Typography variant="body1" align="left">
      <em>Your reproduction's privacy setting:</em> Class anonymous.
      <br />
      You have concealed your identity and free-form narrative answers (e.g.,
      notes, descriptions, summaries, explanations, but not one-sentence claim
      summaries as inputted in Scoping) indefinitely because you conducted the
      reproduction as part of a class, project, or some other assignment for
      credit at the university/post-secondary level. Other users can see basic
      class identifiers (course name, year, institution, instructor’s name, and
      email address), categorical responses, and claim summaries.
    </Typography>
  );
}

function PrivacySettingDetails({ reproductionId, conclusionData }) {
  const privacySetting = conclusionData.privacy_setting;
  const submittedAt = format(
    new Date(conclusionData.submitted_at),
    "MMMM dd yyyy"
  );

  let privacySettingSummary;

  if (privacySetting == "public_identified") {
    privacySettingSummary = <PrivacySettingPublicIdentified />;
  } else if (privacySetting == "temporary_anonymous") {
    privacySettingSummary = (
      <PrivacySettingTemporaryAnonymous
        reproductionId={reproductionId}
        embargoDate={conclusionData.temporary_anonymous_embargo_date}
        maxEmbargoDate={conclusionData.max_embargo_date}
      />
    );
  } else if (privacySetting == "class_anonymous") {
    privacySettingSummary = <PrivacySettingClassAnonymous />;
  } else {
    privacySettingSummary = (
      <div>
        There is an error with your reproduction's privacy details. Please
        contact acre@berkeley.edu to fix.
      </div>
    );
  }

  return (
    <div>
      <Typography variant="body1" align="left">
        <em>Your reproduction was submitted on:</em> {submittedAt}
        <br />
        <StyledLink
          href={`/reproductions/${reproductionId}/published/index`}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/reproductions/${reproductionId}/published/index`);
          }}
        >
          Public link
        </StyledLink>
      </Typography>
      {privacySettingSummary}
    </div>
  );
}

function PrivacySetting(props: { id: number; path: string; location: string }) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [conclusionData, setConclusionData] = React.useState(null);

  const handleUp = () => {
    const nextUrl = `/reproductions/${props.id}/index`;
    navigate(nextUrl);
  };

  const fetchConclusion = async () => {
    setLoading(true);
    axios.get(`/api/conclusions/${props.id}.json`).then(function (response) {
      setConclusionData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchConclusion();
  }, []);

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" align="center">
          Privacy Setting
        </Typography>
        <Typography>
          View your reproduction's public link and privacy setting here.
        </Typography>
        <ActionBar handleUp={handleUp} />
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <PrivacySettingDetails
            reproductionId={props.id}
            conclusionData={conclusionData}
          />
        )}
      </Box>
    </Container>
  );
}

export default PrivacySetting;
