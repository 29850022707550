import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Fab,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Save } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { navigate } from "@reach/router";
import queryString from "query-string";
import Conclusion from "./conclusion";
import axios from "axios";
import { camelizeKeys } from "humps";
import { useFormik } from "formik";
import * as yup from "yup";
import { add, format, parse } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightGroup: {
      marginLeft: "auto",
      display: "inline-flex",
    },
    actionBar: {
      display: "flex",
      padding: 8,
      alignItems: "center",
    },
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    floatingSaveButton: {
      position: "-webkit-sticky",
      position: "sticky",
      left: "90%",
      bottom: "40px",
    },
  })
);

function ActionBar({ handleUp }: { handleUp: Function }) {
  const classes = useStyles();
  return (
    <div className={classes.actionBar}>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUp}
          className={classes.button}
        >
          Return to Stages Overview
        </Button>
      </div>
    </div>
  );
}

const validationSchema = yup.object().shape({
  privacySetting: yup.string().required("Privacy setting is required"),
  classAnonymousCourseName: yup.string().when("privacySetting", {
    is: "class_anonymous",
    then: yup.string().required("Course name is required for class anonymous"),
  }),
  classAnonymousCourseYear: yup.string().when("privacySetting", {
    is: "class_anonymous",
    then: yup.string().required("Course year is required for class anonymous"),
  }),
  classAnonymousInstitution: yup.string().when("privacySetting", {
    is: "class_anonymous",
    then: yup.string().required("Institution is required for class anonymous"),
  }),
  classAnonymousInstructorName: yup.string().when("privacySetting", {
    is: "class_anonymous",
    then: yup
      .string()
      .required("Instructor/supervisor name is required for class anonymous"),
  }),
  classAnonymousInstructorEmail: yup.string().when("privacySetting", {
    is: "class_anonymous",
    then: yup
      .string()
      .required("Instructor/supervisor email is required for class anonymous"),
  }),
  temporaryAnonymousEmbargoDate: yup
    .date()
    .max(add(new Date(), { years: 4 }), "Date must be within 4 years"),
});

const HorizontalNonLinearStepper: React.SFC<any> = (props) => {
  const classes = useStyles();

  const handleSave = () => {
    saveConclusion(formik.values);
  };

  const handleUp = () => {
    const nextUrl = `/reproductions/${props.id}/index?step=4`;
    if (readOnly) {
      navigate(nextUrl);
    } else {
      saveConclusion(formik.values).then(() => {
        navigate(nextUrl);
      });
    }
  };

  const [saving, setSaving] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [readOnly, setReadOnly] = React.useState<boolean>(true);
  const [formValues, setFormValues] = React.useState({
    contactAuthors: [],
    contactAuthorsOther: "",
    requestingAdditionalMaterialResponse: [],
    requestingAdditionalMaterialResponseNotReadyDate: null,
    requestingAdditionalMaterialResponseOther: "",
    requestingAdditionalMaterialAuthorsAvailable: "",
    requestingAdditionalMaterialAuthorsAvailableNo: "",
    clarificationResponse: [],
    clarificationResponseMissingStep: "",
    clarificationResponseAlreadyExistingExplanation: "",
    clarificationResponseOther: "",
    clarificationAuthorsAvailable: "",
    clarificationAuthorsAvailableNo: "",
    feedbackResponse: [],
    feedbackResponseSummary: "",
    feedbackAuthorsAvailable: "",
    otherComments: "",
    privacySetting: "",
    classAnonymousCourseName: "",
    classAnonymousCourseYear: "",
    classAnonymousInstitution: "",
    classAnonymousInstructorName: "",
    classAnonymousInstructorEmail: "",
    temporaryAnonymousEmbargoDate: format(
      add(new Date(), { years: 1 }),
      "MM/dd/yyyy"
    ),
    newRevisedReproductionPackages: [{ name: "", url: "" }],
    revisedReproductionPackages: [],
  });

  useEffect(() => {
    if (!readOnly) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [readOnly]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const isPublishedView = location.pathname.split("/").includes("published");

  const fetchConclusion = async () => {
    setLoading(true);
    const queryParams = isPublishedView ? "?mode=published" : "";

    axios
      .get(`/api/conclusions/${props.id}.json${queryParams}`)
      .then(function (response) {
        setFormValues({
          ...formValues,
          ...camelizeKeys(response.data),
        });

        setReadOnly(
          "submitted" === response.data.workflow_stage ||
            !response.data.is_author ||
            response.data.shareable_link
        );

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchConclusion();
  }, []);

  const saveConclusion = async (values) => {
    setSaving(true);
    await axios.patch(`/api/conclusions/${props.id}`, values);
    setSaving(false);
  };

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await saveConclusion({
        ...values,
        workflowStage: "submit",
      });

      navigate(`/reproductions/home`);
    },
  });

  return (
    <div className={classes.root}>
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          opacity: saving ? 1 : 0,
          transition: "opacity 300ms",
        }}
      >
        <LinearProgress />
      </div>
      <ActionBar handleUp={handleUp} />
      <Conclusion loading={loading} formik={formik} readOnly={readOnly} />
      {!readOnly && (
        <Fab
          variant="extended"
          color="secondary"
          onClick={handleSave}
          className={[classes.button, classes.floatingSaveButton].join(" ")}
        >
          <Save />
          Save
        </Fab>
      )}
    </div>
  );
};

function Index(props: { id: number; path: string; location: string }) {
  const queryParams = queryString.parse(props.location.search);
  const currentStep = parseInt(queryParams.step);
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" align="center">
          Submit your reproduction
        </Typography>
        <Typography>
          To submit your reproduction, please record the outcome of your
          interactions with your declared paper's original authors and choose
          your reproduction's privacy settings. Once you have clicked "Submit",
          the reproduction will no longer be editable.
        </Typography>
        <HorizontalNonLinearStepper {...props} currentStep={currentStep} />
      </Box>
    </Container>
  );
}

export default Index;
