import Typography from "@material-ui/core/Typography";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import { add } from "date-fns";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Button } from "gatsby-material-ui-components";
import QuestionWithTextField from "../components/question-with-text-field";
import MultipleSelectArray from "../components/multiple-select-array";
import { KeyboardDatePicker } from "@material-ui/pickers";
import QuestionWithRadioGroup from "../components/question-with-radio-group";
import ExternalLink from "../../../components/external-link";

const useStyles = (sectionNumber) =>
  makeStyles((theme: Theme) =>
    createStyles({
      otherField: {
        minWidth: 320,
      },
      formField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: 600,
      },
      displayItem: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      hidden: {
        display: "none",
      },
      readOnly: {
        padding: theme.spacing(1),
        border: "1px solid",
        backgroundColor: "#f1f1f1",
      },
      reproductionPackage: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: 600,
      },
      ...questionStyles(sectionNumber, theme),
    })
  );

const useQuestionNumberStyles = (sectionNumber) =>
  makeStyles((theme: Theme) =>
    createStyles({
      ...questionStyles(sectionNumber, theme),
    })
  );

function ConclusionReproductionPackage({
  reproductionField,
  reproductionPackage,
  index,
  classes,
  formik,
  readOnly,
}) {
  return readOnly ? (
    <Card className={[classes.reproductionPackage, classes.readOnly].join(" ")}>
      <Typography component="div" gutterBottom>
        <p>
          <strong>Package name</strong>: {reproductionPackage.name}
        </p>
        <p>
          <strong>Package URL</strong>: {reproductionPackage.url}
        </p>
      </Typography>
    </Card>
  ) : (
    <Card className={classes.reproductionPackage}>
      <CardContent>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.formField}
          label="Contents of reproduction package"
          fullWidth
          placeholder="e.g. Main code repository with data"
          name={`${reproductionField}[${index}].name`}
          value={reproductionPackage.name}
          onChange={formik.handleChange}
        />
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.formField}
          label="URL"
          fullWidth
          placeholder="e.g. https://github.com/BITSS/ACRE"
          name={`${reproductionField}[${index}].url`}
          value={reproductionPackage.url}
          onChange={formik.handleChange}
        />
      </CardContent>
    </Card>
  );
}

function Conclusion({
  loading,
  formik,
  readOnly,
}: {
  loading: boolean;
  formik: any;
  readOnly: boolean;
}) {
  const classes = useStyles("1")();
  const classes_1_2 = useQuestionNumberStyles("1.2")();
  const classes_1_3 = useQuestionNumberStyles("1.3")();
  const classes_1_4 = useQuestionNumberStyles("1.4")();
  const classes_1_5 = useQuestionNumberStyles("1.5")();
  const classes_1_6 = useQuestionNumberStyles("1.6")();

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <div className={classes.section}>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              className={classes.question}
              variant="body1"
              component="div"
              gutterBottom
            >
              Did you contact the authors to request any missing material,
              clarification, or feedback during the Scoping, Assessment,
              Improvement, or Robustness stages? (If you only contacted the
              original authors to request a reproduction package at the Select a
              Paper stage, select no)
            </Typography>
            <FormControl component="fieldset">
              <MultipleSelectArray
                classes={{
                  label: classes.otherField,
                }}
                labels={[
                  "No, I did not contact the authors.",
                  "Yes, requesting missing materials",
                  "Yes, asking for clarification(s) regarding the original reproduction package",
                  "Yes, asking for feedback regarding my reproduction results",
                  <TextField
                    InputLabelProps={{
                      shrink: formik.values.contactAuthors.includes("other"),
                    }}
                    fullWidth
                    label="Other (explain briefly):"
                    variant="outlined"
                    name="contactAuthorsOther"
                    disabled={
                      !formik.values.contactAuthors.includes("other") ||
                      readOnly
                    }
                    value={formik.values.contactAuthorsOther}
                    onChange={formik.handleChange}
                  />,
                ]}
                values={[
                  "no",
                  "yes_requesting_missing_materials",
                  "yes_original_clarification",
                  "yes_requesting_feedback",
                  "other",
                ]}
                name="contactAuthors"
                selectedValues={formik.values.contactAuthors}
                onChange={formik.handleChange}
                disabled={readOnly}
              />
            </FormControl>
            {formik.values.contactAuthors.includes(
              "yes_requesting_missing_materials"
            ) && (
              <div className={classes.section}>
                <Typography
                  className={classes_1_2.question}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  How did the authors respond to the request for missing
                  materials? Select all that apply.
                </Typography>
                <MultipleSelectArray
                  classes={{
                    label: classes.otherField,
                  }}
                  labels={[
                    "Provided all of the missing materials.",
                    "Provided some of the missing materials",
                    "Declined to share the missing materials, citing legal or ethical reasons.",
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent={"flex-start"}
                    >
                      <Box mr={1}>
                        <Typography>
                          Declined to share the missing materials, citing not
                          ready to share. Record date when you estimate that the
                          authors may be ready to share the missing materials:
                        </Typography>
                      </Box>
                      <KeyboardDatePicker
                        disabled={
                          !formik.values.requestingAdditionalMaterialResponse.includes(
                            "declined_not_ready"
                          ) || readOnly
                        }
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        placeholder="MM/DD/YYYY"
                        value={
                          formik.values
                            .requestingAdditionalMaterialResponseNotReadyDate
                        }
                        onChange={(_date, formattedDate) => {
                          formik.setFieldTouched(
                            "requestingAdditionalMaterialResponseNotReadyDate",
                            true
                          );
                          formik.setFieldValue(
                            "requestingAdditionalMaterialResponseNotReadyDate",
                            formattedDate
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label":
                            "Declined to share the missing materials",
                        }}
                      />
                    </Box>,
                    "Author(s) state that they no longer have access to the data.",
                    "Shared detailed instructions on how to access the data (for restricted access only).",
                    "Did not respond after two weeks or more.",
                    <TextField
                      InputLabelProps={{
                        shrink: formik.values.requestingAdditionalMaterialResponse.includes(
                          "other"
                        ),
                      }}
                      fullWidth
                      label="Other (explain briefly)"
                      variant="outlined"
                      name="requestingAdditionalMaterialResponseOther"
                      disabled={
                        !formik.values.requestingAdditionalMaterialResponse.includes(
                          "other"
                        ) || readOnly
                      }
                      value={
                        formik.values.requestingAdditionalMaterialResponseOther
                      }
                      onChange={formik.handleChange}
                    />,
                  ]}
                  values={[
                    "provided_all_missing_materials",
                    "provided_some_missing_materials",
                    "declined_legal_ethical",
                    "declined_not_ready",
                    "no_longer_have_access",
                    "restricted_access_details",
                    "did_not_respond",
                    "other",
                  ]}
                  name="requestingAdditionalMaterialResponse"
                  selectedValues={
                    formik.values.requestingAdditionalMaterialResponse
                  }
                  onChange={formik.handleChange}
                  disabled={readOnly}
                />
                <Typography
                  className={classes_1_2.question}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  Are the authors available for further questions from SSRP
                  reproducers regarding the missing materials?
                </Typography>
                <FormControl component="fieldset" disabled={readOnly}>
                  <RadioGroup
                    aria-label="Are the authors available for further questions from SSRP reproducers regarding the missing materials?"
                    name="requestingAdditionalMaterialAuthorsAvailable"
                    value={
                      formik.values.requestingAdditionalMaterialAuthorsAvailable
                    }
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="not_sure"
                      control={<Radio />}
                      label="Not sure"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      classes={{
                        label: classes.otherField,
                      }}
                      label={
                        <>
                          No - briefly describe all the missing materials the
                          authors would not like to be asked about again in the
                          future:
                          <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="requestingAdditionalMaterialAuthorsAvailableNo"
                            value={
                              formik.values
                                .requestingAdditionalMaterialAuthorsAvailableNo
                            }
                            onChange={formik.handleChange}
                            disabled={
                              !formik.values.requestingAdditionalMaterialAuthorsAvailable.includes(
                                "no"
                              ) || readOnly
                            }
                          />
                        </>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {formik.values.contactAuthors.includes(
              "yes_original_clarification"
            ) && (
              <div className={classes.section}>
                <Typography
                  className={classes_1_3.question}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  How did the authors respond to questions about the original
                  reproduction package? Select all that apply.
                </Typography>
                <MultipleSelectArray
                  classes={{
                    label: classes.otherField,
                  }}
                  labels={[
                    <Box mt={2} border={1}>
                      <Typography component="div">
                        Explained the missing step in the reproduction.
                        <p>
                          Summarize the authors’ explanation and include a link
                          to your revised reproduction package (if you
                          implemented the solution at the Improvements stage).
                        </p>
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        name="clarificationResponseMissingStep"
                        disabled={
                          !formik.values.clarificationResponse.includes(
                            "explained_missing_step"
                          ) || readOnly
                        }
                        value={formik.values.clarificationResponseMissingStep}
                        onChange={formik.handleChange}
                      />
                    </Box>,
                    <Box mt={2} border={1}>
                      <Typography component="div">
                        Pointed out to an already existing explanation in the
                        original reproduction package.
                        <p>
                          Describe the section in the reproduction package cited
                          by the authors (file, line), and add a link to your
                          revised reproduction package if you implemented a more
                          precise explanation in the improvements stage.
                        </p>
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        name="clarificationResponseAlreadyExistingExplanation"
                        disabled={
                          !formik.values.clarificationResponse.includes(
                            "already_existing_explanation"
                          ) || readOnly
                        }
                        value={
                          formik.values
                            .clarificationResponseAlreadyExistingExplanation
                        }
                        onChange={formik.handleChange}
                      />
                    </Box>,
                    "Did not respond after two weeks or more.",
                    <TextField
                      InputLabelProps={{
                        shrink: formik.values.clarificationResponse.includes(
                          "other"
                        ),
                      }}
                      fullWidth
                      label="Other (explain briefly)"
                      variant="outlined"
                      name="clarificationResponseOther"
                      disabled={
                        !formik.values.clarificationResponse.includes(
                          "other"
                        ) || readOnly
                      }
                      value={formik.values.clarificationResponseOther}
                      onChange={formik.handleChange}
                    />,
                  ]}
                  values={[
                    "explained_missing_step",
                    "already_existing_explanation",
                    "did_not_respond",
                    "other",
                  ]}
                  name="clarificationResponse"
                  selectedValues={formik.values.clarificationResponse}
                  onChange={formik.handleChange}
                  disabled={readOnly}
                />
                <Typography
                  className={classes_1_3.question}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  Are the authors available for further questions from SSRP
                  reproducers regarding your request for clarifications?
                </Typography>
                <FormControl component="fieldset" disabled={readOnly}>
                  <RadioGroup
                    aria-label="Are the authors available for further questions from SSRP reproducers regarding your request for clarifications?"
                    name="clarificationAuthorsAvailable"
                    value={formik.values.clarificationAuthorsAvailable}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="not_sure"
                      control={<Radio />}
                      label="Not sure"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      classes={{
                        label: classes.otherField,
                      }}
                      label={
                        <>
                          No - provide a one-sentence summary of the requested
                          explanation
                          <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            name="clarificationAuthorsAvailableNo"
                            value={
                              formik.values.clarificationAuthorsAvailableNo
                            }
                            onChange={formik.handleChange}
                            disabled={
                              !formik.values.clarificationAuthorsAvailable.includes(
                                "no"
                              ) || readOnly
                            }
                          />
                        </>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {formik.values.contactAuthors.includes(
              "yes_requesting_feedback"
            ) && (
              <div className={classes.section}>
                <Typography
                  className={classes_1_4.question}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  What stage was the focus of the author's feedback regarding
                  your reproduction results? Select all that apply.
                </Typography>
                <MultipleSelectArray
                  classes={{
                    label: classes.otherField,
                  }}
                  labels={[
                    "Scoping",
                    "Assessment",
                    "Improvements",
                    "Robustness",
                    "The authors didn’t respond after two weeks or more.",
                  ]}
                  values={[
                    "scoping",
                    "assessment",
                    "improvements",
                    "robustness",
                    "did_not_respond",
                  ]}
                  name="feedbackResponse"
                  selectedValues={formik.values.feedbackResponse}
                  onChange={formik.handleChange}
                  disabled={readOnly}
                />
                {(formik.values.feedbackResponse.includes("scoping") ||
                  formik.values.feedbackResponse.includes("assessment") ||
                  formik.values.feedbackResponse.includes("improvements") ||
                  formik.values.feedbackResponse.includes("robustness")) && (
                  <QuestionWithTextField
                    classes={classes}
                    multiline
                    variant="outlined"
                    fullWidth
                    name="feedbackResponseSummary"
                    value={formik.values.feedbackResponseSummary}
                    onChange={formik.handleChange}
                    readOnly={readOnly}
                  >
                    Briefly summarize the author's feedback here.
                  </QuestionWithTextField>
                )}
                <Typography
                  className={classes_1_4.question}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  Are the authors available to provide feedback on other
                  reproductions of this paper in the future?
                </Typography>
                <FormControl component="fieldset" disabled={readOnly}>
                  <RadioGroup
                    aria-label="Are the authors available for further questions from SSRP reproducers regarding your request for clarifications?"
                    name="feedbackAuthorsAvailable"
                    value={formik.values.feedbackAuthorsAvailable}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="not_sure"
                      control={<Radio />}
                      label="Not sure"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      classes={{
                        label: classes.otherField,
                      }}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {formik.values.contactAuthors.length > 0 && (
              <>
                <Box mt={4}>
                  <Typography variant="h4" gutterBottom>
                    Update your revised reproduction package
                  </Typography>
                  <div className={classes.section}>
                    {formik.values.revisedReproductionPackages.length > 0 ? (
                      <>
                        <Typography
                          variant="body1"
                          component="div"
                          className={classes_1_5.question}
                          gutterBottom
                        >
                          Before publishing this reproduction, please update
                          your revised reproduction packages below with any
                          improvements or materials that you added during your
                          reproduction.
                        </Typography>
                        <div>
                          {formik.values.revisedReproductionPackages.map(
                            (reproductionPackage, index) => (
                              <ConclusionReproductionPackage
                                reproductionPackage={reproductionPackage}
                                key={index}
                                index={index}
                                classes={classes}
                                readOnly={true}
                              />
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="body1"
                          component="div"
                          className={classes_1_5.question}
                          gutterBottom
                        >
                          Before publishing this reproduction, download the
                          original reproduction package, upload it to a new
                          repository, and record it below. This will be the{" "}
                          <em>revised reproduction package</em> and you should
                          store any improvements that you implemented as part of
                          this project. See detailed guidance{" "}
                          <ExternalLink href="https://bitss.github.io/ACRE/scoping.html#setup-your-own-revised-reproduction-package">
                            here
                          </ExternalLink>
                          .
                        </Typography>
                        <div>
                          {formik.values.newRevisedReproductionPackages.map(
                            (reproductionPackage, index) => (
                              <ConclusionReproductionPackage
                                reproductionField={
                                  "newRevisedReproductionPackages"
                                }
                                reproductionPackage={reproductionPackage}
                                index={index}
                                classes={classes}
                                formik={formik}
                                readOnly={readOnly}
                              />
                            )
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Box>
                <Box mt={4}>
                  <Typography variant="h4" gutterBottom>
                    Other comments
                  </Typography>
                  <QuestionWithTextField
                    classes={classes}
                    multiline
                    variant="outlined"
                    fullWidth
                    name="otherComments"
                    value={formik.values.otherComments}
                    onChange={formik.handleChange}
                    readOnly={readOnly}
                  >
                    Do you have any other comments about your responses?
                  </QuestionWithTextField>
                </Box>
                <Box mt={4}>
                  <Typography variant="h4" gutterBottom>
                    Privacy Settings
                  </Typography>
                  <div className={classes.section}>
                    <QuestionWithRadioGroup
                      classes={classes}
                      questionClass={classes_1_6.question}
                      name="privacySetting"
                      readOnly={readOnly}
                      value={formik.values.privacySetting}
                      error={
                        formik.touched.privacySetting &&
                        Boolean(formik.errors.privacySetting)
                      }
                      helperText={
                        formik.touched.privacySetting &&
                        formik.errors.privacySetting
                      }
                      onChange={formik.handleChange}
                      valuesAndLabels={[
                        {
                          value: "public_identified",
                          label: (
                            <div>
                              <h3>Public, identified</h3>
                              <p>
                                This is the default privacy setting. You are
                                listed as the reproduction’s author, and other
                                users can see the entire reproduction.
                              </p>
                            </div>
                          ),
                        },
                        {
                          value: "class_anonymous",
                          label: (
                            <div>
                              <h3>Class anonymous</h3>
                              <p>
                                Allows you to conceal your identity and
                                free-form narrative answers (e.g., notes,
                                descriptions, summaries, explanations, but not
                                one-sentence claim summaries as inputted in
                                Scoping) indefinitely if you conducted the
                                reproduction as part of a class, project, or
                                some other assignment for credit at the
                                university/post-secondary level. Other users can
                                see basic class identifiers (course name, year,
                                institution, instructor’s name, and email
                                address), categorical responses, and claim
                                summaries.
                              </p>
                            </div>
                          ),
                        },
                        {
                          value: "temporary_anonymous",
                          label: (
                            <div>
                              <h3>Temporary individual anonymous</h3>
                              <p>
                                Allows you to embargo your identity and
                                free-form narrative answers (e.g., notes,
                                descriptions, summaries, explanations, but not
                                one-sentence claim summaries as inputted in
                                Scoping) for up to 4 years after submitting. At
                                the expiration of the embargo period, privacy
                                settings are updated to “public, identified.”
                              </p>
                            </div>
                          ),
                        },
                      ]}
                    >
                      Please select privacy settings for your reproduction. If
                      you choose “class anonymous” or “temporary individual
                      anonymous,” you can update the privacy settings to
                      “public, identified” at any time.
                    </QuestionWithRadioGroup>
                    {formik.values.privacySetting == "class_anonymous" && (
                      <>
                        <Typography
                          className={classes_1_6.question}
                          variant="body1"
                          component="div"
                          gutterBottom
                        >
                          For <b>Class anonymous</b>, please enter the following
                          information about your class:
                        </Typography>
                        <>
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.formField}
                            disabled={readOnly}
                            label="Course name"
                            fullWidth
                            placeholder=""
                            value={formik.values.classAnonymousCourseName}
                            name="classAnonymousCourseName"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.classAnonymousCourseName &&
                              Boolean(formik.errors.classAnonymousCourseName)
                            }
                            helperText={
                              formik.touched.classAnonymousCourseName &&
                              formik.errors.classAnonymousCourseName
                            }
                          />
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.formField}
                            disabled={readOnly}
                            label="Course year"
                            fullWidth
                            placeholder=""
                            value={formik.values.classAnonymousCourseYear}
                            name="classAnonymousCourseYear"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.classAnonymousCourseYear &&
                              Boolean(formik.errors.classAnonymousCourseYear)
                            }
                            helperText={
                              formik.touched.classAnonymousCourseYear &&
                              formik.errors.classAnonymousCourseYear
                            }
                          />
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Institution"
                            className={classes.formField}
                            disabled={readOnly}
                            fullWidth
                            placeholder=""
                            value={formik.values.classAnonymousInstitution}
                            name="classAnonymousInstitution"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.classAnonymousInstitution &&
                              Boolean(formik.errors.classAnonymousInstitution)
                            }
                            helperText={
                              formik.touched.classAnonymousInstitution &&
                              formik.errors.classAnonymousInstitution
                            }
                          />
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Name of instructor/supervisor"
                            className={classes.formField}
                            disabled={readOnly}
                            fullWidth
                            placeholder=""
                            value={formik.values.classAnonymousInstructorName}
                            name="classAnonymousInstructorName"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.classAnonymousInstructorName &&
                              Boolean(
                                formik.errors.classAnonymousInstructorName
                              )
                            }
                            helperText={
                              formik.touched.classAnonymousInstructorName &&
                              formik.errors.classAnonymousInstructorName
                            }
                          />
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.formField}
                            disabled={readOnly}
                            label="Email of instructor/supervisor"
                            fullWidth
                            placeholder=""
                            value={formik.values.classAnonymousInstructorEmail}
                            name="classAnonymousInstructorEmail"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.classAnonymousInstructorEmail &&
                              Boolean(
                                formik.errors.classAnonymousInstructorEmail
                              )
                            }
                            helperText={
                              formik.touched.classAnonymousInstructorEmail &&
                              formik.errors.classAnonymousInstructorEmail
                            }
                          />
                        </>
                      </>
                    )}
                    {formik.values.privacySetting == "temporary_anonymous" && (
                      <div>
                        <Typography
                          className={classes_1_6.question}
                          variant="body1"
                          component="div"
                          gutterBottom
                        >
                          For <b>Temporary anonymous</b>, please enter the date
                          when you would like the privacy settings for this
                          reproduction to update to <b>Public, identified</b>.
                        </Typography>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          placeholder="MM/DD/YYYY"
                          value={formik.values.temporaryAnonymousEmbargoDate}
                          minDate={new Date()}
                          maxDate={add(new Date(), { years: 4 })}
                          disabled={readOnly}
                          error={
                            formik.touched.temporaryAnonymousEmbargoDate &&
                            Boolean(formik.errors.temporaryAnonymousEmbargoDate)
                          }
                          helperText={
                            formik.touched.temporaryAnonymousEmbargoDate &&
                            formik.errors.temporaryAnonymousEmbargoDate
                          }
                          onChange={(_date, formattedDate) => {
                            formik.setFieldTouched(
                              "temporaryAnonymousEmbargoDate",
                              true
                            );
                            formik.setFieldValue(
                              "temporaryAnonymousEmbargoDate",
                              formattedDate
                            );
                          }}
                          KeyboardButtonProps={{
                            "aria-label":
                              "Date when privacy settings for this reproduction will update to Public, identified",
                          }}
                        />
                      </div>
                    )}
                    {!readOnly && (
                      <Box mt={4}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Box>
                    )}
                  </div>
                </Box>
              </>
            )}
          </form>
        </div>
      )}
    </>
  );
}

export default Conclusion;
