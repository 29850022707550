import Typography from "@material-ui/core/Typography";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
  })
);

export function QuestionWithTrueFalseRadio(props: {
  classes: ClassNameMap<"question">;
  questionClass?: any;
  value: null;
  onChange: (e: any) => void;
  questionText: string;
  readOnly: boolean;
  disabled: boolean;
}) {
  const componentClasses = useStyles();
  return props.readOnly ? (
    <>
      <Typography
        variant="body1"
        component="div"
        className={props.questionClass || props.classes.question}
        gutterBottom
      >
        {props.questionText} {props.children}
      </Typography>
      <Typography className={componentClasses.readOnly}>
        {((value) => {
          switch (value) {
            case "true":
              return "Yes";
            case "false":
              return "No";
            default:
              return "";
          }
        })(props.value)}
      </Typography>
    </>
  ) : (
    <>
      <Typography
        variant="body1"
        component="div"
        className={(props.disabled
          ? [
              props.classes.typographyDisabled,
              props.questionClass || props.classes.question,
            ]
          : [props.questionClass || props.classes.question]
        ).join(" ")}
        gutterBottom
      >
        {props.questionText} {props.children}
      </Typography>
      <FormControl component="fieldset" disabled={props.disabled}>
        <RadioGroup
          aria-label={props.questionText}
          value={props.value}
          onChange={props.onChange}
        >
          <FormControlLabel value="true" control={<Radio />} label="Yes" />
          <FormControlLabel value="false" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </>
  );
}
