import Typography from "@material-ui/core/Typography";
import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { questionStyles } from "../css";
import { Assessment, EditAssessment } from "../../../state/edit-assessment";
import { updateAssessmentFields } from "../../../actions/assessment.actions";
import { Box } from "@material-ui/core";
import MultipleSelect from "../components/multiple-select";
import TextField from "@material-ui/core/TextField";
import { Button } from "gatsby-material-ui-components";
import { QuestionWithTrueFalseRadio } from "../components/question-with-true-false-radio";
import ExternalLink from "../../../components/external-link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otherField: {
      flexGrow: 1,
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    displayItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
    correctionsTable: {
      borderCollapse: "collapse",
      "& td": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
    },
    ...questionStyles(4, theme),
  })
);

function MasterScript({
  assessment,
  readOnly,
  updateAssessmentFields,
}: {
  assessment: Assessment;
  readOnly: boolean;
  updateAssessmentFields: Function;
}) {
  const classes = useStyles();

  const masterFileExists = assessment.masterFileExists === "true";
  const masterFileOneClick = assessment.masterFileOneClick !== "false";
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Master script
      </Typography>
      <div className={classes.section}>
        <Typography variant="body1" component="div" gutterBottom>
          <p>
            A master script is a code script that runs in defined order all of
            the code files necessary to reproduce the paper. Use this section to
            assess its reproducibility.
          </p>
        </Typography>
        <QuestionWithTrueFalseRadio
          classes={classes}
          questionClass={classes.question}
          questionText="Is there a master file that runs all components of the reproduction package in their required order?"
          value={assessment.masterFileExists}
          onChange={(e: any) => {
            updateAssessmentFields({
              masterFileExists: e.target.value,
            });
          }}
          readOnly={readOnly}
        />
        <QuestionWithTrueFalseRadio
          classes={classes}
          questionClass={classes.question}
          questionText="Did the master file run with one click?"
          value={assessment.masterFileOneClick}
          onChange={(e: any) => {
            updateAssessmentFields({
              masterFileOneClick: e.target.value,
            });
          }}
          readOnly={readOnly}
          disabled={!masterFileExists}
        />
        <Typography
          variant="body1"
          component="div"
          className={(masterFileOneClick
            ? [classes.typographyDisabled, classes.question]
            : [classes.question]
          ).join(" ")}
          gutterBottom
        >
          Why didn't the master file run with one click?
        </Typography>
        {readOnly ? (
          assessment.masterFileNoOneClickReasons.map((reason) => (
            <div className={classes.readOnly} key={reason}>
              {reason}
              {reason ===
                "I don't have the necessary software or version of the software installed. Specify the required software and the required version." && (
                <p>{assessment.masterFileNecessarySoftware}</p>
              )}
              {reason === "Other errors. Explain." && (
                <p>{assessment.masterFileOtherErrors}</p>
              )}
            </div>
          ))
        ) : (
          <MultipleSelect
            disabled={masterFileOneClick}
            classes={{
              label: classes.otherField,
            }}
            labels={[
              <div>
                I don't have the necessary software or version of the software
                installed. Specify the required software and the required
                version.
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  disabled={
                    !assessment.masterFileNoOneClickReasons.contains(
                      "I don't have the necessary software or version of the software installed. Specify the required software and the required version."
                    )
                  }
                  value={assessment.masterFileNecessarySoftware}
                  onChange={(e: any) => {
                    updateAssessmentFields({
                      masterFileNecessarySoftware: e.target.value,
                    });
                  }}
                />
              </div>,
              "Files are missing.",
              "There are path/directory errors.",
              "I need to install additional packages, libraries, or updates.",
              <div>
                Other errors. Explain.
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  disabled={
                    !assessment.masterFileNoOneClickReasons.contains(
                      "Other errors. Explain."
                    )
                  }
                  value={assessment.masterFileOtherErrors}
                  onChange={(e: any) => {
                    updateAssessmentFields({
                      masterFileOtherErrors: e.target.value,
                    });
                  }}
                />
              </div>,
            ]}
            values={[
              "I don't have the necessary software or version of the software installed. Specify the required software and the required version.",
              "Files are missing.",
              "There are path/directory errors.",
              "I need to install additional packages, libraries, or updates.",
              "Other errors. Explain.",
            ]}
            selectedValues={assessment.masterFileNoOneClickReasons}
            updateValues={(v: any) => {
              updateAssessmentFields({
                masterFileNoOneClickReasons: v,
              });
            }}
          />
        )}
        <Typography
          variant="body1"
          component="div"
          className={(masterFileOneClick
            ? [classes.typographyDisabled, classes.question]
            : [classes.question]
          ).join(" ")}
          gutterBottom
        >
          If you attempted to correct any minor issues, please record how much
          time you spent on this.
        </Typography>
        {readOnly ? (
          <table
            className={[classes.correctionsTable, classes.readOnly].join(" ")}
          >
            <tbody>
              <tr>
                <td>
                  Time spent installing additional packages, libraries, updates
                </td>
                <td>{assessment.numMinutesInstallingAdditionalPackages}</td>
              </tr>
              <tr>
                <td>Time spent changing directory paths and/or moving files</td>
                <td>{assessment.numMinutesChangingDirectoryPaths}</td>
              </tr>
              <tr>
                <td>Time spent doing other minor corrections</td>
                <td>{assessment.numMinutesOtherImprovements}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <>
            <TextField
              disabled={masterFileOneClick}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.formField}
              type="number"
              fullWidth
              label="Time spent installing additional packages, libraries, updates"
              placeholder="(in minutes)"
              value={assessment.numMinutesInstallingAdditionalPackages}
              onChange={(e: any) => {
                updateAssessmentFields({
                  numMinutesInstallingAdditionalPackages: e.target.value,
                });
              }}
            />
            <TextField
              disabled={masterFileOneClick}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.formField}
              type="number"
              fullWidth
              label="Time spent changing directory paths and/or moving files"
              placeholder="(in minutes)"
              value={assessment.numMinutesChangingDirectoryPaths}
              onChange={(e: any) => {
                updateAssessmentFields({
                  numMinutesChangingDirectoryPaths: e.target.value,
                });
              }}
            />
            <TextField
              disabled={masterFileOneClick}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.formField}
              type="number"
              fullWidth
              label="Time spent doing other minor corrections"
              placeholder="(in minutes)"
              value={assessment.numMinutesOtherImprovements}
              onChange={(e: any) => {
                updateAssessmentFields({
                  numMinutesOtherImprovements: e.target.value,
                });
              }}
            />
          </>
        )}
        <QuestionWithTrueFalseRadio
          classes={classes}
          questionClass={classes.question}
          questionText="After making minor corrections, did the master file run?"
          value={assessment.masterFileRunAfterCorrections}
          onChange={(e: any) => {
            updateAssessmentFields({
              masterFileRunAfterCorrections: e.target.value,
            });
          }}
          readOnly={readOnly}
          disabled={masterFileOneClick}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({
  editAssessment: { assessment },
}: {
  editAssessment: EditAssessment;
}) => {
  return { assessment };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAssessmentFields: (fields: any) =>
      dispatch(updateAssessmentFields(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterScript);
