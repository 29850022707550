import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ExternalLink from "../../../../components/external-link";
import { questionStyles } from "../../css";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: 600,
    },
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
    ...questionStyles(5, theme),
  })
);

function UploadPDF({ formik, readOnly }) {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Typography
        variant="body1"
        component="div"
        className={classes.question}
        gutterBottom
      >
        Please upload any additional report related to this reproduction to your
        updated reproduction package and provide the link below. Alternatively
        you can email your pdf report to{" "}
        <ExternalLink href="mailto:acre@berkeley.edu">
          acre@berkeley.edu
        </ExternalLink>
        . Make sure to include your name and title of the original paper in the
        pdf.
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          {readOnly ? (
            <Typography
              variant="body1"
              component="div"
              className={classes.readOnly}
              gutterBottom
            >
              {formik.values.additionalReport}
            </Typography>
          ) : (
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="URL"
              fullWidth
              placeholder="e.g. https://github.com/username/revise_repro_railroads/report.pdf"
              name={"additionalReport"}
              value={formik.values.additionalReport}
              onChange={formik.handleChange}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default UploadPDF;
